import Exam from './containers/Exam'

export default {
  exact: true,
  isNav: true,
  name: 'exam',
  purePath: '/exam',
  path: '/exam',
  component: Exam
}
