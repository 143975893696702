import React from 'react'
import PropTypes from 'prop-types'

import { TicketBlock, ExamProgressBarTicket, ExamTimer } from 'modules'

ExamStarted.propTypes = {
  t: PropTypes.func.isRequired,
  buttonsRef: PropTypes.object.isRequired,
  ticketWizard: PropTypes.object.isRequired,
  listenAnswer: PropTypes.func.isRequired,
  listenSkipBtn: PropTypes.func.isRequired,
  examTimer: PropTypes.object.isRequired
}

export default function ExamStarted({
  t,
  buttonsRef,
  ticketWizard,
  listenAnswer,
  listenSkipBtn
}) {
  return (
    <div className="wrapper h-100 w-exam-started">
      <div className="tickets tickets-exam">
        <TicketBlock
          ticket={ticketWizard.ticket}
          listenAnswer={listenAnswer}
          isLocked={ticketWizard.isLocked}
          isExam={true}
        />
        <div className="progress-bar-v2">
          <p style={{ minWidth: `${ticketWizard.progressV2}%` }}>
            {ticketWizard.counter}
            <span>&nbsp;/ {ticketWizard.totalCount}</span>
          </p>
          <ExamProgressBarTicket ticketWizard={ticketWizard} />
        </div>
        <div className="status-bar">
          <div className="left-side">
            <p className="true">{ticketWizard.correct}</p>
            <span>{t('correct')}</span>
            <p className="false">{ticketWizard.wrong}</p>
            <span>{t('mistake')}</span>
          </div>
          <div className="right-side">
            <ExamTimer ticketWizard={ticketWizard} />
            <div className="next-btn">
              <p>{t('next')}</p>
              <button
                ref={el => el && (buttonsRef.current[0] = el)}
                type="button"
                onClick={listenSkipBtn(ticketWizard.isLocked)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10">
                  <path d="M.377 2.328a1.423 1.423 0 0 1 0-1.929 1.234 1.234 0 0 1 1.818 0l3.428 3.637a1.423 1.423 0 0 1 0 1.928L2.195 9.601a1.234 1.234 0 0 1-1.818 0 1.423 1.423 0 0 1 0-1.929L2.896 5 .376 2.328z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
