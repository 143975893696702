import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { authFetchRequested } from 'modules/auth/actions'

export const useAuthService = ({ fetch } = {}) => {
  const {
    i18n: { language: locale }
  } = useTranslation()

  const dispatch = useDispatch()

  const auth = useSelector(({ auth }) => auth, shallowEqual)

  useEffect(() => {
    if (!fetch) return

    dispatch(authFetchRequested({ locale }))
  }, [dispatch, locale, fetch])

  return { auth }
}
