import React from 'react'
import { useTranslation } from 'react-i18next'

import RsQuestModalView from '../views/RsQuestModal'

import { arrayChunk } from 'tools'
import { useRsQuestWizard, useModalManager, useRsQuestModal } from 'hooks'
import { useRsQuest } from 'hooks/services'

export default function RsQuestModal() {
  const { t } = useTranslation()
  const { rsQuest } = useRsQuest()

  const { modalRef, listenCloseModal } = useModalManager()

  const {
    wizardNext,
    wizardLevel,
    wizardCorrectnessState,
    wizardCorrectnessNow
  } = useRsQuestWizard({
    level: rsQuest.data.length - 1,
    correctness: 5
  })

  const { listenAnswerBtn, isWrong } = useRsQuestModal({
    wizardNext,
    wizardCorrectnessNow,
    listenCloseModal
  })

  let answers = []

  if (!rsQuest.loading) {
    answers = arrayChunk(rsQuest.data[wizardLevel].answers, 3)
  }

  return (
    <RsQuestModalView
      t={t}
      modalRef={modalRef}
      listenCloseModal={listenCloseModal}
      wizardCorrectnessState={wizardCorrectnessState}
      listenAnswerBtn={listenAnswerBtn}
      isWrong={isWrong}
      answers={answers}
      wizardLevel={wizardLevel}
      rsQuest={rsQuest}
    />
  )
}
