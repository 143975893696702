export const tipTransformer = {
  fetchTips: data => {
    try {
      return data.data.map(({ id, is_main, title, yt_embed, desc, order }) => ({
        id,
        isMain: is_main,
        title,
        ytEmbed: yt_embed,
        desc,
        order
      }))
    } catch (error) {
      throw new Error(error)
    }
  }
}
