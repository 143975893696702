import { useEffect, useState } from 'react'
import { fromEvent } from 'rxjs'
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators'

export default function useViewport() {
  const [resize, setResize] = useState(window.innerWidth)

  useEffect(() => {
    const { subscriber } = listenResize()

    return () => {
      subscriber.unsubscribe()
    }
  }, [])

  const listenResize = () => {
    const stream$ = fromEvent(window, 'resize').pipe(
      map(() => window.innerWidth),
      debounceTime(100),
      distinctUntilChanged()
    )

    const subscriber = stream$.subscribe(width => setResize(width))

    return { subscriber }
  }

  const vpLessThan = size => size > resize

  const vpMoreThan = size => resize >= size

  return {
    vpLessThan,
    vpMoreThan
  }
}
