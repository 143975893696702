import React from 'react'
import PropTypes from 'prop-types'

export const SCatIcon = ({ className = '' } = '') => (
  <svg
    className={`license-i s-cat-i ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="1.9 24.3 97 53.6"
    enableBackground="new 1.9 24.3 97 53.6"
    xmlSpace="preserve"
  >
    <path
      strokeWidth="1.877816e-02"
      d="M18.7,24.3c-2.5,0.1-5.6,3.8-4.8,4.6c1.9,4,2.1,6.8,1.5,12.8
      c-1.1,0-2.2-0.3-3.2,0c-1.6,0.6-2,2.3-2.9,3.6c-1.3,1.8-0.8,4.2-1,6.3c1.3,0.3,2.5,0.5,3.8,0.7c0.2,0.8,0.5,1.7,0.8,2.5
      c1.2-1.5,2.4-3.1,4-4.3c1.6-1,3.4-1.8,5.3-2.1c6.4,0.2,12.3,3.1,18.5,4.4c2.4-1.4,5.4-1.4,8-1.9c1.5-0.4,2.9,0,4.4,0.1
      c2.1-0.2,4.2-0.6,6.4-0.8c0.4,1.4,0.6,2.9,0.8,4.4c-2.6,0.5-5.3,0.7-7.9,1.4c4.8,2.5,10.2,3.3,15.4,4.7c-0.1-2.1-0.5-4.4,0.4-6.3
      c0.7-0.8,1.2-1.9,0.9-3c0.4-1.2-0.7-1.6-1.4-2c0.1-1.8,0.3-3.5-0.2-5.3c0-0.7-0.3-1.6-1-1.8c-2.8-1.1-5.9-1.2-8.9-2
      c-0.3-0.6-0.4-1.2-0.5-1.8c-0.1-3,0.3-6,0.3-9c-0.1-0.5-0.2-1.3-0.8-1C56.2,28.9,56,29.5,56,30c-0.1,2.8-0.2,5.7-0.4,8.5
      c-2.3-0.4-4.6-0.8-6.8-1.3c-0.7,0.7-1.4,1.3-2.3,1.6c-0.9-1.8-1.2-3.8-1.4-5.8c-0.1-1.3-0.5-2.6-1.5-3.5c-1.7-1.4-3.9-1.9-6-2.3
      c-3-0.5-6-0.9-9-1c-0.2-0.6-0.4-1.3-0.8-1.9c-1.2-0.1-2.4,0.1-3.6,0.4C22,25,22,25,20.3,24.9C19.9,24.4,19.3,24.3,18.7,24.3z
       M19.5,27.9c0.4,0.1,0.8,0.1,1.2,0.2c-0.1,3-0.3,6.1-0.7,9.1c0,1.9-1.6,3.1-3,3.8C17.4,36.6,18.7,32.3,19.5,27.9z M35.3,28.3
      c1.6,0,4.3,1.2,5.2,1.5c2.5,1.5,1.1,16.9,0.8,20.4c-1.3-0.2-3.6,0.1-4.6,0.1c-1-3-1.1-7.2-1.4-10c-0.4-2.5-1.3-1.3-1.8-2.7
      c-0.2-2.6,0.3-5.8,0.5-8.4C34,28.5,34.5,28.3,35.3,28.3L35.3,28.3z M27.5,28.3c1.3,0,2.6,0.2,3.8,0.5c-0.2,3.7-0.3,7.4-0.8,11.1
      c0,0.3-0.3,0.5-0.5,0.7c-2,0.1-4.1,0-6.1-0.1c0-3.7,0.1-7.3,0.6-11c0.1-0.4,0.1-1,0.7-1.1C25.9,28.3,26.7,28.3,27.5,28.3L27.5,28.3z
       M26,29.8c-0.4,3.1-0.5,6.2-0.7,9.3c1,0,2.9,0,3.8,0.1c0.4-3.1,0.5-6.2,0.7-9.3L26,29.8L26,29.8z M23.1,49.9c-0.3,0-0.5,0-0.8,0
      c-1.6,0.4-3.1,1-4.4,1.8c-2.1,1.6-3.2,4.3-5.7,5.5c-2.3,2.5-5,4.6-7.5,6.8c-5.5,5.4-2.9,13.3,6.6,12.2c17.7,0.7,54.8,1,61.6-0.8
      c5.6-2.6,3.8-11-2.9-12.5c-5.1-1.5-10.2-2.6-15.2-4.2c-2.3-0.8-4.5-2-6.9-2.4c-6.7-1.3-13.1-3.6-19.6-5.6
      C26.6,50.3,24.8,49.9,23.1,49.9L23.1,49.9z M58.4,51.8c-2.2,0.3-4.5,0.9-6.8,0.7c-3.1-0.3-6.1,0.6-9.1,1.1c2.8,0.8,5.8,1.3,8.6,2
      c1.9-2,4.9-1.7,7.4-2.1C58.6,52.9,58.5,52.4,58.4,51.8z M23.3,54.5c0.1,0,0.2,0,0.4,0c2.5,0.1,4.5,2.8,3.6,5.3
      c-0.8,2.9-5.1,3.5-6.9,1.2c-1.7-2-0.7-5.7,1.9-6.3C22.6,54.6,22.9,54.5,23.3,54.5z M91,55.9c-2.1,0.1-4.1,0.3-6.1,0.4
      c0.2,0.6,0.5,1.3,0.8,1.9c-1.1-0.2-2.2-0.1-3.3,0.3c0.3,2-0.8,3.7-1.6,5.5c-2.1-0.3-4.2-0.5-6.3-0.5c1,0.9,2,2,3.1,2.8
      c2.7-0.1,5.5-0.2,8.2,0c0,0.4,0,0.8,0,1.2c-2.7,0.2-5.5,0.2-8.2-0.3c0.5,2.8,0.5,5.7-0.9,8.2c6.3,0.4,6.1,0.2,10.7-0.4
      c2.3,0.1,4.1,1.7,5.9,2.9c0.6-0.4,1.1-0.8,1.6-1.3c0.5,0.5,1,0.9,1.5,1.3c0.8-0.3,1.6-0.5,2.4-0.6c-3-3.1-5-7.3-4.9-11.7
      c0-2.1,1.1-4,1.1-6.1c-0.4-1.5-2.3-0.4-3.1-1.4C91.5,57.4,91.3,56.6,91,55.9L91,55.9z M69.8,66.7c1.8,0,3.6,1.5,3.4,3.4
      c0.3,2.6-3.3,4.4-5.2,2.6c-2.2-1.3-1.5-5.2,0.9-5.8C69.2,66.7,69.5,66.7,69.8,66.7z M19.5,69.9c1.3,0,2.4,1.2,2.4,2.5
      c0,1.4-1.1,2.5-2.5,2.5c-1.3,0-2.4-1.1-2.4-2.5C17,71,18.1,69.9,19.5,69.9z M50,70c1.3,0,2.4,1.1,2.4,2.5c-0.2,0.9-0.9,2.6-1.9,2.4
      c-0.2,0-0.4,0.1-0.6,0.1c-0.7,0-1.4-0.4-1.8-0.9C48,74.1,48,74,47.9,74c0-0.1,0-0.1,0-0.2c-0.2-0.4-0.4-0.9-0.4-1.4
      C47.5,71.1,48.7,70,50,70L50,70z M56,70.1c1.3,0,2.4,1.1,2.4,2.5c0,1.4-1.1,2.5-2.5,2.5c-1.3,0-2.4-1.2-2.4-2.5
      C53.6,71.2,54.7,70.1,56,70.1z M25.4,70.2c1.3,0,2.4,1.2,2.4,2.5c0,0.9-0.5,1.7-1.3,2.2c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.3,0-0.4-0.1
      c-0.2,0.1-0.4,0.1-0.6,0.1c-0.8,0-1.4-0.4-1.9-1c0,0-0.1,0-0.1,0c0,0,0,0,0-0.1c-0.3-0.4-0.4-0.9-0.4-1.5
      C22.9,71.2,24,70.1,25.4,70.2L25.4,70.2z M31.4,70.2c1.3,0,2.4,1.2,2.4,2.5c0,0.3-0.1,0.7-0.2,1c0,0,0,0,0,0
      c-0.1,0.5-0.4,0.9-0.9,1.1c0,0-0.1,0-0.1,0c-0.4,0.2-0.8,0.4-1.3,0.4c-1.3,0-2.4-1.2-2.4-2.5C28.9,71.3,30,70.2,31.4,70.2L31.4,70.2
      z"
    ></path>
  </svg>
)

SCatIcon.propTypes = {
  className: PropTypes.string
}
