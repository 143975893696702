import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMapboxGl, { Marker } from 'react-mapbox-gl'
import PropTypes from 'prop-types'
import 'mapbox-gl/dist/mapbox-gl.css';

import SchoolDetail from './SchoolDetail'

import config from 'config'
import mapResize from 'resources/svgs/map-resize.svg'

const { mapApi } = config

const Map = ReactMapboxGl({ accessToken: mapApi })

SchoolMap.propTypes = {
  listenResizeBtn: PropTypes.func.isRequired,
  mo: PropTypes.func.isRequired,
  schoolDetail: PropTypes.object.isRequired,
  setSchoolDetail: PropTypes.func.isRequired,
  drivingSchools: PropTypes.object.isRequired,
  mapOpts: PropTypes.object.isRequired
}

export default function SchoolMap({
  listenResizeBtn,
  mo,
  schoolDetail,
  setSchoolDetail,
  drivingSchools,
  mapOpts
}) {
  const { t } = useTranslation()

  return (
    <>
      <button type="button" className="resize-rubber" onClick={listenResizeBtn}>
        <img src={mapResize} alt="" />
      </button>
      {schoolDetail.show && (
        <SchoolDetail
          schoolDetail={schoolDetail}
          setSchoolDetail={setSchoolDetail}
        />
      )}
      <Map {...mapOpts}>
        {drivingSchools.data.filter(({ swoop })=> !swoop ).map(drivingSchool => (
          <Marker
            className={mo('id', drivingSchool.id) ? 'z-hover' : ''}
            key={drivingSchool.id}
            coordinates={[drivingSchool.mapLng, drivingSchool.mapLat]}
          >
            <div
              className={`info ${mo('id', drivingSchool.id) ? 'info-hover' : ''}`}
            >
              <div className="block">
                <div className="top">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                    <path d="M11.9 8.4c1.12 0 2.1.91 2.1 2.1 0 .91-.63 1.68-1.4 1.96V14l-.7-.7-.7.7v-1.54c-.77-.28-1.4-1.05-1.4-1.96 0-1.19.91-2.1 2.1-2.1zM13.3 0c.32 0 .642.294.693.613L14 .7v7a4.866 4.866 0 0 0-1.107-.538L12.6 7.07V1.4H1.4v8.4h7.07a1.874 1.874 0 0 0-.069.532L8.4 10.5v.7H.7a.704.704 0 0 1-.693-.598L0 10.5V.7C0 .38.294.058.613.007L.7 0h12.6zM3.5 7h5.6c.42 0 .7.28.7.7 0 .385-.235.652-.598.694L9.1 8.4H3.5c-.385 0-.652-.235-.694-.598L2.8 7.7l.006-.102a.643.643 0 0 1 .582-.591L3.5 7h5.6zm2.1-4.2v2.8H2.8V2.8h2.8zm4.9.7c.385 0 .652.235.694.598l.006.102-.006.102a.643.643 0 0 1-.582.591L10.5 4.9H7.7c-.385 0-.652-.235-.694-.598L7 4.2l.006-.102a.643.643 0 0 1 .582-.591L7.7 3.5h2.8z" />
                  </svg>
                  <span>{drivingSchool.categories}</span>
                </div>
                {mo('id', drivingSchool.id) && (
                  <div className="botton">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                      <path d="M13 2c6.075 0 11 4.925 11 11s-4.925 11-11 11S2 19.075 2 13 6.925 2 13 2zm0 4a1 1 0 0 0-1 1v5.586l-2.707 2.707a1 1 0 0 0 1.414 1.414l3-3A1 1 0 0 0 14 13V7a1 1 0 0 0-1-1z" />
                    </svg>
                    <p>
                      <span>
                        {t('monday_friday')}{' '}
                        <b>{drivingSchool.timetables[0]?.open}</b> -{' '}
                        <b>{drivingSchool.timetables[0]?.close}</b>
                      </span>
                      {(drivingSchool.timetables[5] ||
                        drivingSchool.timetables[6]) && (
                        <span>
                          {t('saturday_sunday')}{' '}
                          <b>{drivingSchool.timetables[5]?.open || t('closed')}</b> -{' '}
                          <b>{drivingSchool.timetables[6]?.close || t('closed')}</b>
                        </span>
                      )}
                    </p>
                  </div>
                )}
              </div>
              <i
                className={`marker ${
                  mo('id', drivingSchool.id) ? 'marker-hover' : ''
                }`}
              >
                <em></em>
              </i>
            </div>
          </Marker>
        ))}
      </Map>
    </>
  )
}
