import React from 'react'
import { Element } from 'react-scroll'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import { BreadCrumb } from 'modules'

import { carouselSettings } from 'utils/app'

Regulator.propTypes = {
  regulators: PropTypes.object.isRequired
}

export default function Regulator({ regulators }) {
  const generateSlides = ({ photos }) =>
    photos.map((photo, index) => (
      <div className="slide" key={index}>
        <img src={photo} alt="" />
      </div>
    ))

  return (
    <div className="wrapper">
      <BreadCrumb />
      <div className="regulators">
        <div className="regulator-list">
          {regulators.data.map(({ id, title, subTitle, options, photos }, index) => (
            <Element name={`regulator-${id}`} className="item" key={id}>
              <div className="overflow">
                <div className="block">
                  <div className="wrapt">
                    <div className="shift">
                      <div className="head">
                        <i>{++index}</i>
                        <p>
                          <span>{title}</span>
                          <span>{subTitle}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="empty">&nbsp;</div>
              </div>
              <div className="overflow">
                <div className="block">
                  <div className="wrapt">
                    <div className="shift">
                      <div className="rules">
                        {options.map(({ id, title, desc, isCorrect }) => (
                          <div
                            className={`rule ${isCorrect ? 'r-true' : 'r-false'}`}
                            key={id}
                          >
                            <div className="icon">
                              <i></i>
                            </div>
                            <div className="text">
                              <p>{title}</p>
                              <span>{desc}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slider-block">
                  <Slider {...carouselSettings} className="slider">
                    {generateSlides({ photos })}
                  </Slider>
                </div>
              </div>
            </Element>
          ))}
        </div>
      </div>
    </div>
  )
}
