import moment from 'moment'

export const calendar = {
  today: ({ timetables }) => {
    const weekDay = moment().isoWeekday()

    const currentDay = timetables.find(cal => cal.day === weekDay.toString())

    if (!currentDay)
      return {
        now: 'closed'
      }

    const currentTime = moment()
    const closeTime = moment(currentDay.close, 'h:mm A')
    const openTime = moment(currentDay.open, 'h:mm A')

    const willClose =
      moment.duration(closeTime.diff(currentTime)).asHours() < 1 &&
      currentTime.isBefore(closeTime)

    const isClosed = currentTime.isAfter(closeTime) || currentTime.isBefore(openTime)

    let now = 'open'
    if (willClose) now = 'will_close'
    if (isClosed) now = 'closed'

    return {
      now,
      close: currentDay.close
    }
  }
}
