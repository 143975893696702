import config from 'config'
import { cFetch } from 'tools'
import { tipTransformer } from 'transformers'

const {
  drivingApi: { endpoints }
} = config

export const tipService = {
  fetchTips: ({ payload }) => async () => {
    try {
      const url = endpoints.fetchTips.replace(':locale', payload.locale)

      const response = await cFetch(url)

      const data = await response.json()

      return tipTransformer.fetchTips(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
