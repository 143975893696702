import { useEffect, useState, useRef } from 'react'

// import { fullscreen } from 'tools'

export default function useRsFullscreenModal({ modalRef }) {
  const carousel1Ref = useRef()
  const carousel2Ref = useRef()

  // const { setFullscreen } = fullscreen(modalRef)

  const [carousel, setCarousel] = useState({
    c1: null,
    c2: null
  })

  useEffect(() => {
    // setFullscreen()

    setCarousel({
      c1: carousel1Ref.current,
      c2: carousel2Ref.current
    })
    // eslint-disable-next-line
  }, [])

  const carouselPrev = () => carousel.c2.slickPrev()
  const carouselNext = () => carousel.c1.slickNext()

  return {
    carousel1Ref,
    carousel2Ref,
    carousel1: carousel.c1,
    carousel2: carousel.c2,
    carouselPrev,
    carouselNext
  }
}
