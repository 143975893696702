import React from 'react'
import PropTypes from 'prop-types'

RsModal.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  modalRef: PropTypes.object.isRequired,
  modalContainerRef: PropTypes.object.isRequired,
  listenFullscreenBtn: PropTypes.func.isRequired,
  listenCloseModal: PropTypes.func.isRequired
}

export default function RsModal({
  t,
  data: { roadSign },
  modalRef,
  modalContainerRef,
  listenFullscreenBtn,
  listenCloseModal
}) {
  return (
    <div className="rs-modal" ref={modalRef}>
      <div className="rs-modal-container" ref={modalContainerRef}>
        <span className="rs-modal-close-btn" onClick={listenCloseModal}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.01 7.01">
            <path d="M4.84,3.5,6.73,5.39a1,1,0,0,1,0,1.34.94.94,0,0,1-1.33,0h0L3.5,4.84,1.61,6.73A.94.94,0,0,1,.27,5.4h0L2.17,3.5.28,1.61A.94.94,0,0,1,.27.28a1,1,0,0,1,1.34,0h0L3.5,2.17,5.39.28A.94.94,0,0,1,6.73,1.61Z" />
          </svg>
        </span>
        <img src={roadSign.photo} alt="" />
        <p>{roadSign.title}</p>
        <span className="description">{roadSign.desc}</span>
        <button type="button" onClick={listenFullscreenBtn}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
            <path d="M4.333 0a1 1 0 1 1 0 2h-2A.333.333 0 0 0 2 2.333v2a1 1 0 1 1-2 0v-2A2.333 2.333 0 0 1 2.333 0h2zM14 4.333a1 1 0 0 1-2 0v-2A.333.333 0 0 0 11.667 2h-2a1 1 0 1 1 0-2h2A2.333 2.333 0 0 1 14 2.333v2zM9.667 14a1 1 0 0 1 0-2h2c.184 0 .333-.15.333-.333v-2a1 1 0 0 1 2 0v2A2.333 2.333 0 0 1 11.667 14h-2zM0 9.667a1 1 0 1 1 2 0v2c0 .184.15.333.333.333h2a1 1 0 0 1 0 2h-2A2.333 2.333 0 0 1 0 11.667v-2z" />
          </svg>
          <span>{t('fullscreen')}</span>
        </button>
      </div>
    </div>
  )
}
