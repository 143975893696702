import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { rsQuestFetchRequested } from 'modules/rsQuestModal/actions'

export const useRsQuest = ({ fetch = true } = {}) => {
  const {
    i18n: { language: locale }
  } = useTranslation()

  const dispatch = useDispatch()

  const rsQuest = useSelector(({ rsQuest }) => rsQuest)

  useEffect(() => {
    if (!fetch) return

    dispatch(rsQuestFetchRequested({ locale }))
  }, [dispatch, locale, fetch])

  return { rsQuest }
}
