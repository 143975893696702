import { call, put, take, fork } from 'redux-saga/effects'

import {
  regulatorsFetchSucceeded,
  regulatorsFetchFailed,
  regulatorsFetchRequested
} from '../actions'

import { regulatorService } from 'services'

function* fetchRegulators({ payload }) {
  try {
    const data = yield call(regulatorService.fetchRegulators({ payload }))

    yield put(regulatorsFetchSucceeded({ data }))
  } catch (errors) {
    yield put(regulatorsFetchFailed({ errors }))
  }
}

export default function* watcher() {
  while (true) {
    const { payload } = yield take(regulatorsFetchRequested().type)

    yield fork(fetchRegulators, { payload })
  }
}
