import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import PropTypes from 'prop-types'

import config from 'config'

import userImg from 'resources/imgs/user-img.png'
import userRankImg from 'resources/imgs/user-rank.png'
import userSignImg from 'resources/imgs/user-sign.png'
import userActiveTodayImg from 'resources/imgs/user-active-today.png'
import userChartTodayImg from 'resources/imgs/user-chart-today.png'
import userActiveAllImg from 'resources/imgs/user-active-all.png'
import userChartAllImg from 'resources/imgs/user-chart-all.png'

Block5.propTypes = {
  t: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  textPart: PropTypes.object.isRequired
}

const {
  drivingApi: { endpoints }
} = config

export default function Block5({ t, locale, auth, textPart }) {
  return (
    <section className="home-section driving-join-section left-decoration">
      <div className="home-section-left left-decoration">
        <div className="home-user-section">
          <div className="user-card-left">
            <div className="user-card">
              <div className="user-img">
                <img src={userImg} alt="" />
              </div>
              <h4 className="user-name">{t('fake_user')}</h4>
              <h5 className="user-level">{t('beginner')}</h5>
              <div className="user-icons">
                <div className="user-icon">
                  <div className="icon-round icon-rank">
                    <img src={userRankImg} alt="" />
                  </div>
                  <div className="icon-count">213</div>
                </div>
                <div className="user-icon">
                  <div className="icon-round icon-sign">
                    <img src={userSignImg} alt="" />
                  </div>
                  <div className="icon-count">2 789</div>
                </div>
              </div>
            </div>
          </div>
          <div className="user-card-right">
            <div className="user-card-sm">
              <h4 className="card-title">{t('daily_activity')}</h4>
              <div className="user-activity">
                <img src={userActiveTodayImg} alt="" />
              </div>
              <div className="chart-img chart-today">
                <img src={userChartTodayImg} alt="" />
              </div>
            </div>

            <div className="user-card-sm">
              <h4 className="card-title">{t('total_tickets')}</h4>
              <span className="tickets-number">1792</span>
              <div className="user-activity">
                <img src={userActiveAllImg} alt="" />
              </div>
              <div className="chart-img chart-all">
                <img src={userChartAllImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section-right">
        <div className="join-info">
          <h2 className="home-section-title">{textPart.data.joinUs.title}</h2>
          <div className="home-section-text">
            {ReactHtmlParser(textPart.data.joinUs.desc)}
          </div>
          {!auth.user && (
            <a
              className="driving-btn"
              href={endpoints.authSignUpUri.replace(/:locale/g, locale)}
            >
              {t('sign_up')}
            </a>
          )}
        </div>
      </div>
    </section>
  )
}
