import {
  PROFILE_EDIT_REQUESTED,
  PROFILE_EDIT_SUCCEEDED,
  PROFILE_EDIT_FAILED,
  PROFILE_EDIT_RESET
} from './types'

export const profileEditRequested = content => ({
  type: PROFILE_EDIT_REQUESTED,
  payload: content
})
export const profileEditSucceeded = content => ({
  type: PROFILE_EDIT_SUCCEEDED,
  payload: content
})
export const profileEditFailed = content => ({
  type: PROFILE_EDIT_FAILED,
  payload: content
})
export const profileEditReset = () => ({
  type: PROFILE_EDIT_RESET
})
