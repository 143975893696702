import { useEffect, useRef } from 'react'

export default function useKeyToBtn({ keys, lockupCallback }) {
  const buttonsRef = useRef([])

  useEffect(() => {
    const { current: buttons } = buttonsRef

    let notes = {}

    for (let i = 0; i < keys.length; i++) {
      const keyBtns = Array.isArray(keys[i]) ? keys[i] : [keys[i]]
      Object.assign(notes, construct(keyBtns, buttons))
    }

    const keyup = listenKeyup({ notes, lockupCallback })

    document.addEventListener('keyup', keyup)
    return () => {
      document.removeEventListener('keyup', keyup)
    }

    // eslint-disable-next-line
  }, [keys])

  const construct = (keys, btns) => {
    const data = {}

    for (let i = 0; i < keys.length; i++) {
      data[keys[i]] = btns[i]
    }

    return data
  }

  const listenKeyup = ({ notes, lockupCallback }) => ({ target, keyCode }) => {
    const isLocked = lockupCallback({ target, keyCode })

    if (isLocked || !Reflect.has(notes, keyCode) || !notes[keyCode]) return

    notes[keyCode].click()
  }

  return { buttonsRef, listenKeyup }
}
