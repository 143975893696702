import config from 'config'
import { cFetch } from 'tools'
import { locationTransformer } from 'transformers'

const {
  drivingApi: { endpoints }
} = config

export const locationService = {
  fetchCities: ({ payload }) => async () => {
    try {
      const url = endpoints.fetchCities.replace(':locale', payload.locale)

      const response = await cFetch(url)

      const data = await response.json()

      return locationTransformer.fetchCities(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
