import {
  EXAM_TIMER_SET,
  EXAM_TIMER_UPDATE,
  EXAM_TIMER_DOWN,
  EXAM_TIMER_RESET
} from './types'

export const examTimerSet = content => ({
  type: EXAM_TIMER_SET,
  payload: content
})

export const examTimerUpdate = content => ({
  type: EXAM_TIMER_UPDATE,
  payload: content
})

export const examTimerDown = content => ({
  type: EXAM_TIMER_DOWN,
  payload: content
})

export const examTimerReset = () => ({
  type: EXAM_TIMER_RESET
})
