import React from 'react'

import CategoryNavView from '../views/CategoryNav'

import { useCategoryService } from 'hooks/services'

export default function CategoryNav() {
  const { cats } = useCategoryService()

  return <CategoryNavView cats={cats} />
}
