import React from 'react'
import { useTranslation } from 'react-i18next'

import Block2View from '../views/Block2'

import { useTextPartService } from 'hooks/services'

export default function Block2() {
  const { t } = useTranslation()

  const { textPart } = useTextPartService({ fetch: true, part: 'about' })

  return <Block2View t={t} textPart={textPart} />
}
