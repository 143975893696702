import {
  TEXT_PART_FETCH_REQUESTED,
  TEXT_PART_FETCH_SUCCEEDED,
  TEXT_PART_FETCH_FAILED
} from './types'

export const textPartFetchRequested = content => ({
  type: TEXT_PART_FETCH_REQUESTED,
  payload: content
})
export const textPartFetchSucceeded = content => ({
  type: TEXT_PART_FETCH_SUCCEEDED,
  payload: content
})
export const textPartFetchFailed = content => ({
  type: TEXT_PART_FETCH_FAILED,
  payload: content
})
