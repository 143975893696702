/*eslint-disable*/

!(function(t, n) {
  'object' == typeof exports && 'undefined' != typeof module
    ? n(exports)
    : 'function' == typeof define && define.amd
    ? define(['exports'], n)
    : n(((t = t || self).window = t.window || {}))
})(this, function(t) {
  'use strict'
  function n() {
    return s || ('undefined' != typeof window && (s = window.gsap))
  }
  function e(t) {
    return d(t).id
  }
  function i(t) {
    return h[e('string' == typeof t ? c(t)[0] : t)]
  }
  function r(t) {
    var n,
      e = p
    if (0.05 <= t - g)
      for (g = t; e; )
        ((n = e.g(e.t, e.p)) !== e.v1 || 0.2 < t - e.t1) &&
          ((e.v2 = e.v1), (e.v1 = n), (e.t2 = e.t1), (e.t1 = t)),
          (e = e._next)
  }
  function o() {
    ;(s = n()) &&
      ((c = s.utils.toArray),
      (f = s.utils.getUnit),
      (d = s.core.getCache),
      (l = s.ticker),
      (u = 1))
  }
  function a(t, n, e, i) {
    ;(this.t = t),
      (this.p = n),
      (this.g = t._gsap.get),
      (this.rCap = v[e || f(this.g(t, n))]),
      (this.v1 = this.v2 = 0),
      (this.t1 = this.t2 = l.time),
      i && ((this._next = i)._prev = this)
  }
  var s,
    u,
    c,
    f,
    p,
    l,
    g,
    d,
    h = {},
    v = { deg: 360, rad: 2 * Math.PI },
    m = (function() {
      function t(t, n) {
        u || o(),
          (this.target = c(t)[0]),
          ((h[e(this.target)] = this)._props = {}),
          n && this.add(n)
      }
      t.register = function(t) {
        ;(s = t), o()
      }
      var n = t.prototype
      return (
        (n.get = function(t, n) {
          var e,
            i,
            r = this._props[t] || console.warn('Not tracking ' + t + ' velocity.')
          return (
            (e = parseFloat(n ? r.v1 : r.g(r.t, r.p)) - parseFloat(r.v2)),
            (i = r.rCap) && (e %= i) != e % (i / 2) && (e = e < 0 ? e + i : e - i),
            (function(t) {
              return Math.round(1e4 * t) / 1e4
            })(e / ((n ? r.t1 : l.time) - r.t2))
          )
        }),
        (n.getAll = function() {
          var t,
            n = {},
            e = this._props
          for (t in e) n[t] = this.get(t)
          return n
        }),
        (n.isTracking = function(t) {
          return t in this._props
        }),
        (n.add = function(t, n) {
          t in this._props ||
            (p || (l.add(r), (g = l.time)),
            (p = this._props[t] = new a(this.target, t, n, p)))
        }),
        (n.remove = function(t) {
          var n,
            e,
            i = this._props[t]
          i &&
            ((n = i._prev),
            (e = i._next),
            n && (n._next = e),
            e ? (e._prev = n) : p === i && (l.remove(r), (p = 0)),
            delete this._props[t])
        }),
        (n.kill = function(t) {
          for (var n in this._props) this.remove(n)
          t || delete h[e(this.target)]
        }),
        (t.track = function(n, e, r) {
          u || o()
          for (
            var a,
              s,
              f = [],
              p = c(n),
              l = e.split(','),
              g = (r || '').split(','),
              d = p.length;
            d--;

          ) {
            for (a = i(p[d]) || new t(p[d]), s = l.length; s--; )
              a.add(l[s], g[s] || g[0])
            f.push(a)
          }
          return f
        }),
        (t.untrack = function(t, n) {
          var e = (n || '').split(',')
          c(t).forEach(function(t) {
            var n = i(t)
            n &&
              (e.length
                ? e.forEach(function(t) {
                    return n.remove(t)
                  })
                : n.kill(1))
          })
        }),
        (t.isTracking = function(t, n) {
          var e = i(t)
          return e && e.isTracking(n)
        }),
        (t.getVelocity = function(t, n) {
          var e = i(t)
          return e && e.isTracking(n)
            ? e.get(n)
            : console.warn('Not tracking velocity of ' + n)
        }),
        t
      )
    })()
  function y() {
    return (
      j ||
      ('undefined' != typeof window && (j = window.gsap) && j.registerPlugin && j)
    )
  }
  function _(t) {
    return 'number' == typeof t
  }
  function w(t) {
    return 'object' == typeof t
  }
  function k(t) {
    return 'function' == typeof t
  }
  function x(t) {
    return t
  }
  function T(t) {
    return Math.round(1e4 * t) / 1e4
  }
  function F(t, n, e) {
    for (var i in n) i in t || i === e || (t[i] = n[i])
    return t
  }
  function P(t) {
    var n,
      e,
      i = {}
    for (n in t) i[n] = w((e = t[n])) ? P(e) : e
    return i
  }
  function N(t, n, e, i, r) {
    var o,
      a,
      s,
      u,
      c = n.length,
      f = 0,
      p = L
    if (w(t)) {
      for (; c--; ) {
        for (s in ((o = n[c]), (a = 0), t)) a += (u = o[s] - t[s]) * u
        a < p && ((f = c), (p = a))
      }
      if ((r || L) < L && r < Math.sqrt(p)) return t
    } else for (; c--; ) (a = (o = n[c]) - t) < 0 && (a = -a), a < p && i <= o && o <= e && ((f = c), (p = a))
    return n[f]
  }
  function M(t, n, e, i, r, o) {
    if ('auto' === t.end) return t
    var a,
      s,
      u = t.end
    if (((e = isNaN(e) ? L : e), (i = isNaN(i) ? -L : i), w(n))) {
      if (
        ((a = n.calculated ? n : (k(u) ? u(n) : N(n, u, e, i, o)) || n),
        !n.calculated)
      ) {
        for (s in a) n[s] = a[s]
        n.calculated = !0
      }
      a = a[r]
    } else a = k(u) ? u(n) : K(u) ? N(n, u, e, i, o) : parseFloat(u)
    return (
      e < a ? (a = e) : a < i && (a = i),
      { max: a, min: a, unitFactor: t.unitFactor }
    )
  }
  function b(t, n, e) {
    return isNaN(t[n]) ? e : +t[n]
  }
  function A(t, n) {
    return (0.05 * n * t) / D
  }
  function C(t, n, e) {
    return Math.abs(((n - t) * D) / e / 0.05)
  }
  function O(t, n, e, i) {
    if (n.linkedProps) {
      var r,
        o,
        a,
        s,
        u,
        c,
        f = n.linkedProps.split(','),
        p = {}
      for (r = 0; r < f.length; r++)
        (a = n[(o = f[r])]) &&
          ((s = _(a.velocity)
            ? a.velocity
            : (u = u || J(t)) && u.isTracking(o)
            ? u.get(o)
            : 0),
          (c = Math.abs(s / b(a, 'resistance', i))),
          (p[o] = parseFloat(e(t, o)) + A(s, c)))
      return p
    }
  }
  function E() {
    ;(j = y()) &&
      ((I = j.parseEase),
      (R = j.utils.toArray),
      (q = j.utils.getUnit),
      (z = j.core.getCache),
      (G = j.utils.clamp),
      (V = I('power3')),
      (D = V(0.05)),
      (S = j.core.PropTween),
      j.config({
        resistance: 100,
        unitFactors: { time: 1e3, totalTime: 1e3, progress: 1e3, totalProgress: 1e3 }
      }),
      (U = j.config()),
      j.registerPlugin(m),
      (B = 1))
  }
  ;(m.getByTarget = i), n() && s.registerPlugin(m)
  var j,
    B,
    I,
    R,
    V,
    U,
    q,
    S,
    z,
    D,
    G,
    H,
    J = m.getByTarget,
    K =
      ((function() {
        String.fromCharCode.apply(null, arguments)
      })(103, 114, 101, 101, 110, 115, 111, 99, 107, 46, 99, 111, 109),
      Array.isArray),
    L = 1e10,
    Q = {
      resistance: 1,
      checkpoint: 1,
      preventOvershoot: 1,
      linkedProps: 1,
      radius: 1,
      duration: 1
    },
    W = {
      version: '3.4.3',
      name: 'inertia',
      register: function(t) {
        ;(j = t), E()
      },
      init: function(t, n, e, i, r) {
        B || E()
        var o = J(t)
        if ('auto' === n) {
          if (!o)
            return void console.warn(
              'No inertia tracking on ' + t + '. InertiaPlugin.track(target) first.'
            )
          n = o.getAll()
        }
        ;(this.target = t), (this.tween = e), (H = n)
        var a,
          s,
          u,
          c,
          f,
          p,
          l,
          g,
          d,
          h = t._gsap,
          v = h.get,
          m = n.duration,
          y = w(m),
          T = n.preventOvershoot || (y && 0 === m.overshoot),
          N = b(n, 'resistance', U.resistance),
          j = _(m)
            ? m
            : (function(t, n, e, i, r, o) {
                if (
                  (void 0 === e && (e = 10),
                  void 0 === i && (i = 0.2),
                  void 0 === r && (r = 1),
                  void 0 === o && (o = 0),
                  (function(t) {
                    return 'string' == typeof t
                  })(t) && (t = R(t)[0]),
                  !t)
                )
                  return 0
                var a,
                  s,
                  u,
                  c,
                  f,
                  p,
                  l,
                  g,
                  d,
                  h,
                  v = 0,
                  m = L,
                  y = n.inertia || n,
                  k = z(t).get,
                  x = b(y, 'resistance', U.resistance)
                for (a in ((h = O(t, y, k, x)), y))
                  Q[a] ||
                    (w((s = y[a])) ||
                      ((g = g || J(t)) && g.isTracking(a)
                        ? (s = _(s) ? { velocity: s } : { velocity: g.get(a) })
                        : ((c = +s || 0), (u = Math.abs(c / x)))),
                    w(s) &&
                      ((c = _(s.velocity)
                        ? s.velocity
                        : (g = g || J(t)) && g.isTracking(a)
                        ? g.get(a)
                        : 0),
                      (u = G(i, e, Math.abs(c / b(s, 'resistance', x)))),
                      (p = (f = parseFloat(k(t, a)) || 0) + A(c, u)),
                      'end' in s &&
                        ((s = M(s, h && a in h ? h : p, s.max, s.min, a, y.radius)),
                        o &&
                          (H === n && (H = y = P(n)), (y[a] = F(s, y[a], 'end')))),
                      'max' in s && p > +s.max + 1e-10
                        ? ((d = s.unitFactor || U.unitFactors[a] || 1),
                          (l =
                            (f > s.max && s.min !== s.max) ||
                            (-15 < c * d && c * d < 45)
                              ? i + 0.1 * (e - i)
                              : C(f, s.max, c)) +
                            r <
                            m && (m = l + r))
                        : 'min' in s &&
                          p < s.min - 1e-10 &&
                          ((d = s.unitFactor || U.unitFactors[a] || 1),
                          (l =
                            (f < s.min && s.min !== s.max) ||
                            (-45 < c * d && c * d < 15)
                              ? i + 0.1 * (e - i)
                              : C(f, s.min, c)) +
                            r <
                            m && (m = l + r)),
                      v < l && (v = l)),
                    v < u && (v = u))
                return m < v && (v = m), e < v ? e : v < i ? i : v
              })(
                t,
                n,
                (y && m.max) || 10,
                (y && m.min) || 0.2,
                y && 'overshoot' in m ? +m.overshoot : T ? 0 : 1,
                !0
              )
        for (a in ((n = H), (H = 0), (d = O(t, n, v, N)), n))
          Q[a] ||
            (k((s = n[a])) && (s = s(i, t, r)),
            _(s)
              ? (f = s)
              : w(s) && !isNaN(s.velocity)
              ? (f = +s.velocity)
              : o && o.isTracking(a)
              ? (f = o.get(a))
              : console.warn(
                  'ERROR: No velocity was defined for ' + t + ' property: ' + a
                ),
            (p = A(f, j)),
            (g = 0),
            (u = v(t, a)),
            (c = q(u)),
            (u = parseFloat(u)),
            w(s) &&
              ((l = u + p),
              'end' in s &&
                (s = M(s, d && a in d ? d : l, s.max, s.min, a, n.radius)),
              'max' in s && +s.max < l
                ? T || s.preventOvershoot
                  ? (p = s.max - u)
                  : (g = s.max - u - p)
                : 'min' in s &&
                  +s.min > l &&
                  (T || s.preventOvershoot ? (p = s.min - u) : (g = s.min - u - p))),
            this._props.push(a),
            (this._pt = new S(this._pt, t, a, u, 0, x, 0, h.set(t, a, this))),
            (this._pt.u = c || 0),
            (this._pt.c1 = p),
            (this._pt.c2 = g))
        e.duration(j)
      },
      render: function(t, n) {
        var e = n._pt
        for (t = V(n.tween._time / n.tween._dur); e; )
          e.set(e.t, e.p, T(e.s + e.c1 * t + e.c2 * t * t) + e.u, e.d, t),
            (e = e._next)
      }
    }
  'track,untrack,isTracking,getVelocity,getByTarget'.split(',').forEach(function(t) {
    return (W[t] = m[t])
  }),
    y() && j.registerPlugin(W),
    (t.InertiaPlugin = W),
    (t.VelocityTracker = m),
    (t.default = W),
    'undefined' == typeof window || window !== t
      ? Object.defineProperty(t, '__esModule', { value: !0 })
      : delete t.default
})
