import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { textPartFetchRequested } from 'modules/home/actions'
import { TEXT_PART_IDS } from 'consts'

export const useTextPartService = ({ fetch, part } = {}) => {
  const {
    i18n: { language: locale }
  } = useTranslation()

  const dispatch = useDispatch()

  const textPart = useSelector(({ textPart }) => textPart, shallowEqual)

  useEffect(() => {
    if (!fetch) return

    dispatch(textPartFetchRequested({ locale, id: TEXT_PART_IDS[part] }))
  }, [dispatch, locale, fetch, part])

  return { textPart }
}
