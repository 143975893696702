import config from 'config'
import { drivingSchoolTransformer } from 'transformers'
import { cFetch } from 'tools'

const {
  drivingApi: { endpoints }
} = config

export const drivingSchoolService = {
  fetchDrivingSchools: ({ payload }) => async () => {
    try {
      const { cityId = '', categoryIds = '', rating = '' } = payload

      const url = endpoints.fetchDrivingSchools
        .replace(':locale', payload.locale)
        .replace(':cityId', cityId)
        .replace(':categoryIds', categoryIds)
        .replace(':rating', rating)

      const response = await cFetch(url)

      const data = await response.json()

      return drivingSchoolTransformer.fetchDrivingSchools(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  createDsReview: ({ payload }) => async () => {
    try {
      const url = endpoints.createDrivingSchoolComment
        .replace(':locale', payload.locale)
        .replace(':drivingSchoolId', payload.data.drivingSchoolId)

      const response = await cFetch(url, {
        method: 'POST',
        body: JSON.stringify(
          drivingSchoolTransformer.createDrivingSchoolCommentRequest(payload.data)
        )
      })
      const data = await response.json()

      return drivingSchoolTransformer.createDrivingSchoolComment(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
