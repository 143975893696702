import { call, put, takeLatest } from 'redux-saga/effects'

import { authFetchSucceeded, authFetchFailed, authFetchRequested } from '../actions'
import { authService } from 'services'

function* fetchAuth({ payload }) {
  try {
    const user = yield call(authService.fetchUser({ payload }))

    yield put(authFetchSucceeded({ user }))
  } catch (errors) {
    yield put(authFetchFailed({ errors }))
  }
}

export default function* watcher() {
  yield takeLatest(authFetchRequested().type, fetchAuth)
}
