import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import DsTimeScheduleView from '../views/DsTimeSchedule'

import { useDropdown } from 'hooks'
import { calendar } from 'utils/drivingSchool'

DsTimeSchedule.propTypes = {
  timetables: PropTypes.array.isRequired
}

export default function DsTimeSchedule({ timetables }) {
  const { t } = useTranslation()

  const dropdown = useDropdown({ name: 'dsTimeSchedule', animable: true })

  const listenScheduleBtn = () => dropdown.toggle()

  return (
    <DsTimeScheduleView
      t={t}
      dropdown={dropdown}
      listenScheduleBtn={listenScheduleBtn}
      calendar={calendar.today({ timetables })}
      timetables={timetables}
    />
  )
}
