import React from 'react'
import PropTypes from 'prop-types'

TicketListDescBlock.propTypes = {
  t: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  listenDescBtn: PropTypes.func.isRequired,
  descExpand: PropTypes.bool.isRequired
}

export default function TicketListDescBlock({
  t,
  description,
  listenDescBtn,
  descExpand
}) {
  return (
    <div className={`q-desc-wrap ${descExpand && 'expanded'}`}>
      <button type="button" onClick={listenDescBtn}>
        {!descExpand ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 11 21"
            className="q-mark"
          >
            <path d="M5.65,0A5,5,0,0,1,11,5.26c0,5-4.06,4.77-4.06,7.82v.4H3V12.9C3,7.58,6.31,7.83,6.31,5.83A1.06,1.06,0,0,0,5.15,4.65,3.19,3.19,0,0,0,2.79,6.29L0,3.38A6.48,6.48,0,0,1,5.65,0ZM5,15a3,3,0,1,1-3,3A3,3,0,0,1,5,15Z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 7.01 7.01"
            className="c-mark"
          >
            <path d="M4.84,3.5,6.73,5.39a1,1,0,0,1,0,1.34.94.94,0,0,1-1.33,0h0L3.5,4.84,1.61,6.73A.94.94,0,0,1,.27,5.4h0L2.17,3.5.28,1.61A.94.94,0,0,1,.27.28a1,1,0,0,1,1.34,0h0L3.5,2.17,5.39.28A.94.94,0,0,1,6.73,1.61Z" />
          </svg>
        )}
      </button>
      {descExpand && (
        <div className="desc">
          <p>{t('about_ticket')}:</p>
          <span>{description}</span>
        </div>
      )}
    </div>
  )
}
