import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import LastMistakeView from '../views/LastMistake'

export default function LastMistake() {
  const { t } = useTranslation()

  const auth = useSelector(({ auth }) => auth)

  return <LastMistakeView t={t} auth={auth} />
}
