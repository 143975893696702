import React from 'react'
import PropTypes from 'prop-types'

export const MilCatIcon = ({ className = '' } = '') => (
  <svg
    className={`license-i mil-cat-i ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 143.1 942.4 273.9"
    enableBackground="new 0 143.1 942.4 273.9"
    xmlSpace="preserve"
  >
    <path
      d="M328.7,306.2c-30.6,0-55.4,24.8-55.4,55.4s24.8,55.4,55.4,55.4c30.6,0,55.4-24.8,55.4-55.4S359.3,306.2,328.7,306.2z
		 M328.7,384.9c-13.1,0-23.3-10.2-23.3-23.3s10.2-23.3,23.3-23.3c13.1,0,23.3,10.2,23.3,23.3C352,374,341.8,384.9,328.7,384.9z"
    ></path>
    <path
      d="M816.7,306.2c-30.6,0-55.4,24.8-55.4,55.4s24.8,55.4,55.4,55.4c30.6,0,55.4-24.8,55.4-55.4
		C872.8,331,847.3,306.2,816.7,306.2z M816.7,384.9c-13.1,0-23.3-10.2-23.3-23.3s10.2-23.3,23.3-23.3c13.1,0,23.3,10.2,23.3,23.3
		S829.8,384.9,816.7,384.9z"
    ></path>
    <path
      d="M785.4,226.8c0,0-136.9-64.1-180.6-72.8c-43-9.5-193-0.7-207.6,8l-83.8,41.5l-82.3,40.1L218,321.5c0,0-0.7,45.9,2.9,56.8
		H261l0.7-16c0-37.1,29.9-67,67-67s67,29.9,67,67l1.5,16h352.5v-20.4c0-37.1,28.4-64.8,65.6-64.8c37.1,0,69.2,27.7,69.2,64.8
		l1.5,18.9c0,0,42.2,2.2,56.1-2.9c0,0,5.1-71.4-21.1-97.6C897.6,254.5,851,240.7,785.4,226.8z M405.2,237c0,0,17.5-36.4,35.7-50.3
		c13.8-11.7,90.3-11.7,90.3-11.7l2.9,70.7L405.2,237z M737.3,253.8l-179.9-7.3l-4.4-71.4c67-2.9,142.8,32.8,186.5,58.3
		C783.2,258.9,737.3,253.8,737.3,253.8z"
    ></path>
    <polygon points="0,376.9 95.4,331 190.1,376.9 190.1,301.9 95.4,256 0,301.9 "></polygon>
    <polygon points="0,264 95.4,218.1 190.1,264 190.1,189 95.4,143.1 0,189 "></polygon>
  </svg>
)

MilCatIcon.propTypes = {
  className: PropTypes.string
}
