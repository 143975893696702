import React from 'react'
import PropTypes from 'prop-types'

import { CSelect, DropdownIndicatorV2 } from 'components'

DsRatingSelect.propTypes = {
  t: PropTypes.func.isRequired,
  ratingSelectRef: PropTypes.object.isRequired,
  ratingOptions: PropTypes.array.isRequired,
  listenSelectChange: PropTypes.func.isRequired,
  defaultRatingId: PropTypes.string
}

export default function DsRatingSelect({
  t,
  ratingSelectRef,
  ratingOptions,
  listenSelectChange,
  defaultRatingId
}) {
  return (
    <CSelect
      className="select-type-1 select-type-1-mod"
      forwardRef={ratingSelectRef}
      options={ratingOptions}
      placeholder={t('sort')}
      onChange={listenSelectChange}
      value={ratingOptions.find(({ value }) => value.toString() === defaultRatingId)}
      components={{ DropdownIndicator: DropdownIndicatorV2 }}
    />
  )
}
