import React from 'react'
import PropTypes from 'prop-types'

export const CCatIcon = ({ className = '' } = '') => (
  <svg
    className={`license-i c-cat-i ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34 15"
  >
    <path d="M27.032 2.047h6.04c.512 0 .928.407.928.908v8.47c0 .502-.388.908-.867.908h-.867c-.453-1.487-1.861-2.571-3.53-2.571h-2.633V2.955c0-.5.416-.908.929-.908zm2.456 4.25c0 .178.133.322.298.322h2.787c.165 0 .299-.144.299-.323V3.513c0-.178-.134-.323-.299-.323h-2.787c-.165 0-.298.145-.298.323v2.783zM.664 0h24.024c.366 0 .663.3.663.672v9.756H0V.672C0 .3.297 0 .664 0zm27.32 11.19c1.038 0 1.88.853 1.88 1.905S29.022 15 27.984 15c-1.039 0-1.88-.853-1.88-1.905s.841-1.904 1.88-1.904zm0 2.667a.757.757 0 0 0 .752-.762.757.757 0 0 0-.752-.762.757.757 0 0 0-.753.762c0 .42.337.762.753.762zm-2.633-1.524h-8.429v-1.142h8.43v1.142zm-23.47-1.142c1.038 0 1.88.852 1.88 1.904S2.919 15 1.88 15A1.893 1.893 0 0 1 0 13.095c0-1.052.842-1.904 1.88-1.904zm0 2.666a.757.757 0 0 0 .752-.762.757.757 0 0 0-.752-.762.757.757 0 0 0-.753.762c0 .42.337.762.753.762zm19.71 0a.757.757 0 0 0 .752-.762h1.128c0 1.052-.841 1.905-1.88 1.905a1.892 1.892 0 0 1-1.88-1.905h1.128c0 .42.336.762.752.762zM6.393 11.191c1.038 0 1.88.852 1.88 1.904S7.43 15 6.393 15c-1.039 0-1.88-.853-1.88-1.905s.842-1.904 1.88-1.904zm0 2.666a.757.757 0 0 0 .752-.762.757.757 0 0 0-.752-.762.757.757 0 0 0-.753.762c0 .42.338.762.753.762z" />
  </svg>
)

CCatIcon.propTypes = {
  className: PropTypes.string
}
