import React from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

CSelect.propTypes = {
  forwardRef: PropTypes.object.isRequired
}

export default function CSelect({ forwardRef, ...rest }) {
  const { t } = useTranslation()

  return (
    <Select
      ref={forwardRef}
      noOptionsMessage={() => t('no_data')}
      classNamePrefix="my"
      {...rest}
    />
  )
}
