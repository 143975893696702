import React from 'react'
import { Link } from 'react-scroll'
import PropTypes from 'prop-types'

import { scrollNavSettings } from 'utils/app'

CategoryNav.propTypes = {
  cats: PropTypes.object.isRequired
}

export default function CategoryNav({ cats }) {
  return (
    <nav className="category-nav">
      <ul>
        {cats.data
          .filter(category => category.isParent)
          .map(category => (
            <li key={category.id}>
              <Link to={`category-${category.type}`} {...scrollNavSettings}>
                {category.icon()}
                <i>/</i>
                <span className="title">{category.title}</span>
                {category.subTitle && (
                  <span className="sub-title">/ {category.subTitle}</span>
                )}
              </Link>
            </li>
          ))}
      </ul>
    </nav>
  )
}
