import config from 'config'
import { cFetch } from 'tools'
import { getToken } from 'utils/app'
import { userTransformer } from 'transformers'

const {
  drivingApi: { endpoints }
} = config

export const authService = {
  fetchUser: ({ payload }) => async () => {
    try {
      const accessToken = getToken()

      if (!accessToken) return undefined

      const url = endpoints.fetchUser.replace(':locale', payload.locale)

      const response = await cFetch(url)

      const data = await response.json()

      return userTransformer.fetchUser(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  updateUser: ({ payload }) => async () => {
    try {
      const url = endpoints.updateUser.replace(':locale', payload.locale)

      const response = await cFetch(url, {
        method: 'POST',
        body: JSON.stringify(userTransformer.updateUserRequest(payload.data))
      })

      let { data } = await response.json()

      const isSucceeded = data.StatusCode === 1

      return {
        status: isSucceeded ? 200 : 403,
        message: isSucceeded ? 'succeeded' : 'failed'
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  changePassword: ({ payload }) => async () => {
    try {
      const url = endpoints.changePassword.replace(':locale', payload.locale)

      const response = await cFetch(url, {
        method: 'POST',
        body: JSON.stringify(userTransformer.updatePasswordRequest(payload.data))
      })

      const { data } = await response.json()

      const isSucceeded = data.StatusCode === 1

      return {
        status: isSucceeded ? 200 : 403,
        message: isSucceeded ? 'succeeded' : 'failed'
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  updateLvlNotify: ({ payload }) => async () => {
    try {
      const url = endpoints.updateLvlNotify.replace(':locale', payload.locale)

      const response = await cFetch(url)

      let { data } = await response.json()

      const isSucceeded = data.StatusCode === 1

      return {
        status: isSucceeded ? 200 : 403,
        message: isSucceeded ? 'succeeded' : 'failed'
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
