import { TOGGLE_RS_QUEST_BTN } from '../actions/types'

import { getIsRsQuestBtn } from 'utils/roadSign'

const isRsQuestBtn = getIsRsQuestBtn()

const initialState = {
  show: isRsQuestBtn !== null ? !(isRsQuestBtn === 'false') : true
}

export default function rsQuestBtn(state = initialState, action) {
  const actionTypes = {
    [TOGGLE_RS_QUEST_BTN]: {
      ...state,
      ...action.payload,
      show: !state.show
    },
    default: state
  }

  return Reflect.has(actionTypes, action.type)
    ? actionTypes[action.type]
    : actionTypes.default
}
