import {
  EXAM_TIMER_SET,
  EXAM_TIMER_UPDATE,
  EXAM_TIMER_DOWN,
  EXAM_TIMER_RESET
} from '../actions/types'
import { cacheExamTimer } from 'utils/ticket'

const initialState = {
  time: 0,
  past: 0,
  interval: null,
  play: true,
  down: false
}

export default function examTimer(state = initialState, action) {
  const { payload } = action
  const time = payload ? payload.time : state.time

  const actionTypes = {
    [EXAM_TIMER_SET]: {
      ...state,
      ...action.payload
    },
    [EXAM_TIMER_UPDATE]: {
      ...state,
      ...action.payload,
      play: true,
      down: !state.down
    },
    [EXAM_TIMER_DOWN]: {
      ...state,
      ...action.payload,
      past: cacheExamTimer({ cache: 'total' }) - time,
      play: false,
      down: !state.down
    },
    [EXAM_TIMER_RESET]: {
      ...state,
      ...initialState
    },
    default: state
  }

  return Reflect.has(actionTypes, action.type)
    ? actionTypes[action.type]
    : actionTypes.default
}
