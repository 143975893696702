import React from 'react'
import PropTypes from 'prop-types'

import { BreadCrumb, Activity, LastMistake, TerminatedTicket } from 'modules'

import rewardIcon from 'resources/svgs/reward.svg'

import beginnerIcon from 'resources/imgs/beginner.png'
import advancedIcon from 'resources/imgs/advance.png'
import proIcon from 'resources/imgs/pro.png'

const levels = {
  1: {
    icon: beginnerIcon,
    title: 'beginner'
  },
  2: {
    icon: advancedIcon,
    title: 'advanced'
  },
  3: {
    icon: proIcon,
    title: 'pro'
  }
}

Profile.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

export default function Profile({ t, auth }) {
  return (
    <div className="wrapper profile">
      <BreadCrumb />
      <div className="container">
        <div className="left-side">
          <div className="user">
            <div className="personal">
              <div className="avatar">
                <div className="circle">
                  {auth.user.avatar ? (
                    <img src={auth.user.avatar} alt="" />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 311.54 258.88"
                    >
                      <path
                        fill="#FD4100"
                        d="M155.77,0A155.77,155.77,0,0,0,35.31,254.53c3.41-27.25,30.63-50.31,68.8-61.22,14,13,32,20.88,51.66,20.88,19.23,0,36.89-7.49,50.7-19.94,38.5,11.87,65.14,36.27,66,64.63A155.73,155.73,0,0,0,155.77,0Zm0,195.74a55.56,55.56,0,0,1-27.63-7.47c-20-11.41-33.86-34.75-33.86-61.71,0-38.14,27.58-69.17,61.49-69.17s61.49,31,61.49,69.17c0,27.37-14.24,51-34.79,62.22A55.52,55.52,0,0,1,155.77,195.74Z"
                      />
                    </svg>
                  )}
                </div>
                <div className="reward">
                  <div className="reward-circle">
                    <img src={rewardIcon} alt="" />
                  </div>
                  <p>&nbsp;</p>
                </div>
              </div>
              <div className="info">
                <p>
                  <span>{auth.user.fullName}</span>
                  <em>id: {auth.user.id}</em>
                </p>
              </div>
            </div>
            <div className="position">
              <i className={`process lvl-${auth.user.userExp.lvl}`}></i>
              <img
                src={levels[auth.user.userExp.lvl].icon}
                alt=""
                className="icon"
              />
              <p>{t(levels[auth.user.userExp.lvl].title)}</p>
            </div>
          </div>
          <Activity />
        </div>
        <div className="right-side">
          <TerminatedTicket />
          <LastMistake />
        </div>
      </div>
    </div>
  )
}
