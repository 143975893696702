import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { tipsFetchRequested } from 'modules/tips/actions'

export const useTipService = ({ fetch } = {}) => {
  const {
    i18n: { language: locale }
  } = useTranslation()

  const dispatch = useDispatch()

  const tips = useSelector(({ tips }) => tips, shallowEqual)

  useEffect(() => {
    if (!fetch) return

    dispatch(tipsFetchRequested({ locale }))
  }, [dispatch, locale, fetch])

  return { tips }
}
