import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { TimelineMax } from 'gsap'
import { trackStartDrivingTest } from 'utils/tbcTracker'

import Block1View from '../views/Block1'

export default function Block1() {
  const { t } = useTranslation()

  const animPathsRef = useRef({})
  const animObjectsRef = useRef({})

  useEffect(() => {
    const { current: paths } = animPathsRef
    const { current: objects } = animObjectsRef

    const tl1 = new TimelineMax({ paused: true })
    const tl2 = new TimelineMax({ paused: true })
    const tl3 = new TimelineMax({ paused: true })
    const tl4 = new TimelineMax({ paused: true })
    const tl5 = new TimelineMax({ paused: true })
    const tl6 = new TimelineMax({ paused: true })

    tl1.set(objects.obj1, { alpha: 1 }).to(objects.obj1, {
      duration: 10,
      repeat: -1,
      repeatDelay: 0,
      motionPath: {
        path: paths.path1,
        align: paths.path1,
        autoRotate: true,
        alignOrigin: [0.5, 0.5]
      },
      ease:
        'CustomEase.create("custom", "M0,0,C0.17,0,0.286,0.085,0.32,0.115,0.394,0.18,0.498,0.3,0.5,0.5,0.502,0.706,0.593,0.732,0.63,0.768,0.655,0.793,0.794,1,1,1")'
    })

    tl2.set(objects.obj4, { alpha: 1 }).from(objects.obj4, {
      duration: 10,
      repeat: -1,
      repeatDelay: 0,
      motionPath: {
        path: paths.path1,
        align: paths.path1,
        autoRotate: true,
        alignOrigin: [0.5, 0.5]
      },
      ease: 'sine.out'
    })

    tl3.set(objects.obj3, { alpha: 1 }).to(objects.obj3, {
      duration: 10,
      repeat: -1,
      repeatDelay: 2,
      motionPath: {
        path: paths.path4,
        align: paths.path4,
        autoRotate: true,
        alignOrigin: [0.5, 0.5]
      },
      ease: 'power1.inOut'
    })

    tl4.set(objects.obj5, { alpha: 1 }, 4).from(
      objects.obj5,
      {
        duration: 12,
        delay: 4,
        repeat: -1,
        repeatDelay: 3,
        motionPath: {
          path: paths.path3,
          align: paths.path3,
          autoRotate: true,
          alignOrigin: [0.5, 0.5]
        },
        ease: 'slow(0.7, 0.7, false)'
      },
      0
    )

    tl5
      .set(objects.obj6, { alpha: 1 })
      .to(objects.obj6, {
        duration: 13,
        repeat: -1,
        repeatDelay: 1.5,
        motionPath: {
          path: paths.path2,
          align: paths.path2,
          autoRotate: true,
          alignOrigin: [0.5, 0.5]
        },
        ease: 'sine.out',
        onRepeat: () => tl5.seek(0)
      })
      .addPause(7.7, tl => setTimeout(() => tl.play(), 2000), [tl5])

    tl6
      .set(objects.obj2, { alpha: 1 }, 3)
      .to(
        objects.obj2,
        {
          duration: 12,
          repeat: -1,
          delay: 3,
          repeatDelay: 2.5,
          motionPath: {
            path: paths.path2,
            align: paths.path2,
            autoRotate: true,
            alignOrigin: [0.5, 0.5]
          },
          ease: 'sine.inOut',
          onRepeat: () => tl6.seek(0)
        },
        0
      )
      .addPause(11.5, tl => setTimeout(() => tl.play(), 3000), [tl6])

    setTimeout(() => {
      tl1.play()
      tl2.play()
      tl3.play()
      tl4.play()
      tl5.play()
      tl6.play()
    }, 100)

    return () => {
      tl1.kill()
      tl2.kill()
      tl3.kill()
      tl4.kill()
      tl5.kill()
      tl6.kill()
    }
  }, [])

  return (
    <Block1View
      t={t}
      animPathsRef={animPathsRef.current}
      animObjectsRef={animObjectsRef.current}
      trackStartDrivingTest={trackStartDrivingTest}
    />
  )
}
