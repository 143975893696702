import React from 'react'
import { useTranslation } from 'react-i18next'

import DsRatingSelectView from '../views/DsRatingSelect'

import { RATING_SELECT } from 'consts'
import { useSelect } from 'hooks'

const ratingList = {
  data: [
    {
      id: 1,
      title: 'rating_asc'
    },
    {
      id: 2,
      title: 'rating_desc'
    }
  ]
}

export default function DsRatingSelect() {
  const { t } = useTranslation()

  const { selectRef, defaultSelectId, listenSelectChange } = useSelect({
    fieldName: RATING_SELECT
  })

  const ratingOptions = ratingList.data.map(rating => ({
    value: rating.id,
    label: t(rating.title)
  }))

  return (
    <DsRatingSelectView
      t={t}
      ratingSelectRef={selectRef}
      ratingOptions={ratingOptions}
      listenSelectChange={listenSelectChange}
      defaultRatingId={defaultSelectId}
    />
  )
}
