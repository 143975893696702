export default function randomSplice(arr, n = 1, skip) {
  let array = [...arr]

  let selected = arr
    .sort(() => 0.5 - Math.random())
    .splice(Math.max(arr.length - n, 0))

  if (skip) {
    const key = Object.keys(skip)
    const select = array.filter(el => el[key] === skip[key])
    const filtered = array.filter(el => el[key] !== skip[key])

    if (filtered.length) {
      selected = filtered.slice(0, n)
      selected.splice(0, 1, select[0])
      selected = selected.sort(() => 0.5 - Math.random())
    }
  }
  return selected
}
