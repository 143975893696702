import React from 'react'
import PropTypes from 'prop-types'

import {
  CSelect,
  Option,
  SingleValue,
  DropdownIndicatorPin as DropdownIndicator,
  ClearIndicator
} from 'components'

CitySelect.propTypes = {
  t: PropTypes.func.isRequired,
  citySelectRef: PropTypes.object.isRequired,
  cityOptions: PropTypes.array.isRequired,
  listenSelectChange: PropTypes.func.isRequired,
  defaultCityId: PropTypes.string
}

export default function CitySelect({
  t,
  citySelectRef,
  cityOptions,
  listenSelectChange,
  defaultCityId
}) {
  return (
    <CSelect
      className="select-type-1"
      forwardRef={citySelectRef}
      options={cityOptions}
      placeholder={t('select_city')}
      onChange={listenSelectChange}
      value={cityOptions.find(({ value }) => value.toString() === defaultCityId)}
      isClearable={true}
      components={{
        Option,
        SingleValue,
        DropdownIndicator,
        ClearIndicator
      }}
    />
  )
}
