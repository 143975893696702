import { useRef } from 'react'
import { useDispatch } from 'react-redux'

import { toggleRsQuestBtn } from 'modules/rsQuestBtn/actions'
import { setIsRsQuestBtn } from 'utils/roadSign'

export default function useRsQuestBtnModal({ listenCloseModal }) {
  const noLongerShowRef = useRef()

  const dispatch = useDispatch()

  const listenNoLongerShowbtn = () => {
    const { current: element } = noLongerShowRef

    dispatch(toggleRsQuestBtn())

    if (element.checked) {
      setIsRsQuestBtn(false)
    }

    listenCloseModal()
  }

  return {
    noLongerShowRef,
    listenNoLongerShowbtn
  }
}
