import { roadSignTransformer } from 'transformers'
import config from 'config'
import { cFetch } from 'tools'

const {
  drivingApi: { endpoints }
} = config

export const roadSignService = {
  fetchRoadSigns: ({ payload }) => async () => {
    try {
      const url = endpoints.fetchRoadSigns
        .replace(':locale', payload.locale)
        .replace(':roadSignCatId', payload.roadSignCatId)

      const response = await cFetch(url)

      const data = await response.json()

      return roadSignTransformer.fetchRoadSigns(data)
    } catch (error) {
      throw new Error(error)
    }
  },

  fetchRsQuest: ({ locale }) => async () => {
    const url = endpoints.fetchRsQuest.replace(':locale', locale)

    const response = await cFetch(url)

    const { data } = await response.json()

    return data
  }
}
