import qs from 'qs'

import { TYPE_EXAM } from 'consts'

const checkForReset = (prevTickets, tickets) =>
  prevTickets?.categoryId !== tickets.categoryId ||
  prevTickets?.subjectId !== tickets.subjectId ||
  prevTickets?.type !== tickets.type

export const getTickets = () => JSON.parse(localStorage.getItem('tickets'))

export const setTickets = tickets =>
  localStorage.setItem('tickets', JSON.stringify(tickets))

export const removeSyncedTickets = () => {
  let tickets = getTickets()

  tickets = { ...tickets, syncTickets: [], sync: false }

  setTickets(tickets)
}

export const cacheTickets = ({ wizard, cache }) => {
  let tickets = wizard

  switch (cache) {
    case 'update':
      setTickets(tickets)
      break
    case 'destruct':
      localStorage.removeItem('tickets')
      break
    default:
      tickets = getTickets() || wizard

      if (checkForReset(tickets, wizard)) tickets = wizard
      setTickets(tickets)
      break
  }

  return tickets
}

export const cacheExamTimer = ({ total, current, cache, search } = '') => {
  let timer = JSON.parse(localStorage.getItem('timer'))

  switch (cache) {
    case 'update':
      timer = { ...timer, current }
      localStorage.setItem('timer', JSON.stringify(timer))
      break
    case 'total':
      timer = { current: timer?.total }
      break
    case 'destruct':
      localStorage.removeItem('timer')
      break
    default:
      const tickets = getTickets()
      const q = qs.parse(search, { ignoreQueryPrefix: true })
      if (
        checkForReset(tickets, {
          categoryId: q.categoryId,
          subjectId: undefined,
          type: TYPE_EXAM
        })
      ) {
        timer = {}
      }

      timer = { total, current: total, ...timer }
      localStorage.setItem('timer', JSON.stringify(timer))
      break
  }

  return timer?.current || total || 0
}
