import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { TimelineMax, Sine } from 'gsap'

import ExamTicketTooltipView from '../views/ExamTicketTooltip'

ExamTicketTooltip.propTypes = {
  ticket: PropTypes.object.isRequired
}

export default function ExamTicketTooltip({ ticket }) {
  const ticketTooltipRef = useRef()

  useEffect(() => {
    const { current: ticketTooltip } = ticketTooltipRef

    const wW = window.innerWidth
    const rect = ticketTooltip.getBoundingClientRect()
    const elW = rect.width + rect.x

    const tl = new TimelineMax()

    tl.from(ticketTooltip, 0.2, { y: 20, ease: Sine.easeIn }, 0).to(
      ticketTooltip,
      0.2,
      { alpha: 1, ease: Sine.easeIn },
      0
    )

    if (elW > wW) {
      ticketTooltip.classList.add('from-right')
    } else {
      ticketTooltip.classList.remove('from-right')
    }
  }, [])

  return (
    <ExamTicketTooltipView ticketTooltipRef={ticketTooltipRef} ticket={ticket} />
  )
}
