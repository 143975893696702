import React from 'react'
import { useTranslation } from 'react-i18next'

import Block3View from '../views/Block3'

import { useCategoryService, useTextPartService } from 'hooks/services'

export default function Block3() {
  const { t } = useTranslation()

  const { cats } = useCategoryService()

  const { textPart } = useTextPartService({ fetch: true, part: 'categories' })

  return <Block3View t={t} cats={cats} textPart={textPart} />
}
