import React from 'react'

import { BreadCrumb, ExamPreparation } from 'modules'

export default function Exam() {
  return (
    <div className="wrapper h-100">
      <BreadCrumb />
      <ExamPreparation />
    </div>
  )
}
