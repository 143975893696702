import React from 'react'
import PropTypes from 'prop-types'

import selectArrowIcon from 'resources/svgs/select-arrow.svg'
import closeIcon from 'resources/svgs/close-2.svg'

CategoryMultiSelect.propTypes = {
  t: PropTypes.func.isRequired,
  cats: PropTypes.object.isRequired,
  selectedIds: PropTypes.array.isRequired,
  listenCategoryBtn: PropTypes.func.isRequired,
  dropdown: PropTypes.object.isRequired
}

export default function CategoryMultiSelect({
  t,
  cats,
  selectedIds,
  listenCategoryBtn,
  dropdown
}) {
  const selectedList = cats.data.filter(category =>
    selectedIds.some(id => id === category.id.toString())
  )

  return (
    <div
      className={`category-multi-select ${dropdown.isOpen ? 'is-open' : ''}`}
      ref={dropdown.containerRef}
    >
      <div className="placeholder" onClick={() => dropdown.toggle()}>
        <div className="selected">
          {!selectedList.length && <p>{t('select_category')}</p>}
          {!!selectedList.length && (
            <div className="wrap">
              <div className="slide">
                {selectedList.map(category => (
                  <button
                    key={category.id}
                    type="button"
                    className="item-btn"
                    onClick={listenCategoryBtn(category.id)}
                  >
                    {category.icon()}
                    <i>/</i>
                    <span className="title">{category.title}</span>
                    <span className="delete">
                      <em>{t('remove')}</em>
                      <img src={closeIcon} alt="" />
                    </span>
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="indicator">
          <img src={selectArrowIcon} alt="" />
        </div>
      </div>
      {dropdown.isOpen && (
        <div className="display">
          <ul>
            {cats.data
              .filter(category => category.isParent)
              .map(category => (
                <li key={category.id}>
                  <button
                    type="button"
                    className={`item-btn ${
                      selectedIds.some(id => id === category.id.toString())
                        ? 'checked'
                        : ''
                    }`}
                    onClick={listenCategoryBtn(category.id)}
                  >
                    {category.icon()}
                    <i>/</i>
                    <span className="title">{category.title}</span>
                    <svg
                      className="check-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        className="p1"
                        d="M10,19.5A9.5,9.5,0,1,0,.5,10,9.5,9.5,0,0,0,10,19.5Z"
                      />
                      <path
                        className="p2"
                        d="M12.4,7.93a.87.87,0,0,1,1.2,0,.81.81,0,0,1,0,1.14l0,0L9.67,12.84a.87.87,0,0,1-1.2,0l-2.07-2a.81.81,0,0,1,0-1.14l0,0a.88.88,0,0,1,1.21,0l1.46,1.4Z"
                      />
                    </svg>
                  </button>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  )
}
