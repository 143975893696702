import { call, put, take, fork } from 'redux-saga/effects'

import {
  swoopFetchSucceeded,
  swoopFetchFailed,
  swoopFetchRequested
} from '../actions'
import { drivingSchoolsFetchSucceeded } from 'modules/drivingSchool/actions'

import { swoopService } from 'services'

function* fetchSwoop() {
  try {
    const data = yield call(swoopService.fetchSwoop)

    yield put(swoopFetchSucceeded({ data }))
  } catch (errors) {
    yield put(swoopFetchFailed({ errors }))
  }
}

export default function* watcher() {
  while (true) {
    const { payload } = yield take(swoopFetchRequested().type)

    yield take(drivingSchoolsFetchSucceeded().type)

    yield fork(fetchSwoop, { payload })
  }
}
