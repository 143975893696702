export default {
  drivingApi: {
    endpoints: {
      authSignInUri:
        'https://auth.my.ge/:locale/user/login/?Continue=https://driving.myauto.ge/:locale',
      authSignUpUri:
        'https://auth.my.ge/:locale/user/register/?Continue=https://driving.myauto.ge/:locale',
      authSignOutUri:
        'https://auth.my.ge/:locale/user/logout/?Continue=http://localhost/:locale',
      fetchCats: 'https://api2.martva.myauto.ge/v1/:locale/categories',
      getTicketImage: 'https://static.my.ge/martva/biletebi/:id.jpg',
      getRegulatorPhoto:
        'https://static.my.ge/myauto/martva/regulators/:id/:index.png',
      getRoadSignCatIcon: 'https://static.my.ge/myauto/martva/rs/cat-icons/:id.svg',
      getRoadSignPhoto: 'https://static.my.ge/myauto/martva/rs/road-signs/:path',
      fetchRoadSignCats: 'https://api2.martva.myauto.ge/v1/:locale/road-sign-cats',
      fetchRoadSigns:
        'https://api2.martva.myauto.ge/v1/:locale/road-signs/:roadSignCatId',
      fetchDrivingSchools:
        'https://api2.martva.myauto.ge/v1/:locale/driving-schools?city_id=:cityId&category_ids=:categoryIds&rating=:rating',
      createDrivingSchoolComment:
        'https://api2.martva.myauto.ge/v1/:locale/driving-schools/:drivingSchoolId/comment/create',
      fetchUser: 'https://api2.martva.myauto.ge/v1/:locale/users/get',
      updateUser: 'https://api2.martva.myauto.ge/v1/:locale/users/update',
      changePassword:
        'https://api2.martva.myauto.ge/v1/:locale/users/password/update',
      fetchCities: 'https://api2.martva.myauto.ge/v1/:locale/cities',
      fetchTextPart: 'https://api2.martva.myauto.ge/v1/:locale/text-parts/:id/get',
      fetchRegulators: 'https://api2.martva.myauto.ge/v1/:locale/regulators',
      fetchTips: 'https://api2.martva.myauto.ge/v1/:locale/tips',
      createTicketLog: 'https://api2.martva.myauto.ge/v1/:locale/ticket-logs/create',
      saveLastTickets:
        'https://api2.martva.myauto.ge/v1/:locale/user-exps/last-tickets/save',
      updateLvlNotify:
        'https://api2.martva.myauto.ge/v1/:locale/user-exps/lvl-notify/update',
      getLocales: 'https://api2.martva.myauto.ge/v1/{{lng}}/texts',
      getCatImage: 'https://static.my.ge/myauto/martva/cats/:catId/:photoCount.jpg',
      getDrivingSchoolPhoto:
        'https://static.my.ge/myauto/martva/driving-schools/images/:id.jpg?photo_ver=:photo_ver',
      getDrivingSchoolCover:
        'https://static.my.ge/myauto/martva/driving-schools/covers/:id.jpg?cover_ver=:cover_ver',
      getAvatar: 'https://static.my.ge/users/profile/:id.jpg?photo_ver=:version',
      getSwoop: 'https://merchant.swoop.ge/Api/Myge?categoryid=19&limit=20&SiteID=1 '
    }
  },
  mapApi:
    'pk.eyJ1Ijoid2F0c2NobyIsImEiOiJja2QwMGM3MWEwcGFwMzFtdmd6dzl2dnd2In0.87Qvg6XCptPwpFwKBl5YHQ',
  sentryApi:
    'https://736447001e6346bd5c6e2b10d92c3e96@o4505269284044800.ingest.us.sentry.io/4507430881722368'
}
