import { call, put, takeLatest } from 'redux-saga/effects'

import {
  profileEditSucceeded,
  profileEditFailed,
  profileEditRequested
} from '../actions'
import { authService } from 'services'

function* profileEdit({ payload }) {
  try {
    const data = yield call(authService.updateUser({ payload }))

    yield put(profileEditSucceeded({ data }))
  } catch (errors) {
    yield put(profileEditFailed({ errors }))
  }
}

export default function* watcher() {
  yield takeLatest(profileEditRequested().type, profileEdit)
}
