import RoadSign from './containers/RoadSign'

export default {
  exact: true,
  isNav: true,
  name: 'roadSigns',
  purePath: '/road-signs',
  path: '/road-signs/:roadSignCatId?',
  component: RoadSign
}
