import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { TicketListDescBlock, TicketListAnswersBlock } from 'modules'

import noPhoto from 'resources/svgs/no-photo.svg'

TicketBlock.propTypes = {
  ticket: PropTypes.object.isRequired,
  listenAnswer: PropTypes.func.isRequired,
  buttonsRef: PropTypes.object.isRequired,
  isExam: PropTypes.bool
}

export default function TicketBlock({ ticket, listenAnswer, buttonsRef, isExam }) {
  const { t } = useTranslation()

  return (
    <div className="ticket">
      <div className="question">
        <div className="cover">
          {!isExam && <TicketListDescBlock description={ticket.description} />}
          {!!ticket.imageType && <img src={ticket.image} alt="" />}
          {!ticket.imageType && (
            <div className="no-photo">
              <img src={noPhoto} alt="" />
              <p>{t('ticket_doesnt_have_photo')}</p>
            </div>
          )}
        </div>
        <p className="caption">{ticket.question}</p>
      </div>
      <TicketListAnswersBlock
        answers={ticket.answers}
        listenAnswer={listenAnswer}
        buttonsRef={buttonsRef}
      />
    </div>
  )
}
