import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import TicketListView from '../views/TicketList'
import { Loader } from 'components'

import {
  TYPE_TICKETS,
  // TICKET_QUEST_MODAL,
  TICKET_FINISH_MODAL,
  COMPLETE_STATUS
} from 'consts'
import { toggleModal } from 'modules/modalManager/actions'
import { useTicketWizard } from 'hooks'
import { useTicketService } from 'hooks/services'

export default function TicketList() {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { tickets, options } = useTicketService({ fetch: true })

  // const intervalCallback = tickets =>
  //   dispatch(
  //     toggleModal({
  //       name: TICKET_QUEST_MODAL,
  //       data: tickets
  //     })
  //   )

  const finishCallback = ticketWizard =>
    dispatch(
      toggleModal({
        name: TICKET_FINISH_MODAL,
        data: { ...ticketWizard, status: COMPLETE_STATUS }
      })
    )

  const { ticketWizard, listenAnswer } = useTicketWizard({
    ticketList: tickets,
    // intervalNum: 30,
    // intervalTicketCount: 5,
    // intervalCallback,
    finishCallback,
    options: {
      ...options,
      type: TYPE_TICKETS
    }
  })

  if (ticketWizard.loading || !ticketWizard.ticket) return <Loader />

  return (
    <TicketListView t={t} ticketWizard={ticketWizard} listenAnswer={listenAnswer} />
  )
}
