import { call, put, takeLatest } from 'redux-saga/effects'

import {
  subjectsFetchSucceeded,
  subjectsFetchFailed,
  subjectsFetchRequested
} from '../actions'

import { subjectService } from 'services'

function* fetchSubjects({ payload: { locale } }) {
  try {
    const data = yield call(subjectService.fetchSubjects({ locale }))

    yield put(subjectsFetchSucceeded({ data }))
  } catch (errors) {
    yield put(subjectsFetchFailed({ errors }))
  }
}

export default function* watcher() {
  yield takeLatest(subjectsFetchRequested().type, fetchSubjects)
}
