import React from 'react'
import { useTranslation } from 'react-i18next'

import SubjectSelectView from '../views/SubjectSelect'

import { SUBJECT_SELECT } from 'consts'
import { useSelect } from 'hooks'
import { useSubjectService } from 'hooks/services'

export default function SubjectSelect() {
  const { t } = useTranslation()

  const { selectRef, defaultSelectId, listenSelectChange } = useSelect({
    fieldName: SUBJECT_SELECT
  })

  const { subjects } = useSubjectService({ fetch: true })

  return (
    <SubjectSelectView
      t={t}
      subjectSelectRef={selectRef}
      subjectOptions={subjects.data}
      listenSelectChange={listenSelectChange}
      defaultSubjectId={defaultSelectId}
    />
  )
}
