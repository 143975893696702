import {
  CREATE_DS_REVIEW_REQUESTED,
  CREATE_DS_REVIEW_SUCCEEDED,
  CREATE_DS_REVIEW_FAILED
} from '../actions/types'

const initialState = {
  data: undefined,
  error: undefined,
  loading: false
}

export default function dsReview(state = initialState, action) {
  const actionTypes = {
    [CREATE_DS_REVIEW_REQUESTED]: {
      ...state,
      ...action.payload,
      loading: true
    },
    [CREATE_DS_REVIEW_SUCCEEDED]: {
      ...state,
      ...action.payload,
      loading: false
    },
    [CREATE_DS_REVIEW_FAILED]: {
      ...state,
      ...action.payload,
      loading: false
    },
    default: state
  }

  return Reflect.has(actionTypes, action.type)
    ? actionTypes[action.type]
    : actionTypes.default
}
