import config from 'config'

const {
  drivingApi: { endpoints }
} = config

export const drivingSchoolTransformer = {
  fetchDrivingSchools: data => {
    try {
      return data.data.map(
        ({
          id,
          has_photo,
          has_cover,
          cover_ver,
          photo_ver,
          title,
          desc,
          address,
          phone,
          rating,
          site_url,
          map_lng,
          map_lat,
          map_zoom,
          order,
          categories,
          timetables,
          comments
        }) => ({
          swoop: false,
          id,
          hasPhoto: !!has_photo,
          hasCover: !!has_cover,
          cover: endpoints.getDrivingSchoolCover
            .replace(':id', id)
            .replace(':cover_ver', cover_ver),
          photo: endpoints.getDrivingSchoolPhoto
            .replace(':id', id)
            .replace(':photo_ver', photo_ver),
          title,
          desc,
          address,
          phone,
          rating,
          siteUrl: site_url,
          mapLng: map_lng,
          mapLat: map_lat,
          mapZoom: map_zoom,
          order,
          categories: categories.map(({ title }) => title).join(', '),
          timetables: timetables.map(({ id, day, open, close, order }) => ({
            id,
            day,
            open,
            close,
            order
          })),
          comments: comments.map(
            ({ id, author, text, rate, order, created_at }) => ({
              id,
              author,
              text,
              rate,
              order,
              createdAt: created_at
            })
          )
        })
      )
    } catch (error) {
      throw new Error(error)
    }
  },

  createDrivingSchoolCommentRequest: ({ reviewText, starCount }) => ({
    text: reviewText,
    rate: starCount
  }),

  createDrivingSchoolComment: data => {
    try {
      const { data: comment } = data

      return {
        id: comment.id,
        drivingSchoolId: comment.commentable_id,
        author: comment.author,
        text: comment.text,
        rate: comment.rate,
        order: comment.order,
        createdAt: comment.created_at
      }
    } catch (error) {
      throw new Error(error)
    }
  }
}
