import {
  UPDATE_LVL_NOTIFY_REQUESTED,
  UPDATE_LVL_NOTIFY_SUCCEEDED,
  UPDATE_LVL_NOTIFY_FAILED
} from './types'

export const updateLvlNotifyRequested = content => ({
  type: UPDATE_LVL_NOTIFY_REQUESTED,
  payload: content
})
export const updateLvlNotifySucceeded = content => ({
  type: UPDATE_LVL_NOTIFY_SUCCEEDED,
  payload: content
})
export const updateLvlNotifyFailed = content => ({
  type: UPDATE_LVL_NOTIFY_FAILED,
  payload: content
})
