import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import ProfileEditModalView from '../views/ProfileEditModal'

import { PROFILE_PASSWORD_MODAL } from 'consts'
import { useModalManager } from 'hooks'
import { months } from 'utils/app'
import { toggleModal } from 'modules/modalManager/actions'
import { profileEditRequested, profileEditReset } from '../actions'

ProfileEditModal.propTypes = {
  data: PropTypes.object.isRequired
}

export default function ProfileEditModal({ data }) {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()

  const dispatch = useDispatch()

  const { auth, profileEdit } = useSelector(({ auth, profileEdit }) => ({
    auth,
    profileEdit
  }))

  const { modalRef, listenCloseModal } = useModalManager()

  const { control, handleSubmit, setValue, setError, errors } = useForm()

  const [birthData, setBirthData] = useState({ months: [], days: [], years: [] })

  useEffect(() => {
    const monthsData = months.map((month, index) => ({
      value: ++index,
      label: t(month)
    }))

    let days = []

    for (let i = 1; i <= 31; i++) {
      days.push({
        value: i,
        label: i
      })
    }

    const now = new Date().getFullYear()

    let years = []

    for (let i = now; i >= 1900; i--) {
      years.push({
        value: i,
        label: i
      })
    }

    setValue('birthMonth', monthsData[0])
    setValue('birthDay', days[0])
    setValue('birthYear', years[0])

    setBirthData({ months, days, years })
  }, [t, setValue])

  useEffect(() => {
    if (!profileEdit.processed) return
    if (profileEdit.data.status !== 200)
      return setError('currentPassword', 'notMatch', t('password_is_incorrect'))

    dispatch(toggleModal())

    return () => dispatch(profileEditReset())
    // eslint-disable-next-line
  }, [dispatch, profileEdit.processed])

  const listenProfileEditSubmit = data =>
    dispatch(
      profileEditRequested({
        locale,
        data
      })
    )

  const listenProfilePasswordModal = () =>
    dispatch(toggleModal({ name: PROFILE_PASSWORD_MODAL, data: { anchor: true } }))

  return (
    <ProfileEditModalView
      t={t}
      auth={auth}
      modalRef={modalRef}
      listenCloseModal={listenCloseModal}
      listenProfileEditSubmit={listenProfileEditSubmit}
      listenProfilePasswordModal={listenProfilePasswordModal}
      control={control}
      handleSubmit={handleSubmit}
      errors={errors}
      months={birthData.months}
      days={birthData.days}
      years={birthData.years}
      data={data}
      profileEdit={profileEdit}
    />
  )
}
