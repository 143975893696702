import React from 'react'
import PropTypes from 'prop-types'

DsStarRating.propTypes = {
  t: PropTypes.func.isRequired,
  starsRef: PropTypes.object.isRequired,
  events: PropTypes.func.isRequired,
  starState: PropTypes.object.isRequired
}

export default function DsStarRating({ t, starsRef, events, starState }) {
  let stars = []

  for (let i = 1; i <= 5; i++) {
    stars.push(
      <li
        key={i}
        data-index={i}
        ref={el =>
          el ? (starsRef.current = new Set([...starsRef.current, el])) : null
        }
        {...events(i)}
        data-events={starState.disabled ? 'disabled' : ''}
        className={i <= starState.count ? 'starred' : ''}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
          <path d="M13.903 17.064l-.937-5.745 3.997-4.096-5.521-.844L9 1.177 6.558 6.379l-5.52.844 3.996 4.096-.937 5.745L9 14.354l4.903 2.71z" />
        </svg>
      </li>
    )
  }

  return (
    <>
      <span>
        {starState.count} {t('stars')}
      </span>
      <ul>{stars}</ul>
    </>
  )
}
