import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import TicketFinishModalView from '../views/TicketFinishModal'

import config from 'config'
import { useModalManager } from 'hooks'
import { useAuthService } from 'hooks/services'

const {
  drivingApi: { endpoints }
} = config

TicketFinishModal.propTypes = {
  data: PropTypes.object.isRequired
}

export default function TicketFinishModal({ data }) {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()

  const hisotry = useHistory()

  const examTimer = useSelector(({ examTimer }) => examTimer)

  const { modalRef, listenCloseModal } = useModalManager(data)

  const { auth } = useAuthService()

  const redirectToAuth = () => {
    window.location.href = endpoints.authSignInUri.replace(/:locale/g, locale)
  }

  const listenNavBtn = () => {
    if (!auth.user) return redirectToAuth()
    hisotry.push('/profile')
    listenCloseModal()
  }

  return (
    <TicketFinishModalView
      t={t}
      modalRef={modalRef}
      listenCloseModal={listenCloseModal}
      status={data.status}
      correct={data.correct}
      wrong={data.wrong}
      examTimer={examTimer}
      listenNavBtn={listenNavBtn}
    />
  )
}
