import {
  CREATE_DS_REVIEW_REQUESTED,
  CREATE_DS_REVIEW_SUCCEEDED,
  CREATE_DS_REVIEW_FAILED
} from './types'

export const createDsReviewRequested = content => ({
  type: CREATE_DS_REVIEW_REQUESTED,
  payload: content
})
export const createDsReviewSucceeded = content => ({
  type: CREATE_DS_REVIEW_SUCCEEDED,
  payload: content
})
export const createDsReviewFailed = content => ({
  type: CREATE_DS_REVIEW_FAILED,
  payload: content
})
