import React from 'react'
import PropTypes from 'prop-types'
import validator from 'validator'

import { CInputV1, CSelectV2, DropdownIndicator } from 'components'

ProfileEditModal.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  modalRef: PropTypes.object.isRequired,
  listenCloseModal: PropTypes.func.isRequired,
  listenProfileEditSubmit: PropTypes.func.isRequired,
  listenProfilePasswordModal: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  months: PropTypes.array.isRequired,
  days: PropTypes.array.isRequired,
  years: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  profileEdit: PropTypes.object.isRequired
}

export default function ProfileEditModal({
  t,
  auth,
  modalRef,
  listenCloseModal,
  listenProfileEditSubmit,
  listenProfilePasswordModal,
  control,
  handleSubmit,
  errors,
  months,
  days,
  years,
  data,
  profileEdit
}) {
  return (
    <div
      className="modal-manager"
      ref={modalRef}
      id="modal-manager"
      style={{ opacity: data.anchor ? 1 : 0 }}
    >
      <div className="modal-manager-container">
        <div className="modal-manager-view profile-modal-view">
          <button
            type="button"
            className="modal-manager-close-btn"
            onClick={listenCloseModal}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.01 7.01">
              <path d="M4.84,3.5,6.73,5.39a1,1,0,0,1,0,1.34.94.94,0,0,1-1.33,0h0L3.5,4.84,1.61,6.73A.94.94,0,0,1,.27,5.4h0L2.17,3.5.28,1.61A.94.94,0,0,1,.27.28a1,1,0,0,1,1.34,0h0L3.5,2.17,5.39.28A.94.94,0,0,1,6.73,1.61Z" />
            </svg>
          </button>
          <p className="caption">{t('profile')}</p>
          <div className="form-container">
            <form
              onSubmit={handleSubmit(listenProfileEditSubmit)}
              autoComplete="off"
            >
              <div className="field size">
                <CInputV1
                  control={control}
                  name="firstName"
                  value={auth.user.firstName}
                  textile={t('first_name')}
                  error={errors.firstName}
                  rules={{
                    required: { value: true, message: t('required_filed') },
                    pattern: {
                      value: /^[A-Za-zА-Яа-я\n ა-შც-ჱ]+$/i,
                      message: t('only_words')
                    },
                    minLength: {
                      value: 2,
                      message: t('min_symbol').replace('%s', 2)
                    },
                    maxLength: {
                      value: 48,
                      message: t('max_symbol').replace('%s', 48)
                    }
                  }}
                />
                <CInputV1
                  control={control}
                  name="lastName"
                  value={auth.user.lastName}
                  textile={t('last_name')}
                  error={errors.lastName}
                  rules={{
                    required: { value: true, message: t('required_filed') },
                    pattern: {
                      value: /^[A-Za-zА-Яа-я\n ა-შც-ჱ]+$/i,
                      message: t('only_words')
                    },
                    minLength: {
                      value: 2,
                      message: t('min_symbol').replace('%s', 2)
                    },
                    maxLength: {
                      value: 48,
                      message: t('max_symbol').replace('%s', 48)
                    }
                  }}
                />
              </div>
              <div className="field">
                <CInputV1
                  control={control}
                  name="email"
                  value={auth.user.email}
                  textile={t('email')}
                  error={errors.email}
                  disabled={true}
                  rules={{
                    required: { value: true, message: t('required_filed') },
                    validate: {
                      isEmail: string =>
                        validator.isEmail(string) || t('incorrect_email')
                    }
                  }}
                />
              </div>
              {false && (
                <div className="field">
                  <CSelectV2
                    name="birthMonth"
                    control={control}
                    options={months}
                    textile={t('month')}
                    components={{ DropdownIndicator }}
                  />
                  <CSelectV2
                    name="birthDay"
                    control={control}
                    options={days}
                    textile={t('day')}
                    components={{ DropdownIndicator }}
                  />
                  <CSelectV2
                    name="birthYear"
                    control={control}
                    options={years}
                    textile={t('year')}
                    components={{ DropdownIndicator }}
                  />
                </div>
              )}
              <div className="field field-password">
                <CInputV1
                  control={control}
                  name="currentPassword"
                  type="password"
                  value=""
                  textile={t('password')}
                  error={errors.currentPassword}
                  rules={{
                    required: { value: true, message: t('required_filed') },
                    minLength: {
                      value: 2,
                      message: t('min_symbol').replace('%s', 2)
                    },
                    maxLength: {
                      value: 48,
                      message: t('max_symbol').replace('%s', 48)
                    }
                  }}
                />
                <button type="button" onClick={listenProfilePasswordModal}>
                  {t('change_password')}
                </button>
              </div>
              <div className="field">
                <div className="submit-btn-block">
                  <button type="submit" disabled={profileEdit.loading}>
                    {t('save')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
