import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { roadSignCatsFetchRequested } from 'modules/rsNav/actions'

export const useRoadSignCatService = ({ fetch } = {}) => {
  const {
    i18n: { language: locale }
  } = useTranslation()

  const dispatch = useDispatch()

  const roadSignCats = useSelector(({ roadSignCats }) => roadSignCats, shallowEqual)

  useEffect(() => {
    if (!fetch) return

    dispatch(roadSignCatsFetchRequested({ locale }))
  }, [dispatch, locale, fetch])

  return { roadSignCats }
}
