import React, { useEffect, useState } from 'react'

import { AuthBlock } from 'modules'
import { BurgerNav } from './BurgerNav'
import { Link } from 'components'

import { useMatchConfig } from 'hooks'

import logo from 'resources/svgs/myauto-logo.svg'
import shamefulArrow from 'resources/svgs/shameful-arrow.svg'

export const Header = () => {
  const [shameful, setShameful] = useState('')

  const [headerClass, setHeaderClass] = useState('')

  const { headerBuild, headerClassList } = useMatchConfig({
    headerClass
  })

  useEffect(() => {
    window.addEventListener('scroll', listenWindowScroll)

    return () => {
      window.removeEventListener('scroll', listenWindowScroll)
    }
  }, [])

  const listenWindowScroll = () => {
    let classList = ''
    if (window.scrollY > 10) {
      classList = 'overflow-header'
    }
    setHeaderClass(classList)
  }

  return (
    <header className={`header ${shameful} ${headerClassList}`}>
      <div className="implements">
        <h1 className="logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </h1>
        <div className="space-between">{headerBuild()}</div>
        <div className="non-stretchable">
          <AuthBlock />
          <BurgerNav />
        </div>
        <button
          type="button"
          className="shameful-btn"
          onClick={() => setShameful(prState => (prState ? '' : 'shameful'))}
        >
          <img src={shamefulArrow} alt="" />
        </button>
      </div>
    </header>
  )
}
