export const locationTransformer = {
  fetchCities: data => {
    try {
      return data.data.map(({ id, order, title }) => ({
        id,
        order,
        title
      }))
    } catch (error) {
      throw new Error(error)
    }
  }
}
