import { call, put, takeLatest } from 'redux-saga/effects'

import {
  rsQuestFetchSucceeded,
  rsQuestFetchFailed,
  rsQuestFetchRequested
} from '../actions'

import { roadSignService } from 'services'

function* fetchRsQuest() {
  try {
    const data = yield call(roadSignService.fetchRsQuest)

    yield put(rsQuestFetchSucceeded(data))
  } catch (error) {
    yield put(rsQuestFetchFailed(error))
  }
}

export default function* watcher() {
  yield takeLatest(rsQuestFetchRequested().type, fetchRsQuest)
}
