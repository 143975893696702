import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import { weeks } from 'utils/app'

import clockDarkIcon from 'resources/svgs/s-clock-dark.svg'
import arrDownIcon from 'resources/svgs/drop.svg'

DsTimeSchedule.propTypes = {
  t: PropTypes.func.isRequired,
  dropdown: PropTypes.object.isRequired,
  listenScheduleBtn: PropTypes.func.isRequired,
  calendar: PropTypes.object.isRequired,
  timetables: PropTypes.array.isRequired
}

export default function DsTimeSchedule({
  t,
  dropdown,
  listenScheduleBtn,
  calendar,
  timetables
}) {
  const weekDay = moment().isoWeekday()
  const weekDays = Object.entries(weeks).sort((a, b) => b[0] - weekDay)

  let calendarRow = []
  for (const [key, value] of weekDays) {
    const week = timetables.find(wc => wc.day.toString() === key)
    calendarRow.push(
      <div className="item" key={key}>
        <p>{t(value)}</p>
        <p>{`${week?.open || t('closed')} - ${week?.close || t('closed')}`}</p>
      </div>
    )
  }

  return (
    <div className={`schedule ${dropdown.isOpen ? 'is-open' : ''}`}>
      <img src={clockDarkIcon} alt="" className="icon" />
      <div className="wrap" ref={dropdown.containerRef}>
        {!dropdown.isOpen ? (
          <button type="button" className="current" onClick={listenScheduleBtn}>
            <p>
              <i>{t(calendar.now)}</i>
              <b>- {calendar.close}</b>
            </p>
            <img src={arrDownIcon} alt="" />
          </button>
        ) : (
          <div className="schedule-list">{calendarRow}</div>
        )}
      </div>
    </div>
  )
}
