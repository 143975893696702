import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ProfileView from '../views/Profile'
import { ScrollTo } from 'components'

import { PROFILE_PROGRESS_MODAL } from 'consts'
import { toggleModal } from 'modules/modalManager/actions'

export default function Profile() {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const auth = useSelector(({ auth }) => auth)

  useEffect(() => {
    const userExp = auth.user.userExp

    if (!userExp.nextLvlNotify) return
    dispatch(
      toggleModal({
        name: PROFILE_PROGRESS_MODAL,
        data: { level: userExp.lvl }
      })
    )
  }, [dispatch, auth])

  return (
    <>
      <ProfileView t={t} auth={auth} />
      <ScrollTo />
    </>
  )
}
