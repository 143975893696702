import { getToken } from 'utils/app'

export default function cFetch(url, options = {}) {
  const accessToken = getToken()

  return fetch(url, {
    method: 'GET',
    ...options,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      ...options.headers
    }
  })
}
