import React from 'react'
import { useDispatch } from 'react-redux'

import { RS_FULLSCREEN_MODAL } from 'consts'
import { toggleModal } from 'modules/modalManager/actions'
import { useRoadSignService } from 'hooks/services'

export default function RsList() {
  const dispatch = useDispatch()

  const { roadSigns } = useRoadSignService({ fetch: true })

  const listenFullscreenBtn = index => () => {
    dispatch(toggleModal({ name: RS_FULLSCREEN_MODAL, data: { roadSigns, index } }))
  }

  return (
    <>
      {roadSigns.data.map((roadSign, index) => (
        <div
          className="sign-item"
          key={roadSign.id}
          onClick={listenFullscreenBtn(index)}
        >
          <div className="sign-img">
            <img src={roadSign.photo} alt="" />
          </div>
          <p className="sign-title">{roadSign.title}</p>
        </div>
      ))}
    </>
  )
}
