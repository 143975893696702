import React from 'react'
import PropTypes from 'prop-types'

export const C1CatIcon = ({ className = '' } = '') => (
  <svg
    className={`license-i c1-cat-i ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 23.6 100 52.8"
    enableBackground="new 0 23.6 100 52.8"
    xmlSpace="preserve"
  >
    <path
      d="M71.6,67.6c0,4.9,3.9,8.8,8.8,8.8c4.9,0,8.8-3.9,8.8-8.8c0-4.9-3.9-8.8-8.8-8.8C75.5,58.8,71.6,62.7,71.6,67.6z M76.3,67.6
          c0-2.2,1.8-4.1,4.1-4.1c2.2,0,4.1,1.8,4.1,4.1c0,2.2-1.8,4.1-4.1,4.1C78.1,71.7,76.3,69.8,76.3,67.6z M9.4,67.6
          c0,4.9,3.9,8.8,8.8,8.8s8.8-3.9,8.8-8.8c0-4.9-3.9-8.8-8.8-8.8C13.4,58.8,9.4,62.7,9.4,67.6z M14.2,67.6c0-2.2,1.8-4.1,4.1-4.1
          c2.2,0,4.1,1.8,4.1,4.1c0,2.2-1.8,4.1-4.1,4.1C16,71.7,14.2,69.8,14.2,67.6z M0.1,65.9c0,0.1,0,0.1,0,0.2c0.2,0.7,0.4,0.9,1.6,1.2
          c0.8,0.2,3.7,0.9,6,1.4c0-0.3-0.1-0.7-0.1-1c0-5.9,4.8-10.6,10.6-10.6c5.9,0,10.6,4.8,10.6,10.6c0,0.9-0.1,1.8-0.3,2.6
          c8.8,0,33.6,0.1,41.6,0.1c-0.2-0.9-0.3-1.8-0.3-2.7c0-5.9,4.8-10.6,10.6-10.6S91,61.7,91,67.6c0,0.6-0.1,1.2-0.2,1.8
          c0.3,0.1,0.7,0.1,1.2,0.1c1.7,0,4.3-0.3,5.2-0.7c1.7-0.6,3.3-2,2.6-7.5c-0.1-0.7-0.2-1.3-0.2-2c-0.5-4.6-0.8-7.9-6.6-10.4
          c-6.3-2.7-7.2-3.1-7.2-3.1c0,0-0.2-0.1-0.2-0.1l-0.3-0.1c0,0-14.8-15.8-17.3-17.8c-2.4-2-6.3-4.3-11.1-4.3c0,0-51.5,0-51.6,0
          c-0.4,0-1.3,0.2-1.8,2c-0.6,2.1-3.1,18.5-3.2,21.8C0.1,49.9-0.1,65.2,0.1,65.9z M89,50.5c0.1-0.2,0.3-0.4,0.6-0.4c0,0,0.1,0,0.1,0
          c5.4,1,6.4,3.6,7.2,7.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.3,0.2-0.5,0.2H94c-0.3,0-0.6-0.2-0.6-0.5l0-0.1c-0.4-2.8-0.7-4.6-4.1-6.4
          C89,51.1,88.9,50.8,89,50.5z M57.5,52.5c0-1.2,1-2.2,2.3-2.2H63c1.2,0,2.2,1,2.2,2.2s-1,2.2-2.2,2.2h-3.2
          C58.5,54.7,57.5,53.7,57.5,52.5z M56.2,41.9c-0.5,0-1.2-0.6-2.2-5.3c-1.1-5.3-0.8-7.1-0.7-7.4c0-0.1,0-0.1,0.1-0.2
          c0.1-0.2,0.3-0.3,1.5-0.4c0.9,0,2.1,0,3.7,0c4.5,0.2,4.9,0.6,5,0.7c0.4,0.3,1.9,1.2,6.1,5.7c5.8,6.3,7.6,9.2,7.6,9.3
          c0.1,0.1,0.1,0.3,0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.2c0,0,0,0,0,0c-0.1,0-5.4-0.4-13.2-1.7c-3-0.5-5.2-0.9-6.5-1.2
          C56.7,42.1,56.4,42,56.2,41.9z M48.2,52.5c0-1.2,1-2.2,2.2-2.2h3.2c1.2,0,2.2,1,2.2,2.2s-1,2.2-2.2,2.2h-3.2
          C49.2,54.7,48.2,53.7,48.2,52.5z M7.1,46.9c0-1.2,1-2.2,2.2-2.2h25c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.2-2.2,2.2h-25
          C8.2,49.1,7.1,48.1,7.1,46.9z"
    ></path>
  </svg>
)

C1CatIcon.propTypes = {
  className: PropTypes.string
}
