import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

import ExamStartAtCheckboxView from '../views/ExamStartAtCheckbox'

import { AUTO_TICKET_CHECKBOX } from 'consts'

export default function ExamStartAtCheckbox() {
  const { t } = useTranslation()

  const history = useHistory()
  const location = useLocation()

  const [defaultChecked, setDefaultChecked] = useState(true)

  useEffect(() => {
    const q = qs.parse(location.search, { ignoreQueryPrefix: true })
    const query = { [AUTO_TICKET_CHECKBOX]: 'on', ...q }

    history.push({ search: qs.stringify(query) })

    setDefaultChecked(prevState =>
      query[AUTO_TICKET_CHECKBOX] === 'off' ? false : prevState
    )
    // eslint-disable-next-line
  }, [])

  const listenAtCheckbox = ({ target: { checked } }) => {
    const q = qs.parse(location.search, { ignoreQueryPrefix: true })

    q[AUTO_TICKET_CHECKBOX] = checked ? 'on' : 'off'

    setDefaultChecked(prevState => !prevState)

    history.push({ search: qs.stringify(q) })
  }

  return (
    <ExamStartAtCheckboxView
      t={t}
      listenAtCheckbox={listenAtCheckbox}
      defaultChecked={defaultChecked}
    />
  )
}
