import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import qs from 'qs'

export default function useWatchQuery() {
  const { location, push } = useHistory()

  const [q, setQ] = useState({})

  useEffect(() => {
    const query = getQ()

    setQ(query)
    // eslint-disable-next-line
  }, [location.search])

  const getQ = () => {
    const { search } = location

    return qs.parse(search, { ignoreQueryPrefix: true })
  }

  const watchQ = name => q[name]

  return { watchQ, q, push }
}
