import { useState } from 'react'

export default function useMouseOver({ setMapOpts, locker }) {
  const [moState, setMoState] = useState({})

  const onMouseEnter = data => () => {
    setMoState(data)
    setMapOpts(prevState => ({
      ...prevState,
      ...(!locker
        ? {
            center: {
              lat: data.latitude,
              lng: data.longitude
            },
            zoom: [14]
          }
        : {})
    }))
  }

  const onMouseLeave = () => setMoState({})

  const moEvents = data => ({
    onMouseEnter: onMouseEnter(data),
    onMouseLeave
  })

  const mo = (key, value) => !locker && moState[key] === value

  return { mo, moEvents }
}
