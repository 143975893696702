import React from 'react'
import PropTypes from 'prop-types'

import { TicketWizardLvlBlock } from 'modules'

RsQuestModal.propTypes = {
  t: PropTypes.func.isRequired,
  modalRef: PropTypes.object.isRequired,
  listenCloseModal: PropTypes.func.isRequired,
  isWrong: PropTypes.bool.isRequired,
  answers: PropTypes.array.isRequired,
  listenAnswerBtn: PropTypes.func.isRequired,
  rsQuest: PropTypes.object.isRequired,
  wizardCorrectnessState: PropTypes.func.isRequired,
  wizardLevel: PropTypes.number.isRequired
}

export default function RsQuestModal({
  t,
  modalRef,
  listenCloseModal,
  isWrong,
  answers,
  listenAnswerBtn,
  rsQuest,
  wizardCorrectnessState,
  wizardLevel
}) {
  return (
    <>
      <div
        className="modal-manager rs-quest-modal"
        ref={modalRef}
        id="modal-manager"
      >
        <div className="modal-manager-container rs-quest-modal-container">
          <button
            type="button"
            className="modal-manager-close-btn"
            onClick={listenCloseModal}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.01 7.01">
              <path d="M4.84,3.5,6.73,5.39a1,1,0,0,1,0,1.34.94.94,0,0,1-1.33,0h0L3.5,4.84,1.61,6.73A.94.94,0,0,1,.27,5.4h0L2.17,3.5.28,1.61A.94.94,0,0,1,.27.28a1,1,0,0,1,1.34,0h0L3.5,2.17,5.39.28A.94.94,0,0,1,6.73,1.61Z" />
            </svg>
          </button>
          <div
            className={`modal-manager-view rs-quest-modal-view ${
              isWrong ? 'fillground' : ''
            }`}
          >
            {answers.map((chunk, i) => (
              <div className="rs-quest-answer-box" key={i}>
                {chunk.map(answer => (
                  <div
                    className={`rs-quest-answer ${
                      isWrong && answer.correct ? 'true' : ''
                    }`}
                    key={answer.id}
                    onClick={listenAnswerBtn(answer)}
                  >
                    <button type="button">
                      <img src={answer.image} alt="" />
                      <i>{t('correct')}</i>
                    </button>
                    <span role="img" aria-label="happy" className="happy-emoji">
                      🎉
                    </span>
                  </div>
                ))}
              </div>
            ))}
            <div className="rs-quest-answer-box rs-quest-answer-box-empty">
              <div className="rs-quest-answer">
                <div className="rs-quest-empty"></div>
              </div>
              <div className="rs-quest-answer">
                {isWrong ? (
                  <p className="wrong-type">
                    <span role="img" aria-label="smirk">
                      😏
                    </span>
                    <span>{t('try_again')}</span>
                  </p>
                ) : (
                  <p>
                    {!rsQuest.loading ? rsQuest.data[wizardLevel].question : '...'}
                  </p>
                )}
              </div>
              <div className="rs-quest-answer">
                <div className="rs-quest-empty"></div>
              </div>
            </div>
          </div>
          <div className="modal-manager-lvl">
            <TicketWizardLvlBlock wizardLvl={wizardCorrectnessState() - 1} />
          </div>
        </div>
      </div>
    </>
  )
}
