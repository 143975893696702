import React, { Component } from 'react'
import * as Sentry from '@sentry/react'

import { isProduction } from 'utils/isProduction'
import config from 'config'

export class ErrorBoundaryProvider extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidMount() {
    if (isProduction()) {
      Sentry.init({
        dsn: config.sentryApi,
        integrations: [
          // eslint-disable-next-line
          Sentry.reactRouterV5BrowserTracingIntegration({ history }),
          Sentry.browserTracingIntegration({}),
          Sentry.replayIntegration({})
        ],
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 0.1
      })
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error) {
    if (isProduction()) {
      Sentry.captureException(error)
    }
  }

  render() {
    if (this.state.hasError) {
      return <p>ბოდიშს გიხდით შეფერხებისთვის</p>
    }

    return this.props.children
  }
}
