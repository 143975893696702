import React from 'react'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import { carouselSettings, carousel2Settings } from 'utils/roadSign'
import { useRsFullscreenModal, useModalManager } from 'hooks'

RsFullscreenModal.propTypes = {
  data: PropTypes.object.isRequired
}

export default function RsFullscreenModal({ data }) {
  const { t } = useTranslation()
  const { modalRef, listenCloseModal } = useModalManager()

  const {
    carousel1Ref,
    carousel2Ref,
    carousel1,
    carousel2,
    carouselPrev,
    carouselNext
  } = useRsFullscreenModal({ modalRef })

  return (
    <div className="rs-carousel-modal" ref={modalRef} id="modal-manager">
      <button type="button" className="prev" onClick={carouselPrev}>
        {t('prev')}
      </button>
      <div className="rs-carousel-modal-container">
        <button
          type="button"
          className="rs-carousel-modal-close"
          onClick={listenCloseModal}
        >
          {t('close')}
        </button>
        <Slider
          initialSlide={data.index}
          asNavFor={carousel2}
          ref={carousel1Ref}
          {...carouselSettings}
        >
          {data.roadSigns.data.map(roadSign => (
            <div className="slide" key={roadSign.id}>
              <div className="overflow">
                <img src={roadSign.photo} alt="" />
                <p>{roadSign.title}</p>
                <span>{roadSign.desc}</span>
              </div>
            </div>
          ))}
        </Slider>
        <Slider
          initialSlide={data.index}
          asNavFor={carousel1}
          ref={carousel2Ref}
          {...carousel2Settings}
        >
          {data.roadSigns.data.map(roadSign => (
            <div className="slide" key={roadSign.id}>
              <div className="overflow">
                <img src={roadSign.photo} alt="" />
                <p>{roadSign.title}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <button type="button" className="next" onClick={carouselNext}>
        {t('next')}
      </button>
    </div>
  )
}
