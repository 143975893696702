import React from 'react'
import PropTypes from 'prop-types'

import progress2Img from 'resources/svgs/progress-2lvl.svg'
import progress3Img from 'resources/svgs/progress-3lvl.svg'
import fbIcon from 'resources/svgs/fb.svg'
import twIcon from 'resources/svgs/tw.svg'

const levels = {
  2: progress2Img,
  3: progress3Img
}

ProfileProgressModal.propTypes = {
  t: PropTypes.func.isRequired,
  modalRef: PropTypes.object.isRequired,
  listenCloseModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
}

export default function ProfileProgressModal({
  t,
  modalRef,
  listenCloseModal,
  data
}) {
  return (
    <>
      <div className="modal-manager" ref={modalRef} id="modal-manager">
        <div className="modal-manager-container">
          <div className="modal-manager-view progress-modal-view">
            <button
              type="button"
              className="modal-manager-close-btn"
              onClick={listenCloseModal}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.01 7.01">
                <path d="M4.84,3.5,6.73,5.39a1,1,0,0,1,0,1.34.94.94,0,0,1-1.33,0h0L3.5,4.84,1.61,6.73A.94.94,0,0,1,.27,5.4h0L2.17,3.5.28,1.61A.94.94,0,0,1,.27.28a1,1,0,0,1,1.34,0h0L3.5,2.17,5.39.28A.94.94,0,0,1,6.73,1.61Z" />
              </svg>
            </button>
            <img src={levels[data.level]} alt="" className="background" />
            <p>{t('congratulations')}</p>
            <span>{t('next_level_reached')}</span>
            <ul>
              <li>
                <a
                  href="https://google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={fbIcon} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twIcon} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
