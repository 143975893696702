import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { subjectsFetchRequested } from 'modules/subjectSelect/actions'

export const useSubjectService = ({ fetch } = {}) => {
  const {
    i18n: { language: locale }
  } = useTranslation()

  const dispatch = useDispatch()

  const subjects = useSelector(({ subjects }) => subjects)

  useEffect(() => {
    if (!fetch) return

    dispatch(subjectsFetchRequested({ locale }))
  }, [dispatch, locale, fetch])

  return { subjects }
}
