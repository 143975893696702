import React from 'react'
import PropTypes from 'prop-types'

export const ACatIcon = ({ className = '' } = '') => (
  <svg
    className={`license-i a-cat-i ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 33 22"
  >
    <path d="M17.879 5.501a8.86 8.86 0 0 1 3.073.61l.365.152.88 1.163 1.811-1.462a33.664 33.664 0 0 1 6.033 1.974c.275.674.514 1.363.716 2.064l.144.528-3.594 1.615a.083.083 0 0 0-.045.097.08.08 0 0 0 .085.06c2.307-.237 4.438 1.295 5.022 3.612.584 2.317-.552 4.728-2.678 5.682-2.126.955-4.608.168-5.85-1.853-1.242-2.022-.873-4.676.869-6.256a.084.084 0 0 0 .012-.105.08.08 0 0 0-.061-.037l-.037.006-.482.217c-1.102.495-1.835 1.584-1.909 2.81l-.006.206v1.09h-11.45c-.513 2.169-2.39 3.698-4.553 3.707-2.162.008-4.051-1.505-4.581-3.67-.53-2.165.437-4.424 2.343-5.477 1.84-1.017 4.1-.656 5.559.86l.153.168.998-.717-.42-.814A21.892 21.892 0 0 1 .361 6.39L0 6.044h6.745l1.7 2.13 4.58.747 2.153-3.047a8.849 8.849 0 0 1 2.7-.373zm9.988 8.01a3.417 3.417 0 0 0-1.492.32l-.198.1 2.113 2.832a.63.63 0 0 1-.115.857.583.583 0 0 1-.766-.032l-.066-.073-2.112-2.83a3.719 3.719 0 0 0-.278 4.552c.96 1.396 2.732 1.909 4.253 1.232 1.522-.677 2.378-2.36 2.055-4.038-.323-1.678-1.737-2.895-3.394-2.92zm-21.62-.617l-.213.004c-1.543.078-2.855 1.19-3.233 2.736-.377 1.548.269 3.168 1.592 3.992a3.425 3.425 0 0 0 4.161-.408c1.1-1.02 1.457-2.636.915-4.04l-.08-.19-2.842 2.04a.585.585 0 0 1-.813-.168.632.632 0 0 1 .054-.773l.079-.072 2.84-2.04a3.465 3.465 0 0 0-2.247-1.072l-.212-.01zm5.24.588l-1.106.793c.151.3.272.615.36.941l.06.246h2.382l-1.696-1.98zM2.806 10l1.02.7-.5.334-.775.126L.34 13.5 0 13.176l1.499-2.539L2.806 10zM24.397 0a20.144 20.144 0 0 1 4.777 6 36.05 36.05 0 0 0-5.634-1.665l-2.018-2.058L24.397 0zm-2.49 4.298a.808.808 0 0 1 .38.702.808.808 0 0 1-.38.702.736.736 0 0 1-.77 0 .808.808 0 0 1-.38-.702.808.808 0 0 1 .38-.702.736.736 0 0 1 .77 0z" />
  </svg>
)

ACatIcon.propTypes = {
  className: PropTypes.string
}
