import DrivingSchool from './views/DrivingSchool'

export default {
  exact: true,
  isNav: true,
  name: 'drivingSchools',
  purePath: '/driving-schools',
  path: '/driving-schools',
  component: DrivingSchool
}
