import React from 'react'
import { components } from 'react-select'

import pinIcon from 'resources/svgs/pin-gray.svg'

export default function DropdownIndicatorPin(props) {
  return (
    <components.DropdownIndicator {...props}>
      <img src={pinIcon} alt="" className="pin" />
    </components.DropdownIndicator>
  )
}
