import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import RsModalView from '../views/RsModal'

import { useRsModal } from 'hooks'

RsModalWrap.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}

function RsModalWrap({ data, index }) {
  const { t } = useTranslation()
  const {
    modalRef,
    modalContainerRef,
    listenFullscreenBtn,
    listenCloseModal
  } = useRsModal({
    roadSigns: data.roadSigns,
    index
  })

  return (
    <RsModalView
      t={t}
      data={data}
      index={index}
      modalRef={modalRef}
      modalContainerRef={modalContainerRef}
      listenFullscreenBtn={listenFullscreenBtn}
      listenCloseModal={listenCloseModal}
    />
  )
}

export default function RsModal() {
  const rsModal = useSelector(state => state.rsModal)
  return (
    <>{rsModal.show && <RsModalWrap data={rsModal.data} index={rsModal.index} />}</>
  )
}
