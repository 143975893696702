import React from 'react'
import PropTypes from 'prop-types'

import { TicketListAnswersBlock, TicketWizardLvlBlock } from 'modules'

TicketQuestModal.propTypes = {
  modalRef: PropTypes.object.isRequired,
  buttonsRef: PropTypes.object.isRequired,
  listenCloseModal: PropTypes.func.isRequired,
  ticketWizard: PropTypes.object.isRequired,
  listenAnswer: PropTypes.func.isRequired,
  answers: PropTypes.array.isRequired
}

export default function TicketQuestModal({
  modalRef,
  buttonsRef,
  listenCloseModal,
  ticketWizard,
  listenAnswer,
  answers
}) {
  return (
    <div className="modal-manager" ref={modalRef} id="modal-manager">
      <div className="modal-manager-container">
        <button
          type="button"
          className="modal-manager-close-btn"
          onClick={listenCloseModal}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.01 7.01">
            <path d="M4.84,3.5,6.73,5.39a1,1,0,0,1,0,1.34.94.94,0,0,1-1.33,0h0L3.5,4.84,1.61,6.73A.94.94,0,0,1,.27,5.4h0L2.17,3.5.28,1.61A.94.94,0,0,1,.27.28a1,1,0,0,1,1.34,0h0L3.5,2.17,5.39.28A.94.94,0,0,1,6.73,1.61Z" />
          </svg>
        </button>
        <div className="modal-manager-view ticket-quest-modal-view">
          <div className="question">
            <div className="cover">
              <img src={ticketWizard.ticket.image} alt="" />
            </div>
            <p className="caption">{ticketWizard.ticket.question}</p>
          </div>
          <TicketListAnswersBlock
            buttonsRef={buttonsRef}
            answers={answers}
            listenAnswer={listenAnswer}
          />
        </div>
        <div className="modal-manager-lvl">
          <TicketWizardLvlBlock wizardLvl={ticketWizard.wizardLvl} />
        </div>
      </div>
    </div>
  )
}
