import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import ExamTimerView from '../views/ExamTimer'

import { examTimerDown } from '../actions'
import { cacheExamTimer } from 'utils/ticket'

ExamTimer.propTypes = {
  ticketWizard: PropTypes.object.isRequired
}

export default function ExamTimer({ ticketWizard }) {
  const dispatch = useDispatch()

  const examTimer = useSelector(({ examTimer }) => examTimer)

  const [timer, setTimer] = useState(examTimer)

  useEffect(() => {
    if (!examTimer.play) return

    setTimer(examTimer)

    const interval = setInterval(
      () =>
        setTimer(prevState => {
          const time = prevState.time - 1

          return {
            ...prevState,
            interval,
            time: cacheExamTimer({ current: time, cache: 'update' })
          }
        }),
      1000
    )

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [examTimer.play])

  useEffect(() => {
    if (timer.time) return

    clearInterval(timer.interval)
    dispatch(examTimerDown(timer))
    // eslint-disable-next-line
  }, [dispatch, timer.time])

  useEffect(() => {
    if (!timer.time) return
    if (!ticketWizard.finished) return
    if (ticketWizard.hasSkipped) return

    clearInterval(timer.interval)

    dispatch(examTimerDown(timer))
    // eslint-disable-next-line
  }, [dispatch, ticketWizard.finished])

  return <ExamTimerView examTimer={timer} />
}
