import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import PropTypes from 'prop-types'

RsNav.propTypes = {
  roadSignCats: PropTypes.object.isRequired
}

export default function RsNav({ roadSignCats }) {
  const routeMatch = useRouteMatch('/road-signs/:roadSignCatId')

  return (
    <nav className="rs-nav">
      <ul>
        {roadSignCats.data.map((category, index) => (
          <li key={category.id}>
            <NavLink
              to={`/road-signs/${category.id}`}
              isActive={match => {
                if (!routeMatch && !index) return true
                return !!match
              }}
            >
              <img src={category.icon} alt="" />
              <span>{category.title}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}
