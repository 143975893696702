import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

import { CategorySelect, ExamStartBlock } from 'modules'

import { CATEGORY_SELECT } from 'consts'

export default function ExamPreparation() {
  const { t } = useTranslation()

  const location = useLocation()

  const [categoryId, setCategoryId] = useState()

  useEffect(() => {
    const q = qs.parse(location.search, { ignoreQueryPrefix: true })

    setCategoryId(q[CATEGORY_SELECT])
  }, [location])

  return (
    <div className="exam-preparation" id="container">
      <div className="gradient-top">
        <h1>{t('exam')}</h1>
      </div>
      <div className="gradient-bottom">
        <CategorySelect menuIsOpen={!categoryId ? !categoryId : undefined} />
        {categoryId && <ExamStartBlock />}
      </div>
    </div>
  )
}
