import React from 'react'
import PropTypes from 'prop-types'

export const TsCatIcon = ({ className = '' } = '') => (
  <svg
    className={`license-i ts-cat-i ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 20"
  >
    <path d="M15.665 16.901h-4.398c0-4.865-2.986-7.4-6.624-7.4-.408 0-.516.025-1.33.076V8.195h2.714V0h.815v2.817H15.8l.163 6.428 5.538.384v2.868c-3.8 0-5.837 2.1-5.837 4.404zM9.828 8.195V4.609H8.2v3.586h1.63zM12 8.579l2.253 2.586-.027-6.556H12v3.97zm-7.195 2.356c2.661 0 4.806 2.023 4.806 4.532C9.61 17.977 7.466 20 4.805 20 2.145 20 0 17.977 0 15.467c0-2.51 2.145-4.532 4.805-4.532zm0 6.607c1.222 0 2.2-.922 2.2-2.075 0-1.152-.978-2.074-2.2-2.074-1.221 0-2.199.922-2.199 2.074 0 1.153.978 2.075 2.2 2.075zm15.801-3.995c1.874 0 3.394 1.434 3.394 3.2 0 1.768-1.52 3.202-3.394 3.202-1.873 0-3.393-1.434-3.393-3.201 0-1.767 1.52-3.201 3.393-3.201zm0 4.148c.543 0 1.005-.41 1.005-.947 0-.512-.462-.948-1.005-.948-.543 0-1.004.436-1.004.948s.461.947 1.004.947z" />
  </svg>
)

TsCatIcon.propTypes = {
  className: PropTypes.string
}
