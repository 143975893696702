import {
  SET_DROPDOWN,
  TICKET_LOG_CREATE_REQUESTED,
  TICKET_LOG_CREATE_SUCCEEDED,
  TICKET_LOG_CREATE_FAILED,
  SAVE_LAST_TICKETS_REQUESTED,
  SAVE_LAST_TICKETS_SUCCEEDED,
  SAVE_LAST_TICKETS_FAILED
} from './types'

export const setDropdown = content => ({
  type: SET_DROPDOWN,
  payload: content
})

export const ticketLogCreateRequested = content => ({
  type: TICKET_LOG_CREATE_REQUESTED,
  payload: content
})
export const ticketLogCreateSucceeded = content => ({
  type: TICKET_LOG_CREATE_SUCCEEDED,
  payload: content
})
export const ticketLogCreateFailed = content => ({
  type: TICKET_LOG_CREATE_FAILED,
  payload: content
})

export const saveLastTicketsRequested = content => ({
  type: SAVE_LAST_TICKETS_REQUESTED,
  payload: content
})
export const saveLastTicketsSucceeded = content => ({
  type: SAVE_LAST_TICKETS_SUCCEEDED,
  payload: content
})
export const saveLastTicketsFailed = content => ({
  type: SAVE_LAST_TICKETS_FAILED,
  payload: content
})
