import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import PropTypes from 'prop-types'

import { Link } from 'components'

import ticketImg from 'resources/imgs/ticket.png'
import bitmapImg from 'resources/imgs/bitmap.png'

Block2.propTypes = {
  t: PropTypes.func.isRequired,
  textPart: PropTypes.object.isRequired
}

export default function Block2({ t, textPart }) {
  return (
    <section className="home-section driving-theory-section right-decoration">
      <div className="home-section-left">
        <div className="theory-info">
          <h2 className="home-section-title">{textPart.data.about.title}</h2>
          <div className="theory-text">
            <div className="tickets-all">
              <span className="tickets-count">1792</span>
              <span>{t('home_text2')}</span>
            </div>
            {ReactHtmlParser(textPart.data.about.desc)}
          </div>
        </div>
      </div>
      <div className="home-section-right">
        <div className="theory-details">
          <div className="theory-themes">
            <div className="theory-item">
              <span className="theory-icon category-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="14"
                  viewBox="0 0 35 14"
                >
                  <path
                    fill="#A5B4BD"
                    fillRule="nonzero"
                    d="M27.495 5.955c-2.143 0-3.88 1.801-3.88 4.023 0 2.221 1.737 4.022 3.88 4.022s3.88-1.8 3.88-4.022c0-2.222-1.737-4.023-3.88-4.023zm0 6.153c-1.133 0-2.055-.956-2.054-2.13 0-1.175.921-2.13 2.054-2.13 1.133 0 2.054.955 2.054 2.13 0 1.174-.921 2.13-2.054 2.13zM20.045.798C15.493-1.471 6.08 1.613 0 3.667c0 0 1.75 6.193 2.815 7.102.491.418 1.054.65 1.663.783a4.858 4.858 0 0 1-.265-1.573c0-2.61 2.048-4.733 4.564-4.733 2.518 0 4.566 2.123 4.566 4.733 0 .417-.058.817-.155 1.202a42.653 42.653 0 0 1 3.409-.153c1.967 0 4.39.372 6.661.703a4.843 4.843 0 0 1-.328-1.752c0-2.61 2.048-4.733 4.565-4.733 2.517 0 4.565 2.123 4.565 4.733 0 .671-.138 1.31-.382 1.888 1.19-.349 1.938-1.002 2.446-2.204 1.129-2.668 1.034-2.948.46-3.494-2.134-2.027-4.978-2.609-7.877-2.78-1.648-.098-2.404-.422-3.24-.79-1.288-.701-2.622-1.4-3.422-1.8zm-5.894 3.855c-2.263 0-3.062-1.557-3.062-1.557l.88-1.757c1.366-.231 2.69-.382 3.898-.382 1.572 0 2.846.227 3.783.694.851.425 2.183 1.166 3.392 1.825 0 0-.288 1.177-1.377 1.177h-7.514zM8.777 5.955c-2.142 0-3.879 1.801-3.88 4.023 0 2.221 1.738 4.022 3.88 4.022 2.144 0 3.881-1.8 3.881-4.022 0-2.222-1.737-4.023-3.88-4.023zm0 6.153c-1.132 0-2.053-.956-2.053-2.13 0-1.175.921-2.13 2.053-2.13 1.134 0 2.056.955 2.056 2.13 0 1.174-.922 2.13-2.056 2.13z"
                    opacity=".6"
                  />
                </svg>
              </span>
              B B1 {t('category')}
            </div>
            <div className="theory-item">
              <span className="theory-icon sign-icon">
                <img src={bitmapImg} alt="" />
              </span>
              {t('warning')}
            </div>
            <Link
              className="more-btn"
              to={{ pathname: '/tickets', search: '?categoryId=2&subjectId=3' }}
            >
              {t('more')}
            </Link>
          </div>
          <div className="theory-img">
            <img src={ticketImg} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}
