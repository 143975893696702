import {
  TIPS_FETCH_REQUESTED,
  TIPS_FETCH_SUCCEEDED,
  TIPS_FETCH_FAILED
} from './types'

export const tipsFetchRequested = content => ({
  type: TIPS_FETCH_REQUESTED,
  payload: content
})
export const tipsFetchSucceeded = content => ({
  type: TIPS_FETCH_SUCCEEDED,
  payload: content
})
export const tipsFetchFailed = content => ({
  type: TIPS_FETCH_FAILED,
  payload: content
})
