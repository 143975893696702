import allTickets from 'data/tickets.json'

import config from 'config'
import { userTransformer } from 'transformers'

const {
  drivingApi: { endpoints }
} = config

const constructTicket = ticket => ({
  id: ticket.id,
  imageType: ticket.pic,
  categoryIds: ticket.cIds,
  subjectId: ticket.sId,
  image: endpoints.getTicketImage.replace(':id', ticket.id),
  description: ticket.d,
  question: ticket.q,
  answers: ticket.a.map(answer => ({
    id: answer.id,
    answer: answer.a,
    correct: !!answer.c
  }))
})

export const ticketTransformer = {
  createTicketLogRequest: (data, type) => ({
    tickets: data.tickets.map(ticket => ({
      type: type,
      ticket_id: ticket.id,
      category_ids: ticket.categoryIds.join(','),
      subject_id: ticket.subjectId,
      answer_id: ticket.result.answerId,
      is_correct: ticket.result.isCorrect,
      time: ticket.time
    }))
  }),

  createTicketLog: data => {
    try {
      const {
        latest_ticket_mistakes,
        ticket_log_by_week,
        ticket_log_by_month
      } = data.data

      return {
        latestTicketMistakes: userTransformer.latestTicketMistakes(
          latest_ticket_mistakes
        ),
        ticketLogByWeek: {
          ...ticket_log_by_week,
          loop: Object.values(ticket_log_by_week.loop)
        },
        ticketLogByMonth: {
          ...ticket_log_by_month,
          loop: Object.values(ticket_log_by_month.loop)
        }
      }
    } catch (error) {
      throw new Error(error)
    }
  },

  saveLastTicketsRequest: (data, type) => ({
    tickets: {
      ...data.tickets,
      type: type,
      sync: false,
      syncTickets: [],
      intervalTickets: data.tickets.intervalTickets.map(({ id }) => ({ id })),
      tickets: data.tickets.tickets.map(({ id }) => ({ id })),
      ticket: { id: data.tickets.ticket.id },
      prevTickets: data.tickets.prevTickets.map(({ id, result }) => ({ id, result }))
    }
  }),

  saveLastTickets: data => {
    try {
      const { data: tickets } = data

      if (!tickets.last_tickets) return
      const parsed = JSON.parse(tickets.last_tickets)

      return {
        ...parsed,
        intervalTickets: parsed.intervalTickets.map(({ id }) =>
          constructTicket(allTickets[id])
        ),
        tickets: parsed.tickets.map(({ id }) => constructTicket(allTickets[id])),
        ticket: constructTicket(allTickets[parsed.ticket.id]),
        prevTickets: parsed.prevTickets.map(({ id, result }) => ({
          id,
          ...constructTicket(allTickets[id]),
          result
        }))
      }
    } catch (error) {
      throw new Error(error)
    }
  },

  fetchTickets: tickets => {
    try {
      const data = []
      for (const ticket in tickets) data.push(constructTicket(tickets[ticket]))
      return data
    } catch (error) {
      throw new Error(error)
    }
  }
}
