import {
  TICKETS_FETCH_REQUESTED,
  TICKETS_FETCH_SUCCEEDED,
  TICKETS_FETCH_FAILED
} from './types'

export const ticketsFetchRequested = content => ({
  type: TICKETS_FETCH_REQUESTED,
  payload: content
})
export const ticketsFetchSucceeded = content => ({
  type: TICKETS_FETCH_SUCCEEDED,
  payload: content
})
export const ticketsFetchFailed = content => ({
  type: TICKETS_FETCH_FAILED,
  payload: content
})
