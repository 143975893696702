import { call, put, takeLatest } from 'redux-saga/effects'

import {
  ticketLogCreateSucceeded,
  ticketLogCreateFailed,
  ticketLogCreateRequested
} from '../actions'

import { ticketService } from 'services'

function* ticketLogCreate({ payload }) {
  try {
    // const auth = yield select(({ auth }) => auth)

    // if (auth.loading) return

    const data = yield call(ticketService.createTicketLog({ payload }))

    yield put(ticketLogCreateSucceeded({ data }))
  } catch (errors) {
    yield put(ticketLogCreateFailed({ errors }))
  }
}

export default function* watcher() {
  yield takeLatest(ticketLogCreateRequested().type, ticketLogCreate)
}
