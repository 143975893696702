import React from 'react'
import PropTypes from 'prop-types'

import TicketBlockView from '../views/TicketBlock'

import { useKeyToBtn } from 'hooks'

TicketBlock.propTypes = {
  ticket: PropTypes.object.isRequired,
  listenAnswer: PropTypes.func.isRequired,
  isLocked: PropTypes.bool.isRequired,
  isExam: PropTypes.bool
}

export default function TicketBlock({ ticket, listenAnswer, isLocked, isExam }) {
  const { buttonsRef } = useKeyToBtn({
    keys: [
      [49, 50, 51, 52],
      [97, 98, 99, 100]
    ],
    lockupCallback: ({ target }) => {
      const modalManager = document.getElementById('modal-manager')
      return target.contains(modalManager) || isLocked
    }
  })

  return (
    <TicketBlockView
      buttonsRef={buttonsRef}
      ticket={ticket}
      listenAnswer={listenAnswer}
      isExam={isExam}
    />
  )
}
