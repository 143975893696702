import React from 'react'
import PropTypes from 'prop-types'

TicketListAnswersBlock.propTypes = {
  buttonsRef: PropTypes.object.isRequired,
  answers: PropTypes.array.isRequired,
  listenAnswerBtn: PropTypes.func.isRequired,
  answered: PropTypes.object.isRequired
}

export default function TicketListAnswersBlock({
  buttonsRef,
  answers,
  listenAnswerBtn,
  answered
}) {
  return (
    <div className={`answers ${answered.isAnswered ? 'answered' : ''}`}>
      {answers.map((answer, index) => (
        <button
          data-key={index}
          ref={el => el && (buttonsRef.current[el.dataset.key] = el)}
          type="button"
          key={answer.id}
          disabled={answered.isAnswered}
          className={
            answered.isAnswered && answer.correct ? answered.className : undefined
          }
          onClick={listenAnswerBtn(answer.correct, answer.id)}
        >
          <span>{++index}</span>
          <em>{answer.answer}</em>
        </button>
      ))}
    </div>
  )
}
