import { call, put, fork, take } from 'redux-saga/effects'

import {
  roadSignCatsFetchSucceeded,
  roadSignCatsFetchFailed,
  roadSignCatsFetchRequested
} from '../actions'

import { roadSignCatService } from 'services'

function* fetchRoadSignCats({ payload }) {
  try {
    const data = yield call(roadSignCatService.fetchRoadSignCats({ payload }))

    yield put(roadSignCatsFetchSucceeded({ data }))
  } catch (errors) {
    yield put(roadSignCatsFetchFailed({ errors }))
  }
}

export default function* watcher() {
  while (true) {
    const { payload } = yield take(roadSignCatsFetchRequested().type)

    yield fork(fetchRoadSignCats, { payload })
  }
}
