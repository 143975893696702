import React from 'react'
import PropTypes from 'prop-types'

import { ExamTicketTooltip } from 'modules'

ExamProgressBarTicket.propTypes = {
  listenPlaceholder: PropTypes.func.isRequired,
  isTicketTooltip: PropTypes.object.isRequired,
  totalCount: PropTypes.number.isRequired,
  counter: PropTypes.number.isRequired,
  tickets: PropTypes.array.isRequired
}

export default function ExamProgressBarTicket({
  listenPlaceholder,
  isTicketTooltip,
  totalCount,
  counter,
  tickets
}) {
  let progressBar = []

  for (let i = 0, j = 0; i < totalCount; i++) {
    const { isCorrect, skipped } = tickets[i] ? tickets[i].result : {}

    const tooltipClass =
      counter === ++j
        ? 'current'
        : isCorrect === true
        ? 'true'
        : isCorrect === false
        ? 'false'
        : ''

    progressBar.push(
      <li
        onMouseEnter={listenPlaceholder(i)}
        onMouseLeave={listenPlaceholder(i)}
        className={tooltipClass}
        key={i}
      >
        {tickets[i] && !skipped && (
          <>
            <i className="placeholder"></i>
            {isTicketTooltip[i] && <ExamTicketTooltip ticket={tickets[i]} />}
          </>
        )}
      </li>
    )
  }

  return <ul>{progressBar}</ul>
}
