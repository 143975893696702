import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import { WIZARD_MAX_LVL } from 'consts'
import { examTimerSet, examTimerReset } from 'modules/examTimer/actions'
import { randomSplice } from 'tools'
import { cacheTickets, cacheExamTimer } from 'utils/ticket'
import { useTicketLogCreate } from 'hooks'

export default function useTicketWizard({
  ticketList,
  finishCallback,
  intervalNum,
  intervalCallback,
  intervalTicketCount,
  time,
  examTimer = {},
  examTryWrong,
  autoTicket = true,
  options
}) {
  const wizardInitState = {
    type: '',
    categoryId: 0,
    subjectId: 0,
    tickets: [],
    ticket: { answers: [] },
    prevTickets: [],
    syncTickets: [],
    intervalTickets: [],
    interval: false,
    intervalCounter: 0,
    totalCount: 0,
    counter: 1,
    progressV1: 0,
    progressV2: 0,
    ticketCount: 0,
    correct: 0,
    wrong: 0,
    wizardLvl: 0,
    circularSkippedIndex: {},
    isLocked: false,
    nextTicketTimeoutId: null,
    nextTicketClosure: null,
    isSkippedRound: false,
    hasSkipped: false,
    finished: false,
    restart: false,
    loading: true,
    sync: false
  }

  const dispatch = useDispatch()

  const { search } = useLocation()

  const { doSync } = useTicketLogCreate({ interval: true, clearTickets: false })

  const [ticketWizard, setTicketWizard] = useState(wizardInitState)

  useEffect(() => {
    if (ticketList.loading) return

    setTicketWizard(prevState => {
      const { data } = ticketList

      const tickets = [...data]
      const ticket = randomSplice(tickets)[0]
      const totalCount = data.length

      const { progressV2 } = constructProgressBar(0, totalCount)

      return cacheTickets({
        wizard: {
          ...prevState,
          ...options,
          tickets,
          ticket,
          totalCount,
          progressV2,
          loading: false,
          sync: false
        }
      })
    })

    return () => {
      doSync()

      setTicketWizard(prevState => {
        return {
          ...prevState,
          ...wizardInitState
        }
      })
      cacheTickets({ cache: 'destruct' })
    }
    // eslint-disable-next-line
  }, [ticketList, ticketWizard.restart])

  useEffect(() => {
    if (!ticketWizard.finished) return
    if (typeof finishCallback !== 'function') return
    if (ticketWizard.hasSkipped) return backToSkippedTicket()

    setTicketWizard(prevState => ({
      ...prevState,
      restart: !prevState.restart
    }))

    finishCallback(ticketWizard)

    // eslint-disable-next-line
  }, [ticketWizard.finished])

  useEffect(() => {
    if (!ticketWizard.interval) return
    if (typeof intervalCallback !== 'function') return

    intervalCallback({
      data: randomSplice(ticketWizard.intervalTickets, intervalTicketCount),
      loading: ticketWizard.loading
    })

    setTicketWizard(prevState => ({ ...prevState, intervalTickets: [] }))

    // eslint-disable-next-line
  }, [ticketWizard.interval])

  useEffect(() => {
    if (!time) return
    dispatch(examTimerSet({ time: cacheExamTimer({ total: time, search }) }))

    return () => {
      dispatch(examTimerReset())
      cacheExamTimer({ cache: 'destruct' })
    }
  }, [dispatch, time, search])

  useEffect(() => {
    if (!time) return
    if (examTimer.play) return

    clearTimeout(ticketWizard.nextTicketTimeoutId)

    setTicketWizard(prevState =>
      cacheTickets({
        wizard: {
          ...prevState,
          hasSkipped: false,
          finished: true
        },
        cache: 'update'
      })
    )
    // eslint-disable-next-line
  }, [examTimer.down])

  const listenAnswer = (isCorrect, answerId) => {
    const nextTicketClosure = nextTicket({ isCorrect, answerId })

    let nextTicketTimeoutId = null
    let isLocked = false

    if (autoTicket) {
      nextTicketTimeoutId = setTimeout(nextTicketClosure, 1000)
      isLocked = true
    }

    setTicketWizard(prevState => {
      const interval = false

      let correct = prevState.correct
      let wrong = prevState.wrong
      let wizardLvl = prevState.wizardLvl

      isCorrect
        ? ++correct && wizardLvl < WIZARD_MAX_LVL && ++wizardLvl
        : ++wrong && wizardLvl && --wizardLvl

      return cacheTickets({
        wizard: {
          ...prevState,
          interval,
          correct,
          wrong,
          wizardLvl,
          isLocked,
          nextTicketTimeoutId,
          nextTicketClosure,
          sync: true
        },
        cache: 'update'
      })
    })
  }

  const listenSkipBtn = isLocked => () => {
    if (isLocked) return

    const nextTicketClosure = ticketWizard.nextTicketClosure

    nextTicketClosure ? nextTicketClosure() : nextTicket({ skipped: true })(true)
  }

  const nextTicket = result => skipped => {
    if (ticketWizard.isSkippedRound) {
      return nextSkippedTicket(result)(skipped)
    }

    setTicketWizard(prevState => {
      let hasSkipped = skipped || prevState.hasSkipped
      const isLocked = false
      const nextTicketClosure = null

      const tickets = [...prevState.tickets]
      let ticket = randomSplice(tickets, 1)[0]

      const prevTickets = [
        ...prevState.prevTickets,
        {
          ...prevState.ticket,
          result
        }
      ]

      let syncTickets = prevState.syncTickets

      const dateNow = moment()

      if (!result.skipped) {
        syncTickets = [
          ...prevState.syncTickets,
          {
            ...prevState.ticket,
            result,
            time: moment.duration(dateNow.diff(prevState.updatedAt)).asSeconds()
          }
        ]
      }

      const isWrongLever = wrongLever(prevState.wrong)

      if (isWrongLever) {
        ticket = null
        hasSkipped = false
      }

      if (!ticket) {
        return cacheTickets({
          wizard: {
            ...prevState,
            prevTickets,
            syncTickets,
            isLocked,
            nextTicketClosure,
            hasSkipped,
            finished: true,
            updatedAt: dateNow
          },
          cache: 'update'
        })
      }

      const { counter, progressV1, progressV2 } = constructProgressBar(
        prevState.counter,
        prevState.totalCount
      )

      const { intervalTickets, intervalCounter, interval } = constructIntervalTicket(
        prevState,
        prevState.ticket,
        skipped
      )

      return cacheTickets({
        wizard: {
          ...prevState,
          tickets,
          ticket,
          prevTickets,
          syncTickets,
          intervalTickets,
          interval,
          intervalCounter,
          counter,
          progressV1,
          progressV2,
          isLocked,
          nextTicketClosure,
          hasSkipped,
          updatedAt: moment()
        },
        cache: 'update'
      })
    })
  }

  const nextSkippedTicket = result => skipped =>
    setTicketWizard(prevState => {
      let hasSkipped = skipped || prevState.hasSkipped
      const isLocked = false
      const nextTicketClosure = null

      let tickets = [...prevState.tickets]
      let ticket = prevState.ticket

      let skippedTicketIndex = tickets.findIndex(
        (ticket, index) =>
          !prevState.circularSkippedIndex[index] && ticket.result.skipped
      )

      const circularSkippedIndex = {
        ...prevState.circularSkippedIndex,
        [skippedTicketIndex]: skippedTicketIndex.toString()
      }

      tickets[skippedTicketIndex] = {
        ...ticket,
        ...{ result: { skipped: false, ...result } }
      }

      let prevTickets = [...prevState.prevTickets]
      prevTickets[skippedTicketIndex] = tickets[skippedTicketIndex]

      skippedTicketIndex = tickets.findIndex(
        (ticket, index) => !circularSkippedIndex[index] && ticket.result.skipped
      )

      ticket = tickets[skippedTicketIndex]

      const isWrongLever = wrongLever(prevState.wrong)

      if (isWrongLever) {
        ticket = null
        hasSkipped = false
      }

      if (!ticket) {
        return cacheTickets({
          wizard: {
            ...prevState,
            prevTickets,
            isLocked,
            nextTicketClosure,
            hasSkipped,
            finished: true
          },
          cache: 'update'
        })
      }

      const { counter, progressV1, progressV2 } = constructProgressBar(
        skippedTicketIndex,
        prevState.totalCount
      )

      const { intervalTickets, intervalCounter, interval } = constructIntervalTicket(
        prevState,
        ticket,
        skipped
      )

      return cacheTickets({
        wizard: {
          ...prevState,
          tickets,
          ticket,
          prevTickets,
          intervalTickets,
          interval,
          intervalCounter,
          counter,
          progressV1,
          progressV2,
          circularSkippedIndex,
          isLocked,
          nextTicketClosure,
          hasSkipped
        },
        cache: 'update'
      })
    })

  const backToSkippedTicket = () =>
    setTicketWizard(prevState => {
      const tickets = [...prevState.prevTickets]
      const skippedTicketIndex = tickets.findIndex(ticket => ticket.result.skipped)
      const ticket = tickets[skippedTicketIndex]

      const { counter, progressV1, progressV2 } = constructProgressBar(
        skippedTicketIndex,
        prevState.totalCount
      )

      return cacheTickets({
        wizard: {
          ...prevState,
          tickets,
          ticket,
          intervalTickets: [],
          interval: false,
          intervalCounter: 0,
          counter,
          progressV1,
          progressV2,
          circularSkippedIndex: {},
          isSkippedRound: true,
          hasSkipped: false,
          finished: false
        },
        cache: 'update'
      })
    })

  const constructProgressBar = (index, total) => {
    const counter = index + 1
    const progressV1 = (index * 100) / (total - 1)
    const progressV2 = (100 / total) * counter

    return {
      counter,
      progressV1,
      progressV2
    }
  }

  const constructIntervalTicket = (state, ticket, skipped) => {
    let intervalTickets = [...state.intervalTickets, { ...ticket }]
    let intervalCounter = state.intervalCounter + 1
    let interval = state.interval

    if (skipped) {
      intervalTickets = state.intervalTickets
      intervalCounter = state.intervalCounter
    }

    if (intervalCounter === intervalNum) {
      interval = true
      intervalCounter = 0
    }

    return {
      intervalTickets,
      intervalCounter,
      interval
    }
  }

  const wrongLever = wrong => wrong > examTryWrong

  return { ticketWizard, listenAnswer, listenSkipBtn }
}
