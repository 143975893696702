export const carouselSettings = {
  className: 'carousel-1',
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  arrow: false,
  infinite: true,
  speed: 500
}

export const carousel2Settings = {
  className: 'carousel-2',
  slidesToShow: 5,
  swipeToSlide: true,
  focusOnSelect: true,
  centerMode: true,
  slidesToScroll: 1,
  dots: false,
  arrow: false,
  infinite: true,
  speed: 500
}

export const setIsRsQuestBtn = bool => localStorage.setItem('isRsQuestBtn', bool)
export const getIsRsQuestBtn = () => localStorage.getItem('isRsQuestBtn')
