import React from 'react'
import PropTypes from 'prop-types'

RsQuestBtnModal.propTypes = {
  modalRef: PropTypes.object.isRequired,
  listenCloseModal: PropTypes.func.isRequired,
  noLongerShowRef: PropTypes.object.isRequired,
  listenNoLongerShowbtn: PropTypes.func.isRequired
}

export default function RsQuestBtnModal({
  t,
  modalRef,
  listenCloseModal,
  noLongerShowRef,
  listenNoLongerShowbtn
}) {
  return (
    <>
      <div
        className="modal-manager rs-quest-btn-modal"
        ref={modalRef}
        id="modal-manager"
      >
        <div className="modal-manager-container rs-quest-btn-modal-container">
          <button
            type="button"
            className="modal-manager-close-btn"
            onClick={listenCloseModal}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.01 7.01">
              <path d="M4.84,3.5,6.73,5.39a1,1,0,0,1,0,1.34.94.94,0,0,1-1.33,0h0L3.5,4.84,1.61,6.73A.94.94,0,0,1,.27,5.4h0L2.17,3.5.28,1.61A.94.94,0,0,1,.27.28a1,1,0,0,1,1.34,0h0L3.5,2.17,5.39.28A.94.94,0,0,1,6.73,1.61Z" />
            </svg>
          </button>
          <div className="modal-manager-view rs-quest-btn-modal-view">
            <div className="no-longer-show-box">
              <div className="wrap">
                <input
                  type="checkbox"
                  id="no-longer-show"
                  defaultChecked={true}
                  ref={noLongerShowRef}
                />
                <label htmlFor="no-longer-show" className="checkbox">
                  <i></i>
                </label>
                <label htmlFor="no-longer-show" className="text">
                  {t('no_longer_show')}
                </label>
              </div>
              <button type="button" onClick={listenNoLongerShowbtn}>
                {t('save')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
