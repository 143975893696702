import React from 'react'
import { components } from 'react-select'
import PropTypes from 'prop-types'

Option.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.func.isRequired
  }),
  label: PropTypes.string.isRequired
}

export default function Option(props) {
  return (
    <components.Option {...props}>
      <span className="my__list-value-icon">{props.data.icon()}</span>
      <span className="my__list-value-text">{props.label}</span>
    </components.Option>
  )
}
