import { call, put, takeLatest, select } from 'redux-saga/effects'

import {
  saveLastTicketsSucceeded,
  saveLastTicketsFailed,
  saveLastTicketsRequested
} from '../actions'

import { ticketService } from 'services'

function* saveLastTickets({ payload }) {
  try {
    const auth = yield select(({ auth }) => auth)

    if (auth.loading) return

    const data = yield call(ticketService.saveLastTickets({ payload }))

    yield put(saveLastTicketsSucceeded({ data }))
  } catch (errors) {
    yield put(saveLastTicketsFailed({ errors }))
  }
}

export default function* watcher() {
  yield takeLatest(saveLastTicketsRequested().type, saveLastTickets)
}
