import { useEffect, useState, useRef } from 'react'
import qs from 'qs'

import { useWatchQuery } from 'hooks'

export default function useSelect({ fieldName }) {
  const selectRef = useRef()

  const { q, push } = useWatchQuery()

  const [defaultSelectId, setDefaultSelectId] = useState()

  useEffect(() => {
    selectRef.current.state.value = null

    setDefaultSelectId(q[fieldName])
    // eslint-disable-next-line
  }, [q])

  const listenSelectChange = (selected, { action }) => {
    q[fieldName] = action !== 'clear' ? selected.value : ''

    push({ search: qs.stringify(q) })
  }

  return { selectRef, defaultSelectId, listenSelectChange }
}
