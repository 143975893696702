import React from 'react'

import RsNavView from '../views/RsNav'

import { useRoadSignCatService } from 'hooks/services'

export default function RsNav() {
  const { roadSignCats } = useRoadSignCatService({ fetch: true })

  return <RsNavView roadSignCats={roadSignCats} />
}
