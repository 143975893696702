import {
  CATS_FETCH_REQUESTED,
  CATS_FETCH_SUCCEEDED,
  CATS_FETCH_FAILED
} from './types'

export const catsFetchRequested = content => ({
  type: CATS_FETCH_REQUESTED,
  payload: content
})
export const catsFetchSucceeded = content => ({
  type: CATS_FETCH_SUCCEEDED,
  payload: content
})
export const catsFetchFailed = content => ({
  type: CATS_FETCH_FAILED,
  payload: content
})
