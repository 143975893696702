import React from 'react'
import PropTypes from 'prop-types'

export const D1CatIcon = ({ className = '' } = '') => (
  <svg
    className={`license-i d1-cat-i ${className}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="2 28.4 96.4 41.6"
    enableBackground="new 2 28.4 96.4 41.6"
    xmlSpace="preserve"
  >
    <path
      d="M77.1,64.2c0,3.2,2.6,5.8,5.8,5.8c3.2,0,5.8-2.6,5.8-5.8c0-3.2-2.6-5.8-5.8-5.8C79.7,58.3,77.1,61,77.1,64.2z M79.9,64.2
        c0-1.7,1.3-3,3-3c1.7,0,3,1.3,3,3s-1.3,3-3,3C81.2,67.2,79.9,65.8,79.9,64.2z M22,64.2c0,3.2,2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8
        c0-3.2-2.6-5.8-5.8-5.8C24.6,58.3,22,61,22,64.2z M24.8,64.2c0-1.7,1.3-3,3-3s3,1.3,3,3s-1.3,3-3,3C26.2,67.2,24.8,65.8,24.8,64.2z
         M2,29.9v30.2c0,0.8,0.7,1.5,1.5,1.5l15.9,1.6h1.2c0.5-3.6,3.5-6.4,7.3-6.4c3.7,0,6.8,2.8,7.3,6.4h24.7H63h12.6
        c0.5-3.6,3.5-6.4,7.3-6.4c3.8,0,6.9,2.9,7.3,6.5l3,0c4,0,5.3-3.3,5.3-7.3c0-2.4-1.1-4.5-2.9-5.8L74.6,33.7c0,0-6.6-5.3-11.6-5.3H3.5
        C2.7,28.4,2,29.1,2,29.9z M94,51.5c0.2-0.6,0.5-0.6,1.6,0.2c1.2,0.9,1.6,3,1.7,3.5c0.1,0.5-0.4,0.9-1,0.9c-0.6,0-1.4,0-1.8-0.7
        C94.3,54.8,93.9,52.1,94,51.5z M66.2,44.4v-8.9c0-0.8,0.7-1.5,1.5-1.5h2.5c0,0,2.2-0.2,4.8,2c2.4,2,7.1,5.7,9.7,7.8
        c1.3,1,0.5,2.1-0.3,2.1H67.7C66.9,45.9,66.2,45.3,66.2,44.4L66.2,44.4z M40.6,44.4v-8.9c0-0.8,0.7-1.5,1.5-1.5h17.9
        c0.8,0,1.5,0.7,1.5,1.5v8.9c0,0.8-0.7,1.5-1.5,1.5H42C41.2,45.9,40.6,45.3,40.6,44.4L40.6,44.4z M17.4,35.5c0-0.8,0.7-1.5,1.5-1.5
        h17.9c0.8,0,1.5,0.7,1.5,1.5v8.9c0,0.8-0.7,1.5-1.5,1.5H18.9c-0.8,0-1.5-0.7-1.5-1.5C17.4,44.4,17.4,35.5,17.4,35.5z M4.3,35.5
        c0-0.8,0.7-1.5,1.5-1.5h7.9c0.8,0,1.5,0.7,1.5,1.5v8.9c0,0.8-0.7,1.5-1.5,1.5H5.7c-0.8,0-1.5-0.7-1.5-1.5V35.5z"
    ></path>
  </svg>
)

D1CatIcon.propTypes = {
  className: PropTypes.string
}
