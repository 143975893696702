import {
  SWOOP_FETCH_REQUESTED,
  SWOOP_FETCH_SUCCEEDED,
  SWOOP_FETCH_FAILED
} from './types'

export const swoopFetchRequested = content => ({
  type: SWOOP_FETCH_REQUESTED,
  payload: content
})
export const swoopFetchSucceeded = content => ({
  type: SWOOP_FETCH_SUCCEEDED,
  payload: content
})
export const swoopFetchFailed = content => ({
  type: SWOOP_FETCH_FAILED,
  payload: content
})
