import React from 'react'
import { Link } from 'react-scroll'
import PropTypes from 'prop-types'

import { scrollNavSettings } from 'utils/app'

RegulatorNav.propTypes = {
  regulators: PropTypes.object.isRequired
}

export default function RegulatorNav({ regulators }) {
  return (
    <div className="regulator-nav">
      {regulators.data.map(({ id, shortTitle }) => (
        <Link to={`regulator-${id}`} {...scrollNavSettings} key={id}>
          {shortTitle}
        </Link>
      ))}
    </div>
  )
}
