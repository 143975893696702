import config from 'config'

const {
  drivingApi: { endpoints }
} = config

export const roadSignTransformer = {
  fetchRoadSigns: data => {
    try {
      return data.data.map(roadSign => ({
        id: roadSign.id,
        roadSignCatId: roadSign.road_sign_cat_id,
        photo: endpoints.getRoadSignPhoto.replace(
          ':path',
          `${roadSign.road_sign_cat_id}/${roadSign.photo}`
        ),
        title: roadSign.title,
        desc: roadSign.desc
      }))
    } catch (error) {
      throw new Error(error)
    }
  }
}
