export const textPartTransformer = {
  fetchTextPart: data => {
    try {
      const { help_id, title, descr } = data.data

      return {
        id: help_id,
        title: title,
        desc: descr
      }
    } catch (error) {
      throw new Error(error)
    }
  }
}
