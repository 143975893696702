import { useState } from 'react'

export default function useRsQuestWizard({ level, now = 0, correctness }) {
  const [wizardLevel, setWizardLevel] = useState(now)
  const [wizardCorrectness, setWizardCorrectness] = useState(1)

  const wizardState = level => {
    if (level !== undefined) {
      return level === wizardLevel
    }
    return wizardLevel
  }

  const wizardPrev = () => {
    if (!(wizardLevel - 1)) {
      return undefined
    }
    return () => setWizardLevel(prevState => --prevState)
  }

  const wizardNext = () => {
    if (wizardLevel === level) {
      return undefined
    }
    return () => setWizardLevel(prevState => ++prevState)
  }

  const wizardNow = lvl => () => {
    if (!lvl || lvl > level) {
      return undefined
    }
    return setWizardLevel(lvl)
  }

  const wizardCorrectnessState = level => {
    if (level !== undefined) {
      return level === wizardCorrectness
    }
    return wizardCorrectness
  }

  const wizardCorrectnessNow = correct =>
    setWizardCorrectness(prevState => {
      if (correct && prevState !== correctness) {
        ++prevState
      }
      if (!correct && prevState !== 1) {
        --prevState
      }
      return prevState
    })

  return {
    wizardState,
    wizardPrev,
    wizardNext,
    wizardNow,
    wizardLevel,
    wizardCorrectnessState,
    wizardCorrectnessNow
  }
}
