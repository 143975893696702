import { useRef } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { TimelineMax, Sine } from 'gsap'

import { setDropdown } from 'modules/application/actions'

export const useDropdown = ({ name, animable = false, outsideable = true } = {}) => {
  const containerRef = useRef()

  const displayRef = useRef()

  const dropdown = useSelector(({ dropdown }) => dropdown, shallowEqual)

  const dispatch = useDispatch()

  const isOpen = !!dropdown[name]?.isOpen

  const toggle = value => {
    const open = value || !isOpen

    dispatch(setDropdown({ name, isOpen: open }))

    open ? registerEvents() : unregisterEvents()
  }

  const listenDocument = event => {
    const { current: container } = containerRef
    const { target, keyCode } = event

    if ((outsideable && !container.contains(target)) || keyCode === 27) {
      dispatch(setDropdown({ name, isOpen: false }))
      unregisterEvents()
    }
  }

  const registerEvents = () => {
    if (animable) setTimeout(() => registerAnim(), 0)
    document.addEventListener('keyup', listenDocument)
    document.addEventListener('mousedown', listenDocument)
    document.addEventListener('touchend', listenDocument)
  }

  const unregisterEvents = () => {
    document.removeEventListener('keyup', listenDocument)
    document.removeEventListener('mousedown', listenDocument)
    document.removeEventListener('touchend', listenDocument)
  }

  const registerAnim = () => {
    const { current: display } = displayRef

    const tl = new TimelineMax().to(
      display,
      {
        duration: 0.01,
        alpha: 1,
        y: 0,
        ease: Sine.easeIn,
        onComplete: () => tl.kill()
      },
      0
    )

    return tl
  }

  return {
    containerRef,
    displayRef,
    isOpen,
    toggle
  }
}
