import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { regulatorsFetchRequested } from 'modules/regulator/actions'

export const useRegulatorService = ({ fetch } = {}) => {
  const {
    i18n: { language: locale }
  } = useTranslation()

  const dispatch = useDispatch()

  const regulators = useSelector(({ regulators }) => regulators, shallowEqual)

  useEffect(() => {
    if (!fetch) return

    dispatch(regulatorsFetchRequested({ locale }))
  }, [dispatch, locale, fetch])

  return { regulators }
}
