import React from 'react'

import ExamView from '../views/Exam'
import { ScrollTo } from 'components'

export default function Exam() {
  return (
    <>
      <ExamView />
      <ScrollTo />
    </>
  )
}
