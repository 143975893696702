import moment from 'moment'
import { SHA256 } from 'crypto-js'

import config from 'config'
import { swoopTransformer } from 'transformers'

const {
  drivingApi: { endpoints }
} = config

export const swoopService = {
  fetchSwoop: async () => {
    const url = endpoints.getSwoop

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Api-Auth-Token': SHA256(`${moment().format('DD.MM.Y')}wEqoP3sx9NruH64uWT`)
      }
    })

    const data = await response.json()

    return {
      data: response.ok ? swoopTransformer.fetchSwoop(data) : data,
      ok: response.ok,
      status: response.status
    }
  }
}
