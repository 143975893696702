import React from 'react'
import { components } from 'react-select'

import clearIcon from 'resources/svgs/clear.svg'

export default function ClearIndicator(props) {
  return (
    <components.ClearIndicator {...props}>
      <img src={clearIcon} alt="" className="clear" />
    </components.ClearIndicator>
  )
}
