import React from 'react'
import { components } from 'react-select'

import selectArrow2Icon from 'resources/svgs/drop-sm.svg'

export default function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <img src={selectArrow2Icon} alt="" />
    </components.DropdownIndicator>
  )
}
