import { call, put, take, fork } from 'redux-saga/effects'

import {
  createDsReviewSucceeded,
  createDsReviewFailed,
  createDsReviewRequested
} from '../actions'
import { drivingSchoolService } from 'services'

function* createDsReview({ payload }) {
  try {
    const data = yield call(drivingSchoolService.createDsReview({ payload }))

    yield put(createDsReviewSucceeded({ data }))
  } catch (errors) {
    yield put(createDsReviewFailed({ errors }))
  }
}

export default function* watcher() {
  while (true) {
    const { payload } = yield take(createDsReviewRequested().type)

    yield fork(createDsReview, { payload })
  }
}
