import React from 'react'
import PropTypes from 'prop-types'

import { ExamStartAtCheckbox } from 'modules'

ExamStartBlock.propTypes = {
  t: PropTypes.func.isRequired,
  examStartBlockRef: PropTypes.object.isRequired,
  listenExamStartBtn: PropTypes.func.isRequired
}

export default function ExamStartBlock({
  t,
  examStartBlockRef,
  listenExamStartBtn
}) {
  return (
    <div
      className="exam-start-block"
      style={{ opacity: 0, transform: 'translate(0px, 7px)' }}
      ref={examStartBlockRef}
    >
      <div className="caption">
        <p>{t('keyboard_control')}</p>
        <span>{t('exam_text_1')}</span>
      </div>
      <div className="keys">
        <div className="num">
          <span>1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
        </div>
        <span className="space">
          <b>Space</b> <i> - </i> <em>{t('exam_text_2')}</em>
        </span>
      </div>
      <ExamStartAtCheckbox />
      <button type="button" className="start-exam" onClick={listenExamStartBtn}>
        {t('start_exam')}
      </button>
    </div>
  )
}
