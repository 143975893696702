import React from 'react'
import PropTypes from 'prop-types'

import { TicketBlock, TicketWizardLvlBlock } from 'modules'

TicketList.propTypes = {
  t: PropTypes.func.isRequired,
  ticketWizard: PropTypes.object.isRequired,
  listenAnswer: PropTypes.func.isRequired
}

export default function TicketList({ t, ticketWizard, listenAnswer }) {
  return (
    <div className="tickets">
      <TicketBlock
        ticket={ticketWizard.ticket}
        listenAnswer={listenAnswer}
        isLocked={ticketWizard.isLocked}
      />
      <div className="progress-bar-v1">
        <p style={{ minWidth: `${ticketWizard.progressV1}%` }}>
          {ticketWizard.counter}
          <span>&nbsp;/ {ticketWizard.totalCount}</span>
        </p>
        <i style={{ width: `${ticketWizard.progressV1}%` }}></i>
      </div>
      <div className="status-bar">
        <div className="left-side">
          <p className="true">{ticketWizard.correct}</p>
          <span>{t('correct')}</span>
          <p className="false">{ticketWizard.wrong}</p>
          <span>{t('mistake')}</span>
        </div>
        <div className="right-side">
          <TicketWizardLvlBlock wizardLvl={ticketWizard.wizardLvl} />
        </div>
      </div>
    </div>
  )
}
