import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { citiesFetchRequested } from 'modules/citySelect/actions'

export const useCityService = ({ fetch } = {}) => {
  const {
    i18n: { language: locale }
  } = useTranslation()

  const dispatch = useDispatch()

  const cities = useSelector(({ cities }) => cities, shallowEqual)

  useEffect(() => {
    if (!fetch) return

    dispatch(citiesFetchRequested({ locale }))
  }, [dispatch, locale, fetch])

  return { cities }
}
