import { call, put, take, fork } from 'redux-saga/effects'

import {
  citiesFetchSucceeded,
  citiesFetchFailed,
  citiesFetchRequested
} from '../actions'

import { locationService } from 'services'

function* fetchCities({ payload }) {
  try {
    const data = yield call(locationService.fetchCities({ payload }))

    yield put(citiesFetchSucceeded({ data }))
  } catch (errors) {
    yield put(citiesFetchFailed({ errors }))
  }
}

export default function* watcher() {
  while (true) {
    const { payload } = yield take(citiesFetchRequested().type)

    yield fork(fetchCities, { payload })
  }
}
