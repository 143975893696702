import React from 'react'
import { Element } from 'react-scroll'
import ReactHtmlParser from 'react-html-parser'
import PropTypes from 'prop-types'

import { BreadCrumb } from 'modules'

Tips.propTypes = {
  tips: PropTypes.object.isRequired
}

export default function Tips({ tips }) {
  return (
    <div className="wrapper">
      <BreadCrumb />
      <div className="tips">
        <div className="container">
          {!!tips.data.filter(({ isMain }) => isMain).length && (
            <div className="playstation">
              {tips.data
                .filter(({ isMain }) => isMain)
                .map(({ id, ytEmbed }) => (
                  <div className="play-box" key={id}>
                    <iframe
                      title={id}
                      src={ytEmbed}
                      frameBorder="0"
                      allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                ))}
            </div>
          )}
          <div className="tip-list">
            {tips.data
              .filter(({ isMain }) => !isMain)
              .map(({ id, title, desc, ytEmbed }, index) => (
                <Element name={`tip-${id}`} className="item" key={id}>
                  <div className="head">
                    <h2>
                      <i>{++index}</i>
                      <span>{title}</span>
                    </h2>
                  </div>
                  <div className="overwrap">
                    <div className="left">
                      <div className="editor">{ReactHtmlParser(desc)}</div>
                    </div>
                    <div className="right">
                      <div className="play-box">
                        <iframe
                          title={id}
                          src={ytEmbed}
                          frameBorder="0"
                          allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </Element>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
