import React from 'react'

import Block1 from '../containers/Block1'
import Block2 from '../containers/Block2'
import Block3 from '../containers/Block3'
import Block4 from '../containers/Block4'
import Block5 from '../containers/Block5'

export default function Home() {
  return (
    <div className="wrapper">
      <Block1 />
      <Block2 />
      <Block3 />
      <Block4 />
      <Block5 />
    </div>
  )
}
