import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { catsFetchRequested } from 'modules/category/actions'

export const useCategoryService = ({ fetch } = {}) => {
  const {
    i18n: { language: locale }
  } = useTranslation()

  const dispatch = useDispatch()

  const cats = useSelector(({ cats }) => cats)

  useEffect(() => {
    if (!fetch) return

    dispatch(catsFetchRequested({ locale }))
  }, [dispatch, locale, fetch])

  return { cats }
}
