import {
  DRIVING_SCHOOLS_FETCH_SUCCEEDED,
  DRIVING_SCHOOLS_FETCH_FAILED
} from '../actions/types'
import { createDsReviewSucceeded } from 'modules/dsReviewModal/actions'
import { swoopFetchSucceeded } from 'modules/swoop/actions'

const initialState = {
  data: [],
  error: undefined,
  loading: true
}

export default function drivingSchools(state = initialState, action) {
  const actionTypes = {
    [DRIVING_SCHOOLS_FETCH_SUCCEEDED]: {
      ...state,
      ...action.payload,
      loading: false
    },
    [DRIVING_SCHOOLS_FETCH_FAILED]: {
      ...state,
      ...action.payload,
      loading: false
    },
    [createDsReviewSucceeded().type]: {
      ...state,
      data: state.data.map(drivingSchool => {
        let comments = drivingSchool.comments
        if (drivingSchool.id === action.payload?.data?.drivingSchoolId) {
          comments = [action.payload?.data, ...comments]
        }
        return {
          ...drivingSchool,
          comments
        }
      })
    },
    [swoopFetchSucceeded().type]:{
      ...state,
      data: (()=> {
        const data = [...state.data]
        let j = 0
        for (let i = 0; i < data.length; i++) {
          if(i % 2 !== 0){
            const { data: items } = action.payload?.data
            const item = items?.[j]
            if(!item) break
            data.splice(i, 0, item);
            j++
          }
        }
        return  data
      })()
    },
    default: state
  }

  return Reflect.has(actionTypes, action.type)
    ? actionTypes[action.type]
    : actionTypes.default
}
