import React from 'react'
import PropTypes from 'prop-types'

import lvl1Img from 'resources/imgs/game-lvl/lvl-1.png'
import lvl2Img from 'resources/imgs/game-lvl/lvl-2.png'
import lvl3Img from 'resources/imgs/game-lvl/lvl-3.png'
import lvl4Img from 'resources/imgs/game-lvl/lvl-4.png'
import lvl5Img from 'resources/imgs/game-lvl/lvl-5.png'

TicketWizardLvlBlock.propTypes = {
  wizardLvl: PropTypes.number.isRequired
}

const wizardLvls = [lvl1Img, lvl2Img, lvl3Img, lvl4Img, lvl5Img]

export default function TicketWizardLvlBlock({ wizardLvl }) {
  return (
    <ul className="wizard-lvls">
      {wizardLvls.map((lvl, index) => (
        <li className={wizardLvl === index ? 'current' : undefined} key={lvl}>
          <img src={lvl} alt="" />
        </li>
      ))}
    </ul>
  )
}
