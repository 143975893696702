import React, { useState } from 'react'

import { ScrollTo } from 'components'
import { BreadCrumb, DsRatingSelect } from 'modules'
import SchoolList from './SchoolList'
import SchoolMap from './SchoolMap'

import { useMouseOver } from 'hooks'
import { useDrivingSchoolService, useSwoopService } from 'hooks/services'
import { options } from 'utils/map'

export default function DrivingSchool() {
  const [revert, setRevert] = useState(false)
  const [schoolDetail, setSchoolDetail] = useState({ show: false, id: null })

  const { drivingSchools } = useDrivingSchoolService({ fetch: true })

  useSwoopService()

  const [mapOpts, setMapOpts] = useState(options)

  const { mo, moEvents } = useMouseOver({
    setMapOpts,
    locker: revert || schoolDetail.show
  })

  const listenResizeBtn = () => {
    setRevert(prevState => !prevState)
    setSchoolDetail({ show: false })
  }

  const listenSchoolDetailBtn = id => () => {
    setRevert(false)
    setSchoolDetail(prevState =>
      prevState.id === id ? { show: !prevState.show } : { id, show: true }
    )
  }

  return (
    <>
      <div className="wrapper">
        <div className={`driving-schools ${revert ? 'revert' : 'invert'}`}>
          <div className="rubber-1">
            <i className="figure figure-top"></i>
            <i className="figure figure-bottom"></i>
            <div className="wrapt">
              <div className="fifty">
                <div className="head">
                  <BreadCrumb />
                  <DsRatingSelect />
                </div>
                <SchoolList
                  moEvents={moEvents}
                  listenSchoolDetailBtn={listenSchoolDetailBtn}
                  drivingSchools={drivingSchools}
                />
              </div>
            </div>
          </div>
          <div className="rubber-2">
            <SchoolMap
              mo={mo}
              listenResizeBtn={listenResizeBtn}
              schoolDetail={schoolDetail}
              setSchoolDetail={setSchoolDetail}
              drivingSchools={drivingSchools}
              mapOpts={mapOpts}
            />
          </div>
        </div>
      </div>
      <ScrollTo />
    </>
  )
}
