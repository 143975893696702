import {
  LESS_CATS_FETCH_REQUESTED,
  LESS_CATS_FETCH_SUCCEEDED,
  LESS_CATS_FETCH_FAILED
} from './types'

export const lessCatsFetchRequested = content => ({
  type: LESS_CATS_FETCH_REQUESTED,
  payload: content
})
export const lessCatsFetchSucceeded = content => ({
  type: LESS_CATS_FETCH_SUCCEEDED,
  payload: content
})
export const lessCatsFetchFailed = content => ({
  type: LESS_CATS_FETCH_FAILED,
  payload: content
})
