import { call, put, takeLatest } from 'redux-saga/effects'

import {
  changePasswordSucceeded,
  changePasswordFailed,
  changePasswordRequested
} from '../actions'
import { authService } from 'services'

function* changePassword({ payload }) {
  try {
    const data = yield call(authService.changePassword({ payload }))

    yield put(changePasswordSucceeded({ data }))
  } catch (errors) {
    yield put(changePasswordFailed({ errors }))
  }
}

export default function* watcher() {
  yield takeLatest(changePasswordRequested().type, changePassword)
}
