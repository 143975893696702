import React, { Suspense } from 'react'
import { render } from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import gsap from 'gsap'
import { CSSPlugin, MotionPathPlugin, Draggable } from 'gsap/all'
import InertiaPlugin from './InertiaPlugin'

import App from 'App'
import { Loader } from 'components'

import { store } from '@redux'

import 'i18n'

import * as serviceWorker from 'serviceWorker'
import { ErrorBoundaryProvider } from 'providers'

gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(MotionPathPlugin)
gsap.registerPlugin(Draggable)
gsap.registerPlugin(InertiaPlugin)

render(
  <ReduxProvider store={store}>
    <Suspense fallback={<Loader />}>
      <ErrorBoundaryProvider>
        <App />
      </ErrorBoundaryProvider>
    </Suspense>
  </ReduxProvider>,
  document.getElementById('root')
)

serviceWorker.register()
