import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import chainedBackend from 'i18next-chained-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import xhrBackend from 'i18next-xhr-backend'

import config from 'config'

const {
  drivingApi: {
    endpoints: { getLocales }
  }
} = config

const locales = {
  ka: 'ქართული',
  en: 'English',
  ru: 'Русский'
}
const localesArray = Object.keys(locales)

Object.assign(i18n, {
  locales,
  defaultLocale: localesArray[0],
  pattern: `/:locale(${localesArray.join('|')})?`
})

export default i18n
  .use(chainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true
    },
    fallbackLng: localesArray[0],
    whitelist: localesArray,
    load: 'languageOnly',
    backend: {
      backends: [LocalStorageBackend, xhrBackend],
      backendOptions: [
        { prefix: 'd-32758-', expirationTime: 5 * 24 * 60 * 60 * 1000 }, // 5 day
        {
          loadPath: getLocales,
          parse: data => data,
          ajax: async function(url, options, callback) {
            const response = await fetch(url)
            const { data } = await response.json()
            callback(data, { status: 200 })
          }
        }
      ]
    }
  })
