import React from 'react'
import PropTypes from 'prop-types'

export const DCatIcon = ({ className = '' } = '') => (
  <svg
    className={`license-i d-cat-i ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 16"
  >
    <path d="M5.41 11.51c-1.264 0-2.29 1.005-2.29 2.245C3.12 14.995 4.147 16 5.41 16c1.265 0 2.29-1.005 2.29-2.245 0-1.24-1.025-2.245-2.29-2.245zm21.15 0c-1.264 0-2.29 1.005-2.29 2.245 0 1.24 1.026 2.245 2.29 2.245 1.265 0 2.29-1.005 2.29-2.245 0-1.24-1.025-2.245-2.29-2.245zm0 1.102a1.143 1.143 0 1 1 0 2.286 1.143 1.143 0 0 1 0-2.286zm-21.15 0a1.143 1.143 0 1 1 0 2.286 1.143 1.143 0 0 1 0-2.286zM1.92 0C.86 0 0 .843 0 1.882v11.632c0 .693.573 1.254 1.28 1.254h1.125a3.053 3.053 0 0 1-.175-1.013c0-1.722 1.424-3.118 3.18-3.118 1.757 0 3.18 1.396 3.18 3.118 0 .355-.063.696-.174 1.013h15.14a3.049 3.049 0 0 1-.176-1.013c0-1.722 1.424-3.118 3.18-3.118 1.757 0 3.181 1.396 3.181 3.118 0 .355-.064.696-.175 1.013h1.052c.675 0 1.233-.511 1.277-1.171.278-4.182.023-8.076-1.015-11.516C30.507.847 29.349 0 28.036 0zm22.4 1.313h3.716c.715 0 1.357.469 1.56 1.14.525 1.742.85 3.65.987 5.752h-4.387L24.32 6.808V1.313zm-7.36 0h6.08v5.25h-6.08v-5.25zm-8 0h6.72v5.25H8.96v-5.25zm-7.108 0H7.68v5.25H1.34V1.816c0-.277.23-.502.512-.502z" />
  </svg>
)

DCatIcon.propTypes = {
  className: PropTypes.string
}
