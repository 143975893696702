import React from 'react'
import PropTypes from 'prop-types'

import { ProfileBox } from 'modules'

import config from 'config'

const {
  drivingApi: { endpoints }
} = config

AuthBlock.propTypes = {
  t: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired
}

export default function AuthBlock({ t, locale, auth }) {
  return (
    <div className="auth-block">
      {!auth.user ? (
        <a
          href={endpoints.authSignInUri.replace(/:locale/g, locale)}
          className="sign-in"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path d="M8 7.579c-2.062 0-3.734-1.697-3.734-3.79C4.266 1.697 5.938 0 8 0c2.062 0 3.734 1.697 3.734 3.79 0 2.092-1.672 3.789-3.734 3.789zM.013 14.758C.615 10.989 3.955 8.842 8 8.842s7.385 2.147 7.986 5.916A1.07 1.07 0 0 1 14.928 16H1.071a1.08 1.08 0 0 1-1.058-1.242z" />
          </svg>
          <span>{t('sign_in')}</span>
        </a>
      ) : (
        <ProfileBox auth={auth} />
      )}
    </div>
  )
}
