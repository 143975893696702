import {
  REGULATORS_FETCH_REQUESTED,
  REGULATORS_FETCH_SUCCEEDED,
  REGULATORS_FETCH_FAILED
} from './types'

export const regulatorsFetchRequested = content => ({
  type: REGULATORS_FETCH_REQUESTED,
  payload: content
})
export const regulatorsFetchSucceeded = content => ({
  type: REGULATORS_FETCH_SUCCEEDED,
  payload: content
})
export const regulatorsFetchFailed = content => ({
  type: REGULATORS_FETCH_FAILED,
  payload: content
})
