import React from 'react'
import PropTypes from 'prop-types'

import ticketSuccessImg from 'resources/imgs/ticket-success.png'
import ticketFailedImg from 'resources/imgs/ticket-failed.png'

import { VICTORY_STATUS, LOSING_STATUS, COMPLETE_STATUS } from 'consts'
import { convertTime } from 'tools'

const data = {
  [VICTORY_STATUS]: {
    poster: ticketSuccessImg,
    className: 'ticket-modal-winner',
    title: 'victory_title',
    subTitle: 'victory_sub_title'
  },
  [LOSING_STATUS]: {
    poster: ticketFailedImg,
    className: 'ticket-modal-failed',
    title: 'exam_is_over',
    subTitle: 'try_again'
  },
  [COMPLETE_STATUS]: {
    poster: ticketSuccessImg,
    className: 'ticket-modal-complete',
    title: 'is_over',
    subTitle: 'congratulations'
  }
}

TicketFinishModal.propTypes = {
  t: PropTypes.func.isRequired,
  modalRef: PropTypes.object.isRequired,
  listenCloseModal: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  correct: PropTypes.number.isRequired,
  wrong: PropTypes.number.isRequired,
  examTimer: PropTypes.object.isRequired,
  listenNavBtn: PropTypes.func.isRequired
}

export default function TicketFinishModal({
  t,
  modalRef,
  listenCloseModal,
  status,
  correct,
  wrong,
  examTimer,
  listenNavBtn
}) {
  const caption = Reflect.has(data, status) ? data[status] : {}

  return (
    <div className="modal-manager" ref={modalRef} id="modal-manager">
      <div className="modal-manager-container">
        <div
          className={`modal-manager-view ticket-modal-view  ${caption.className}`}
        >
          <button
            type="button"
            className="modal-manager-close-btn"
            onClick={listenCloseModal}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.01 7.01">
              <path d="M4.84,3.5,6.73,5.39a1,1,0,0,1,0,1.34.94.94,0,0,1-1.33,0h0L3.5,4.84,1.61,6.73A.94.94,0,0,1,.27,5.4h0L2.17,3.5.28,1.61A.94.94,0,0,1,.27.28a1,1,0,0,1,1.34,0h0L3.5,2.17,5.39.28A.94.94,0,0,1,6.73,1.61Z" />
            </svg>
          </button>
          <div className="ticket-modal-overview">
            <div className="img-wrap">
              <img src={caption.poster} alt="" />
            </div>
            <p>{t(caption.title)}</p>
            <span>{t(caption.subTitle)}</span>
          </div>
          <div className="ticket-modal-indicator">
            <div className="indicator-wrap">
              <div className="i-wrap i-true">
                <i>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14">
                    <path d="M7.578 9.353l9.043-8.79a2.02 2.02 0 0 1 2.8 0c.772.752.772 1.971 0 2.723L8.977 13.436a2.02 2.02 0 0 1-2.8 0L.58 7.992a1.887 1.887 0 0 1 0-2.722 2.02 2.02 0 0 1 2.8 0l4.198 4.083z" />
                  </svg>
                </i>
                <div className="overflow">
                  <p>{correct}</p>
                  <span>{t('correct')}</span>
                </div>
              </div>
              <div className="i-wrap i-false">
                <i>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                    <path d="M9.586 7l3.878 3.879a1.828 1.828 0 1 1-2.585 2.585L7 9.586l-3.879 3.878A1.828 1.828 0 1 1 .536 10.88L4.414 7 .536 3.121A1.828 1.828 0 1 1 3.12.536L7 4.414 10.879.536a1.828 1.828 0 1 1 2.585 2.585L9.586 7z" />
                  </svg>
                </i>
                <div className="overflow">
                  <p>{wrong}</p>
                  <span>{t('mistake')}</span>
                </div>
              </div>
              {status !== COMPLETE_STATUS && (
                <div className="i-wrap i-clock">
                  <i></i>
                  <div className="overflow">
                    <p>{convertTime(examTimer.past).type1}</p>
                    <span>{t('past_time')}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="ticket-modal-actions">
            <button type="button" onClick={listenNavBtn}>
              {t('profile')}
            </button>
            <button type="button" onClick={listenCloseModal}>
              {t('restart')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
