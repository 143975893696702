import { roadSignCatTransformer } from 'transformers'
import config from 'config'
import { cFetch } from 'tools'

const {
  drivingApi: { endpoints }
} = config

export const roadSignCatService = {
  fetchRoadSignCats: ({ payload }) => async () => {
    const url = endpoints.fetchRoadSignCats.replace(':locale', payload.locale)

    const response = await cFetch(url)

    const data = await response.json()

    return roadSignCatTransformer.fetchRoadSignCats(data)
  }
}
