import { TOGGLE_MODAL } from '../actions/types'

const initialState = {
  index: 0,
  name: '',
  data: {},
  show: false
}

export default function modalManager(state = initialState, action) {
  const { payload: { name } = '' } = action

  const actionTypes = {
    [TOGGLE_MODAL]: {
      ...state,
      data: {},
      ...action.payload,
      show: !!name
    },
    default: state
  }

  return Reflect.has(actionTypes, action.type)
    ? actionTypes[action.type]
    : actionTypes.default
}
