import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

import { Loader } from 'components'

import { authFetchRequested } from './actions'
import { setToken } from 'utils/app'

export default function AuthUser() {
  const {
    i18n: { language: locale }
  } = useTranslation()

  const { search } = useLocation()

  const history = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    const { AccessToken: accessToken } = qs.parse(search, {
      ignoreQueryPrefix: true
    })

    if (accessToken && accessToken !== 'SESSEND') {
      setToken(accessToken)
      dispatch(authFetchRequested({ locale }))
    }

    history.push('/')
  }, [dispatch, locale, history, search])

  return <Loader />
}
