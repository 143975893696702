import { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { TimelineLite, Sine } from 'gsap'

import { toggleModal } from 'modules/modalManager/actions'

export default function useModalManager({ closeModalCallback } = {}) {
  const modalRef = useRef()

  const dispatch = useDispatch()

  useEffect(() => {
    const { body } = document
    const { current: element } = modalRef

    const tl = startAnim(body, element)

    document.addEventListener('keyup', listenDocument)

    return () => {
      tl.kill()

      clearAnim(body, element)
      document.removeEventListener('keyup', listenDocument)
    }
    // eslint-disable-next-line
  }, [])

  const startAnim = (body, element) => {
    const tl = new TimelineLite()

    tl.set(body, { overflow: 'hidden' }, 0).to(
      element,
      0.2,
      { alpha: 1, ease: Sine.easeOut },
      0
    )

    return tl
  }

  const clearAnim = (body, element) => {
    const tl = new TimelineLite()

    tl.set(body, { overflow: 'visible' }, 0).set(element, { alpha: 0 }, 0)
  }

  const listenCloseModal = () => {
    dispatch(toggleModal())

    if (typeof closeModalCallback === 'function') {
      closeModalCallback()
    }
  }

  const listenDocument = e => {
    if (e.which !== 27) return
    listenCloseModal()
  }

  return {
    modalRef,
    listenCloseModal
  }
}
