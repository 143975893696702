import React, { useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import ProfilePasswordModalView from '../views/ProfilePasswordModal'

import { PROFILE_EDIT_MODAL } from 'consts'
import { useModalManager } from 'hooks'
import { toggleModal } from 'modules/modalManager/actions'
import { changePasswordRequested, changePasswordReset } from '../actions'

ProfilePasswordModal.propTypes = {
  data: PropTypes.object
}

export default function ProfilePasswordModal({ data }) {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()

  const dispatch = useDispatch()

  const { modalRef, listenCloseModal } = useModalManager()

  const changePassword = useSelector(({ changePassword }) => changePassword)

  const { control, handleSubmit, errors, watch, setError } = useForm()

  const newPasswordRef = useRef({})

  newPasswordRef.current = watch('newPassword', '')

  useEffect(() => {
    if (!changePassword.processed) return
    if (changePassword.data.status !== 200)
      return setError('currentPassword', 'notMatch', t('password_is_incorrect'))

    dispatch(toggleModal())

    return () => dispatch(changePasswordReset())
    // eslint-disable-next-line
  }, [dispatch, changePassword.processed])

  const listenPasswordChangeSubmit = data =>
    dispatch(
      changePasswordRequested({
        locale,
        data
      })
    )

  const listenProfileEditModal = () => {
    dispatch(toggleModal({ name: PROFILE_EDIT_MODAL, data: { anchor: true } }))
  }

  return (
    <ProfilePasswordModalView
      t={t}
      modalRef={modalRef}
      listenCloseModal={listenCloseModal}
      listenPasswordChangeSubmit={listenPasswordChangeSubmit}
      listenProfileEditModal={listenProfileEditModal}
      control={control}
      handleSubmit={handleSubmit}
      errors={errors}
      data={data}
      newPasswordRef={newPasswordRef}
      changePassword={changePassword}
    />
  )
}
