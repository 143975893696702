import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import DsReviewModalView from '../views/DsReviewModal'

import { createDsReviewRequested } from '../actions'
import { useModalManager } from 'hooks'
import { toggleModal } from 'modules/modalManager/actions'

DsReviewModal.propTypes = {
  data: PropTypes.object.isRequired
}

export default function DsReviewModal({
  data: { drivingSchoolId, starCount, removeStars, lockStars }
}) {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()

  const [reviewText, setReviewText] = useState('')

  const dispatch = useDispatch()

  const closeModalCallback = () => removeStars()

  const { modalRef, listenCloseModal } = useModalManager({ closeModalCallback })

  const { auth, dsReview } = useSelector(({ auth, dsReview }) => ({
    auth,
    dsReview
  }))

  const listenReviewTextChange = ({ target: { value } }) => setReviewText(value)

  const listenSubmit = e => {
    e.preventDefault()

    dispatch(
      createDsReviewRequested({
        locale,
        data: { drivingSchoolId, reviewText, starCount }
      })
    )

    lockStars()

    dispatch(toggleModal())
  }

  return (
    <DsReviewModalView
      t={t}
      modalRef={modalRef}
      listenCloseModal={listenCloseModal}
      listenReviewTextChange={listenReviewTextChange}
      disabledSubmitBtn={!reviewText || dsReview.loading}
      auth={auth}
      starCount={starCount}
      listenSubmit={listenSubmit}
    />
  )
}
