import Tips from './containers/Tips'

export default {
  exact: true,
  isNav: true,
  name: 'tips',
  purePath: '/tips',
  path: '/tips',
  component: Tips
}
