import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { lessCatsFetchRequested } from 'modules/categorySelect/actions'

export const useLessCatService = ({ fetch } = {}) => {
  const {
    i18n: { language: locale }
  } = useTranslation()

  const dispatch = useDispatch()

  const lessCats = useSelector(({ lessCats }) => lessCats)

  useEffect(() => {
    if (!fetch) return

    dispatch(lessCatsFetchRequested({ locale }))
  }, [dispatch, locale, fetch])

  return { lessCats }
}
