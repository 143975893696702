import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import AuthBlockView from '../views/AuthBlock'

export default function AuthBlock() {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()

  const auth = useSelector(({ auth }) => auth)

  return <AuthBlockView t={t} locale={locale} auth={auth} />
}
