import React from 'react'
import PropTypes from 'prop-types'

LastMistake.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

export default function LastMistake({ t, auth }) {
  let placeholder = auth.user.latestTicketMistakes.map((mistake, index) => (
    <div className="item" key={index}>
      <span>{mistake.value}</span>
      <p>{mistake.title}</p>
    </div>
  ))

  for (let i = placeholder.length; i < 6; i++) {
    placeholder.push(
      <div className="item" key={i}>
        <span></span>
        <p>
          <i></i>
          <i></i>
        </p>
      </div>
    )
  }

  return (
    <div className="last-wrongs">
      <p className="title">{t('last_mistakes')}</p>
      <div className="wrong-list">{placeholder}</div>
      {!placeholder.length && (
        <div className="no-result">
          <p>{t('no_activity')}</p>
        </div>
      )}
    </div>
  )
}
