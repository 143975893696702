import React from 'react'

import TicketView from '../views/Ticket'
import { ScrollTo } from 'components'

export default function Ticket() {
  return (
    <>
      <TicketView />
      <ScrollTo />
    </>
  )
}
