import { useEffect, useRef } from 'react'
import { TimelineMax, Sine } from 'gsap'
import { useDispatch } from 'react-redux'

import { RS_FULLSCREEN_MODAL } from 'consts'
import { toggleRsModal } from 'modules/rsModal/actions'
import { toggleModal } from 'modules/modalManager/actions'

export default function useRsModal({ roadSigns, index }) {
  const modalRef = useRef()
  const modalContainerRef = useRef()

  const dispatch = useDispatch()

  useEffect(() => {
    const body = document.body
    const container = document.getElementById('container')
    const { current: element } = modalRef

    const tl = startAnim(body, container, element)

    document.addEventListener('keyup', listenDocument)
    document.addEventListener('mousedown', listenDocument)
    document.addEventListener('touchend', listenDocument)

    return () => {
      tl.kill()
      clearAnim(body, container, element)

      document.removeEventListener('keyup', listenDocument)
      document.removeEventListener('mousedown', listenDocument)
      document.removeEventListener('touchend', listenDocument)
    }
    // eslint-disable-next-line
  }, [])

  const startAnim = (body, container, element) => {
    const tl = new TimelineMax()

    tl.set(body, { overflow: 'hidden' }, 0)
      .to(container, 1, { filter: 'blur(3px)', ease: Sine.easeOut }, 0.2)
      .to(element, 0.2, { alpha: 1, ease: Sine.easeOut }, 0)

    return tl
  }

  const clearAnim = (body, container, element) => {
    const tl = new TimelineMax()

    tl.set(body, { overflow: 'visible' }, 0)
      .set(container, { filter: 'blur(0px)' }, 0)
      .set(element, { alpha: 0 }, 0)
  }

  const listenDocument = e => {
    const { current: modalContainer } = modalContainerRef
    if (
      (e.type === 'keyup' && e.which === 27) ||
      (e.type !== 'keyup' && !modalContainer.contains(e.target))
    ) {
      dispatch(toggleRsModal())
    }
  }
  const listenCloseModal = () => {
    dispatch(toggleRsModal())
  }

  const listenFullscreenBtn = () => {
    dispatch(toggleRsModal())
    dispatch(toggleModal({ name: RS_FULLSCREEN_MODAL, data: { roadSigns, index } }))
  }

  return {
    modalRef,
    modalContainerRef,
    listenFullscreenBtn,
    listenCloseModal
  }
}
