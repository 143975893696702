import { call, put, take, fork } from 'redux-saga/effects'

import {
  updateLvlNotifySucceeded,
  updateLvlNotifyFailed,
  updateLvlNotifyRequested
} from '../actions'

import { authService } from 'services'

function* updateLvlNotify({ payload }) {
  try {
    const data = yield call(authService.updateLvlNotify({ payload }))

    yield put(updateLvlNotifySucceeded({ data }))
  } catch (errors) {
    yield put(updateLvlNotifyFailed({ errors }))
  }
}

export default function* watcher() {
  while (true) {
    const { payload } = yield take(updateLvlNotifyRequested().type)

    yield fork(updateLvlNotify, { payload })
  }
}
