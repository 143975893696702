import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import FooterView from '../views/Footer'

import config from 'config'
import { PROFILE_EDIT_MODAL, PROFILE_PASSWORD_MODAL } from 'consts'
import { useMatchConfig } from 'hooks'
import { useAuthService, useCategoryService } from 'hooks/services'
import { toggleModal } from 'modules/modalManager/actions'

const {
  drivingApi: { endpoints }
} = config

export default function Footer() {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()

  const dispatch = useDispatch()

  const { isFooter } = useMatchConfig()

  const { auth } = useAuthService()

  const { cats } = useCategoryService()

  const hisotry = useHistory()

  const redirectToAuth = () => {
    window.location.href = endpoints.authSignInUri.replace(/:locale/g, locale)
  }

  const listenNavBtn = action => () => {
    if (!auth.user) return redirectToAuth()

    switch (action) {
      case PROFILE_EDIT_MODAL:
        dispatch(toggleModal({ name: action }))
        break
      case PROFILE_PASSWORD_MODAL:
        dispatch(toggleModal({ name: action }))
        break
      default:
        hisotry.push('/profile')
        break
    }
  }

  return (
    <>{isFooter && <FooterView t={t} cats={cats} listenNavBtn={listenNavBtn} />}</>
  )
}
