import React from 'react'
import { useTranslation } from 'react-i18next'

import RsQuestBtnModalView from '../views/RsQuestBtnModal'

import { useRsQuestBtnModal, useModalManager } from 'hooks'

export default function RsQuestBtnModal() {
  const { t } = useTranslation()

  const { modalRef, listenCloseModal } = useModalManager()
  const { noLongerShowRef, listenNoLongerShowbtn } = useRsQuestBtnModal({
    listenCloseModal
  })

  return (
    <RsQuestBtnModalView
      t={t}
      modalRef={modalRef}
      listenCloseModal={listenCloseModal}
      noLongerShowRef={noLongerShowRef}
      listenNoLongerShowbtn={listenNoLongerShowbtn}
    />
  )
}
