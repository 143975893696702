import {
  ROAD_SIGNS_FETCH_REQUESTED,
  ROAD_SIGNS_FETCH_SUCCEEDED,
  ROAD_SIGNS_FETCH_FAILED
} from './types'

export const roadSignsFetchRequested = content => ({
  type: ROAD_SIGNS_FETCH_REQUESTED,
  payload: content
})
export const roadSignsFetchSucceeded = content => ({
  type: ROAD_SIGNS_FETCH_SUCCEEDED,
  payload: content
})
export const roadSignsFetchFailed = content => ({
  type: ROAD_SIGNS_FETCH_FAILED,
  payload: content
})
