import React from 'react'

import { BreadCrumb, TicketList } from 'modules'

export default function Ticket() {
  return (
    <div className="wrapper h-100">
      <BreadCrumb />
      <TicketList />
    </div>
  )
}
