import {
  PROFILE_EDIT_REQUESTED,
  PROFILE_EDIT_SUCCEEDED,
  PROFILE_EDIT_FAILED,
  PROFILE_EDIT_RESET
} from '../actions/types'

const initialState = {
  data: undefined,
  errors: undefined,
  loading: false,
  processed: false
}

export default function profileEdit(state = initialState, action) {
  const actionTypes = {
    [PROFILE_EDIT_REQUESTED]: {
      ...state,
      ...action.payload,
      loading: true,
      processed: false
    },
    [PROFILE_EDIT_SUCCEEDED]: {
      ...state,
      ...action.payload,
      loading: false,
      processed: true
    },
    [PROFILE_EDIT_FAILED]: {
      ...state,
      ...action.payload,
      loading: false,
      processed: true
    },
    [PROFILE_EDIT_RESET]: {
      ...state,
      processed: false
    },
    default: state
  }

  return Reflect.has(actionTypes, action.type)
    ? actionTypes[action.type]
    : actionTypes.default
}
