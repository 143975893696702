import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import { Draggable } from 'gsap/all'

Block3.propTypes = {
  t: PropTypes.func.isRequired,
  cats: PropTypes.object.isRequired,
  textPart: PropTypes.object.isRequired
}

export default function Block3({ t, cats, textPart }) {
  const containerRef = useRef()

  useEffect(() => {
    if (cats.loading) return

    const { current: container } = containerRef

    Draggable.create(container, {
      type: 'scrollLeft',
      edgeResistance: 0.7,
      inertia: true
    })
  }, [cats])

  return (
    <section className="home-section home-categories-section">
      <div className="categories-section-info">
        <div className="categories-section-title">
          <h2>{textPart.data.categories.title}</h2>
        </div>
        <div className="categories-section-text">
          <p>{textPart.data.categories.desc}</p>
        </div>
      </div>
      <div className="categories-wrapper">
        <div className="left-side">
          <svg
            className="icon-1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 6"
          >
            <path d="M3 0a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm12 0a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm12 0a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" />
          </svg>
          <svg
            className="icon-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 6"
          >
            <path d="M1 0h10a1 1 0 1 1 0 2H1a1 1 0 1 1 0-2zm0 4h5a1 1 0 1 1 0 2H1a1 1 0 1 1 0-2z" />
          </svg>
          <span>&nbsp;</span>
        </div>
        <div className="overflow" ref={containerRef}>
          <div className="categories-swipe">
            {cats.data
              .filter(category => category.isParent)
              .map(category => (
                <div className="swipe-block" key={category.id}>
                  <div className="head-block">
                    <p className="caption">{category.title.charAt(0)}</p>
                    <p className="description">
                      {t('category_license_includes')} <br />
                      <span>{category.desc}</span>
                    </p>
                  </div>
                  <p className="options">
                    {category.options &&
                      ReactHtmlParser(category.options?.[0]?.value)}
                  </p>
                  <div className="image-block">
                    {category.photos && <img src={category.photos?.[0]} alt="" />}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  )
}
