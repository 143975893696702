import config from 'config'

const {
  drivingApi: { endpoints }
} = config

export const roadSignCatTransformer = {
  fetchRoadSignCats: data => {
    try {
      return data.data.map(({ id, title }) => ({
        id,
        icon: endpoints.getRoadSignCatIcon.replace(':id', id),
        title
      }))
    } catch (error) {
      throw new Error(error)
    }
  }
}
