import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

import { drivingSchoolsFetchRequested } from 'modules/drivingSchool/actions'

export const useDrivingSchoolService = ({ fetch = true } = {}) => {
  const {
    i18n: { language: locale }
  } = useTranslation()

  const { search } = useLocation()

  const dispatch = useDispatch()

  const drivingSchools = useSelector(({ drivingSchools }) => drivingSchools)

  useEffect(() => {
    if (!fetch) return

    const q = qs.parse(search, { ignoreQueryPrefix: true })

    dispatch(drivingSchoolsFetchRequested({ ...q, locale }))
  }, [dispatch, locale, fetch, search])

  return { drivingSchools }
}
