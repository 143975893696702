import config from 'config'
import { cFetch } from 'tools'
import { textPartTransformer } from 'transformers'

const {
  drivingApi: { endpoints }
} = config

export const textPartService = {
  fetchTextPart: ({ payload }) => async () => {
    try {
      const url = endpoints.fetchTextPart
        .replace(':locale', payload.locale)
        .replace(':id', payload.id)

      const response = await cFetch(url)

      const data = await response.json()

      return textPartTransformer.fetchTextPart(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
