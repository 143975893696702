import React from 'react'
import { Element } from 'react-scroll'
import Slider from 'react-slick'
import ReactHtmlParser from 'react-html-parser'
import PropTypes from 'prop-types'

import { BreadCrumb } from 'modules'

import { carouselSettings } from 'utils/app'

Category.propTypes = {
  t: PropTypes.func.isRequired,
  catsByType: PropTypes.array.isRequired
}

export default function Category({ t, catsByType }) {
  return (
    <div className="wrapper">
      <BreadCrumb />
      <div className="categories-v2">
        <div className="category-list">
          {catsByType.map((cats, index) => (
            <Element name={`category-${cats[0].type}`} key={index}>
              {cats.map(({ id, title, desc, tempDesc, ticketCount, photos }) => (
                <div className="item" key={id}>
                  <div className="overflow">
                    <div className="block block-top">
                      <div className="wrapt">
                        <div className="shift">
                          <div className="head">
                            <i>{title}</i>
                            <p>
                              <span>{t('category_license_includes')}</span>{' '}
                              <span>{desc}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="empty">&nbsp;</div>
                  </div>
                  <div className="overflow">
                    <div className="block">
                      <div className="wrapt">
                        <div className="shift">
                          <div className="info">
                            <p>{ReactHtmlParser(tempDesc)}</p>
                          </div>
                          <div className="ticket-count">
                            <i>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="20"
                                viewBox="0 0 24 20"
                              >
                                <path
                                  fill="#FD4100"
                                  fillRule="nonzero"
                                  d="M14.217 3.208h8.378a1.405 1.405 0 0 1 0 2.81h-8.378a1.405 1.405 0 1 1 0-2.81zM3.903 4.453L7.773 0l2.152 1.831-5.668 6.523L.17 5.081l1.788-2.187 1.945 1.56zm10.078 9.792h8.378a1.405 1.405 0 1 1 0 2.81H13.98a1.405 1.405 0 0 1 0-2.81zm-7.202-2.94l2.006 1.985L6.4 15.654l2.385 2.36L6.779 20l-2.39-2.36L2.007 20 0 18.015l2.384-2.36L0 13.29l2.006-1.986 2.384 2.36 2.389-2.36z"
                                />
                              </svg>
                            </i>
                            <span>
                              {t('ticket_counter').replace('%s', ticketCount)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slider-block">
                      <Slider {...carouselSettings} className="slider">
                        {photos.map((photo, index) => (
                          <div className="slide" key={index}>
                            <img src={photo} alt="" />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              ))}
            </Element>
          ))}
        </div>
      </div>
    </div>
  )
}
