import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'components'

import animCar1 from 'resources/imgs/anim-cars/car-1.png'
import animCar2 from 'resources/imgs/anim-cars/car-2.png'
import animCar3 from 'resources/imgs/anim-cars/car-3.png'
import animCar4 from 'resources/imgs/anim-cars/car-4.png'
import animCar5 from 'resources/imgs/anim-cars/car-5.png'
import animCar6 from 'resources/imgs/anim-cars/car-6.png'

import trafficLightImg from 'resources/svgs/traffic-light.svg'
import stopImg from 'resources/svgs/stop.svg'
import crosswalkImg from 'resources/svgs/crosswalk.svg'

Block1.propTypes = {
  t: PropTypes.func.isRequired,
  animPathsRef: PropTypes.object.isRequired,
  animObjectsRef: PropTypes.object.isRequired
}

export default function Block1({ t, animPathsRef, animObjectsRef, trackStartDrivingTest }) {
  return (
    <section className="driving-animation-section">
      <div className="animation-bg">
        <div className="animation-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1920 1040"
          >
            <path
              data-key="path1"
              className="c-1"
              d="M2020,310H562.8c-51.4-0.4-102.5,13.8-153.2,42.7C358.9,381.5,162.2,552.3-95.5,788"
              ref={el => el && (animPathsRef[el.dataset.key] = el)}
            />
            <path
              data-key="path2"
              className="c-1"
              transform="translate(0, -135.5)"
              d="M487,1q47.21,583.37,82.36,755.59c35.14,172.24,220.16,276.81,422.85,276.81H2027.4"
              ref={el => el && (animPathsRef[el.dataset.key] = el)}
            />
            <path
              data-key="path3"
              className="c-1"
              d="M-192.390554,897.05532 L959.609375,898 C1162.29788,898 1326.60938,733.688503 1326.60938,531 C1326.60938,395.874331 1326.57292,156.793911 1326.5,-186.241261"
              ref={el => el && (animPathsRef[el.dataset.key] = el)}
            />
            <image
              style={{ opacity: 0 }}
              data-key="obj1"
              className="an-img-1"
              xlinkHref={animCar1}
              ref={el => el && (animObjectsRef[el.dataset.key] = el)}
            />
            <image
              style={{ opacity: 0 }}
              data-key="obj4"
              className="an-img-4"
              xlinkHref={animCar4}
              ref={el => el && (animObjectsRef[el.dataset.key] = el)}
            />
            <polygon
              className="b-1"
              points="1261.34 333.19 655.62 333.19 676.75 292.9 1244.17 292.9 1261.34 333.19"
            />
            <path
              data-key="path4"
              className="c-3"
              d="M-210.705244,898 L960,898 C1162.6885,898 1327,733.688503 1327,531 C1327,328.311497 1162.6885,164 960,164 C757.311497,164 593,328.311497 593,531 L593,1225"
              ref={el => el && (animPathsRef[el.dataset.key] = el)}
            />
            <image
              style={{ opacity: 0 }}
              data-key="obj2"
              className="an-img-2"
              xlinkHref={animCar2}
              ref={el => el && (animObjectsRef[el.dataset.key] = el)}
            />
            <image
              style={{ opacity: 0 }}
              data-key="obj3"
              className="an-img-3"
              xlinkHref={animCar3}
              ref={el => el && (animObjectsRef[el.dataset.key] = el)}
            />
            <image
              style={{ opacity: 0 }}
              data-key="obj5"
              className="an-img-5"
              xlinkHref={animCar5}
              ref={el => el && (animObjectsRef[el.dataset.key] = el)}
            />
            <image
              style={{ opacity: 0 }}
              data-key="obj6"
              className="an-img-6"
              xlinkHref={animCar6}
              ref={el => el && (animObjectsRef[el.dataset.key] = el)}
            />
            <image
              className="an-traffic-light"
              transform="translate(1637, 850)"
              xlinkHref={trafficLightImg}
            />
            <image
              className="an-stop"
              transform="translate(1270, 355)"
              xlinkHref={stopImg}
            />
            <image
              className="an-crosswalk"
              transform="translate(322, 405)"
              xlinkHref={crosswalkImg}
            />
          </svg>
        </div>

        <div className="animation-info-box">
          <h1>
            {t('home1_str1')}
            <span>{t('home1_str2')}</span>
          </h1>
          <h3>{t('home_text1')}</h3>
          <Link className="driving-btn" to="/exam" onClick={trackStartDrivingTest}>
            {t('start_exam')}
          </Link>
        </div>
      </div>
    </section>
  )
}
