import React, { useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { TimelineMax, Sine } from 'gsap'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

import ExamStartBlockView from '../views/ExamStartBlock'

import { EXAM_STARTED_ROUTE } from 'consts'

export default function ExamStartBlock() {
  const { t } = useTranslation()

  const history = useHistory()
  const location = useLocation()

  const examStartBlockRef = useRef()

  useEffect(() => {
    const { current: examStartBlock } = examStartBlockRef

    const tl = new TimelineMax()

    tl.to(examStartBlock, 0.5, { alpha: 1, y: 0, ease: Sine.easeOut }, 0)
  }, [])

  const listenExamStartBtn = () => {
    const q = qs.parse(location.search, { ignoreQueryPrefix: true })

    history.push({ pathname: EXAM_STARTED_ROUTE, search: qs.stringify(q) })
  }

  return (
    <ExamStartBlockView
      t={t}
      examStartBlockRef={examStartBlockRef}
      listenExamStartBtn={listenExamStartBtn}
    />
  )
}
