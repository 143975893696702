import {
  CHANGE_PASSWORD_REQUESTED,
  CHANGE_PASSWORD_SUCCEEDED,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_RESET
} from './types'

export const changePasswordRequested = content => ({
  type: CHANGE_PASSWORD_REQUESTED,
  payload: content
})
export const changePasswordSucceeded = content => ({
  type: CHANGE_PASSWORD_SUCCEEDED,
  payload: content
})
export const changePasswordFailed = content => ({
  type: CHANGE_PASSWORD_FAILED,
  payload: content
})
export const changePasswordReset = () => ({
  type: CHANGE_PASSWORD_RESET
})
