import React from 'react'

import { CategorySelect, SubjectSelect } from 'modules'

export default function TicketNav() {
  return (
    <div className="ticket-nav">
      <CategorySelect />
      <SubjectSelect />
    </div>
  )
}
