import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ActivityView from '../views/Activity'

import { CHART_TYPES } from 'consts'
import { useWatchQuery } from 'hooks'

export default function Activity() {
  const { t } = useTranslation()

  const auth = useSelector(({ auth }) => auth)

  const { q } = useWatchQuery()

  const [charts, setCharts] = useState({
    type: CHART_TYPES.week,
    data: auth.user.ticketLogByWeek
  })

  useEffect(() => {
    let period = {
      type: CHART_TYPES.week,
      data: auth.user.ticketLogByWeek
    }

    if (q.period === CHART_TYPES.month) {
      period = {
        type: CHART_TYPES.month,
        data: auth.user.ticketLogByMonth
      }
    }
    setCharts(period)
  }, [q.period, auth])

  const calculatePillar = (total, oneOf) => ({
    height: total ? (100 / total) * oneOf + '%' : 100 + '%'
  })

  return <ActivityView t={t} charts={charts} calculatePillar={calculatePillar} />
}
