import React from 'react'
import { Link } from 'react-scroll'
import PropTypes from 'prop-types'

import { scrollNavSettings } from 'utils/app'

TipNav.propTypes = {
  tips: PropTypes.object.isRequired
}

export default function TipNav({ tips }) {
  return (
    <div className="tip-nav">
      {tips.data
        .filter(({ isMain }) => !isMain)
        .map(({ id, title }) => (
          <Link to={`tip-${id}`} {...scrollNavSettings} key={id}>
            {title}
          </Link>
        ))}
    </div>
  )
}
