import {
  CHANGE_PASSWORD_REQUESTED,
  CHANGE_PASSWORD_SUCCEEDED,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_RESET
} from '../actions/types'

const initialState = {
  data: undefined,
  errors: undefined,
  loading: false,
  processed: false
}

export default function changePassword(state = initialState, action) {
  const actionTypes = {
    [CHANGE_PASSWORD_REQUESTED]: {
      ...state,
      ...action.payload,
      loading: true,
      processed: false
    },
    [CHANGE_PASSWORD_SUCCEEDED]: {
      ...state,
      ...action.payload,
      loading: false,
      processed: true
    },
    [CHANGE_PASSWORD_FAILED]: {
      ...state,
      ...action.payload,
      loading: false,
      processed: true
    },
    [CHANGE_PASSWORD_RESET]: {
      ...state,
      processed: false
    },
    default: state
  }

  return Reflect.has(actionTypes, action.type)
    ? actionTypes[action.type]
    : actionTypes.default
}
