import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'components'

TerminatedTicket.propTypes = {
  lastTickets: PropTypes.object,
  listenContTicketsBtn: PropTypes.func.isRequired
}

export default function TerminatedTicket({ t, lastTickets, listenContTicketsBtn }) {
  return (
    <div className="terminated-ticket">
      <p className="title">{t('terminated_ticket')}</p>
      <div className="ticket-counter">
        <div className="ticket-true">
          <i>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14">
              <path d="M7.578 9.353l9.043-8.79a2.02 2.02 0 0 1 2.8 0c.772.752.772 1.971 0 2.723L8.977 13.436a2.02 2.02 0 0 1-2.8 0L.58 7.992a1.887 1.887 0 0 1 0-2.722 2.02 2.02 0 0 1 2.8 0l4.198 4.083z" />
            </svg>
          </i>
          <p>
            <span>{lastTickets?.correct}</span>
            <b>{t('correct')}</b>
          </p>
        </div>
        <div className="ticket-false">
          <i>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
              <path d="M9.586 7l3.878 3.879a1.828 1.828 0 1 1-2.585 2.585L7 9.586l-3.879 3.878A1.828 1.828 0 1 1 .536 10.88L4.414 7 .536 3.121A1.828 1.828 0 1 1 3.12.536L7 4.414 10.879.536a1.828 1.828 0 1 1 2.585 2.585L9.586 7z" />
            </svg>
          </i>
          <p>
            <span>{lastTickets?.wrong}</span>
            <b>{t('mistake')}</b>
          </p>
        </div>
      </div>
      <div className="pg-bar">
        <p>
          <span>{lastTickets?.counter}</span>
          <span>/ {lastTickets?.totalCount}</span>
        </p>
        <i style={{ width: `${lastTickets?.progressV1}%` }}></i>
      </div>
      {lastTickets && (
        <div className="ticket-continue" onClick={listenContTicketsBtn}>
          <button type="button">{t('continue')}</button>
        </div>
      )}
      {!lastTickets && (
        <div className="no-result">
          <p>{t('no_activity')}</p>
          <Link to="/tickets">{t('start_learning')}</Link>
        </div>
      )}
    </div>
  )
}
