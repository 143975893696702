import AuthListener from './AuthListener'
import AuthUser from './AuthUser'

export default [
  {
    hasAuth: false,
    exact: true,
    path: '/auth-listener',
    component: AuthListener
  },
  {
    hasAuth: false,
    exact: true,
    path: '/auth-user',
    component: AuthUser
  }
]
