import { call, put, take, fork } from 'redux-saga/effects'

import {
  textPartFetchSucceeded,
  textPartFetchFailed,
  textPartFetchRequested
} from '../actions'

import { textPartService } from 'services'

function* fetchTextPart({ payload }) {
  try {
    const data = yield call(textPartService.fetchTextPart({ payload }))

    yield put(textPartFetchSucceeded({ data: { ...data, ...payload } }))
  } catch (errors) {
    yield put(textPartFetchFailed({ errors }))
  }
}

export default function* watcher() {
  while (true) {
    const { payload } = yield take(textPartFetchRequested().type)

    yield fork(fetchTextPart, { payload })
  }
}
