import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import ProfileProgressModalView from '../views/ProfileProgressModal'

import { updateLvlNotifyRequested } from '../actions'
import { useModalManager } from 'hooks'

ProfileProgressModal.propTypes = {
  data: PropTypes.object.isRequired
}

export default function ProfileProgressModal({ data }) {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()

  const dispatch = useDispatch()

  const { modalRef, listenCloseModal } = useModalManager()

  useEffect(() => {
    dispatch(updateLvlNotifyRequested({ locale }))
  }, [dispatch, locale])

  return (
    <ProfileProgressModalView
      modalRef={modalRef}
      t={t}
      listenCloseModal={listenCloseModal}
      data={data}
    />
  )
}
