import Ticket from './containers/Ticket'

export default {
  exact: true,
  isNav: true,
  name: 'tickets',
  purePath: '/tickets',
  path: '/tickets',
  component: Ticket
}
