import {
  DRIVING_SCHOOLS_FETCH_REQUESTED,
  DRIVING_SCHOOLS_FETCH_SUCCEEDED,
  DRIVING_SCHOOLS_FETCH_FAILED
} from './types'

export const drivingSchoolsFetchRequested = content => ({
  type: DRIVING_SCHOOLS_FETCH_REQUESTED,
  payload: content
})
export const drivingSchoolsFetchSucceeded = content => ({
  type: DRIVING_SCHOOLS_FETCH_SUCCEEDED,
  payload: content
})
export const drivingSchoolsFetchFailed = content => ({
  type: DRIVING_SCHOOLS_FETCH_FAILED,
  payload: content
})
