import React from 'react'

import RegulatorNavView from '../views/RegulatorNav'

import { useRegulatorService } from 'hooks/services'

export default function RegulatorNav() {
  const { regulators } = useRegulatorService()

  return <RegulatorNavView regulators={regulators} />
}
