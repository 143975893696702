export const RS_QUEST_MODAL = 'RS_QUEST_MODAL'
export const RS_QUEST_BTN_MODAL = 'RS_QUEST_BTN_MODAL'
export const RS_FULLSCREEN_MODAL = 'RS_FULLSCREEN_MODAL'
export const TICKET_FINISH_MODAL = 'TICKET_FINISH_MODAL'
export const TICKET_QUEST_MODAL = 'TICKET_QUEST_MODAL'
export const PROFILE_EDIT_MODAL = 'PROFILE_EDIT_MODAL'
export const DS_REVIEW_MODAL = 'DS_REVIEW_MODAL'
export const PROFILE_PROGRESS_MODAL = 'PROFILE_PROGRESS_MODAL'
export const PROFILE_PASSWORD_MODAL = 'PROFILE_PASSWORD_MODAL'

export const EXAM_ROUTE = '/exam'
export const EXAM_STARTED_ROUTE = '/exam/started'
export const TICKETS_ROUTE = '/tickets'
export const ROAD_SIGNS_ROUTE = '/road-signs/:categoryId?'
export const CATEGORIES_ROUTE = '/categories'
export const DRIVING_SCHOOL_ROUTE = '/driving-schools'
export const PROFILE_ROUTE = '/profile'
export const TIPS_ROUTE = '/tips'
export const REGULATORS_ROUTE = '/regulators'

export const CATEGORY_SELECT = 'categoryId'
export const SUBJECT_SELECT = 'subjectId'
export const CITY_SELECT = 'cityId'
export const CATEGORY_SELECTS = 'categoryIds'
export const RATING_SELECT = 'rating'
export const PERIOD_SELECT = 'period'

export const AUTO_TICKET_CHECKBOX = 'autoTicket'

export const VICTORY_STATUS = 'VICTORY_STATUS'
export const LOSING_STATUS = 'LOSING_STATUS'
export const COMPLETE_STATUS = 'COMPLETE_STATUS'

export const EXAM_TICKET_LIMIT = 30
export const EXAM_TIME = 30 * 60
export const EXAM_TRY_WRONG = 3
export const EXAM_TRY_VICTORY = 27
export const WIZARD_MAX_LVL = 4 // from 0

export const X1380_SIZE = 1380

export const TYPE_TICKETS = 'tickets'
export const TYPE_EXAM = 'exam'

export const CHART_TYPES = {
  week: 'week',
  month: 'month'
}

export const TEXT_PART_IDS = {
  about: 3,
  categories: 4,
  joinUs: 6,
  bigRace: 7
}
