import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser'
import PropTypes from 'prop-types'

import { DsTimeSchedule, DsStarRating } from 'modules'

import { useDrivingSchoolService } from 'hooks/services'

import closeIcon from 'resources/svgs/close-2.svg'
import wwwDarkIcon from 'resources/svgs/s-www-dark.svg'
import licenseDarkIcon from 'resources/svgs/s-license-dark.svg'
import phoneDarkIcon from 'resources/svgs/s-phone-dark.svg'

SchoolDetail.propTypes = {
  schoolDetail: PropTypes.object.isRequired,
  setSchoolDetail: PropTypes.func.isRequired
}

export default function SchoolDetail({ schoolDetail, setSchoolDetail }) {
  const { t } = useTranslation()

  const containerRef = useRef()

  const { drivingSchools } = useDrivingSchoolService({ fetch: false })

  const listenCloseBtn = () => setSchoolDetail({ show: false })

  useEffect(() => {
    const { current: container } = containerRef

    container.scrollTop = 0
  }, [schoolDetail.id])

  const drivingSchool = drivingSchools.data.find(({ id }) => id === schoolDetail.id)

  const renderStar = starCount => {
    let stars = []

    for (let i = 0; i < 5; i++) {
      stars.push(
        <li className={i < starCount ? 'starred' : ''} key={i}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
            <path d="M13.903 17.064l-.937-5.745 3.997-4.096-5.521-.844L9 1.177 6.558 6.379l-5.52.844 3.996 4.096-.937 5.745L9 14.354l4.903 2.71z" />
          </svg>
        </li>
      )
    }

    return stars
  }

  return (
    <>
      {drivingSchool && (
        <div className="school-display">
          <div className="view" ref={containerRef}>
            <div className="image">
              {drivingSchool.hasCover && <img src={drivingSchool.cover} alt="" />}
              <button type="button" onClick={listenCloseBtn}>
                <img src={closeIcon} alt="" />
              </button>
            </div>
            <div className="caption">
              <div className="text">
                <p>{drivingSchool.title}</p>
                <span>{drivingSchool.address}</span>
              </div>
              <div className="rating-stars">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9">
                  <path d="M4.5 7.463L1.719 9l.531-3.256L0 3.438l3.11-.475L4.5 0l1.39 2.963L9 3.438 6.75 5.744 7.281 9z" />
                </svg>
                <p>{drivingSchool.rating}</p>
              </div>
            </div>
            <div className="info-www">
              <div className="left">
                <img src={licenseDarkIcon} alt="" />
                <p>{drivingSchool.categories}</p>
              </div>
              <div className="right">
                <img src={wwwDarkIcon} alt="" />
                <a
                  href={drivingSchool.siteUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('website')}
                </a>
              </div>
            </div>
            <div className="phone">
              <img src={phoneDarkIcon} alt="" />
              <span>{drivingSchool.phone}</span>
            </div>
            <DsTimeSchedule timetables={drivingSchool.timetables} />
            <div className="description">
              <p className="title">{t('description')}</p>
              <div className="editor">{ReactHtmlParser(drivingSchool.desc)}</div>
            </div>
            <div className="reviews">
              <div className="head">
                <div className="left">
                  <p>{t('review')}</p>
                </div>
                <div className="right">
                  <DsStarRating drivingSchoolId={drivingSchool.id} />
                </div>
              </div>
              <div className="list">
                {drivingSchool.comments.map(comment => (
                  <div className="item" key={comment.id}>
                    <div className="wrap">
                      <div className="avatar">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 311.54 258.88"
                        >
                          <path
                            fill="#FD4100"
                            d="M155.77,0A155.77,155.77,0,0,0,35.31,254.53c3.41-27.25,30.63-50.31,68.8-61.22,14,13,32,20.88,51.66,20.88,19.23,0,36.89-7.49,50.7-19.94,38.5,11.87,65.14,36.27,66,64.63A155.73,155.73,0,0,0,155.77,0Zm0,195.74a55.56,55.56,0,0,1-27.63-7.47c-20-11.41-33.86-34.75-33.86-61.71,0-38.14,27.58-69.17,61.49-69.17s61.49,31,61.49,69.17c0,27.37-14.24,51-34.79,62.22A55.52,55.52,0,0,1,155.77,195.74Z"
                          />
                        </svg>
                      </div>
                      <div className="cmt-info">
                        <p>{comment.author}</p>
                        <div className="stars">
                          <ul>{renderStar(comment.rate)}</ul>
                          <em>{moment(comment.createdAt).format('YYYY-MM-DD')}</em>
                        </div>
                        <span>{comment.text}</span>
                      </div>
                    </div>
                  </div>
                ))}
                {!drivingSchool.comments.length && (
                  <p className="no-review">{t('no_review')}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
