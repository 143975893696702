import React from 'react'

import RoadSignView from '../views/RoadSign'
import { ScrollTo } from 'components'

export default function RoadSign() {
  return (
    <>
      <RoadSignView />
      <ScrollTo />
    </>
  )
}
