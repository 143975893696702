import config from 'config'
import { cFetch } from 'tools'
import { regulatorTransformer } from 'transformers'

const {
  drivingApi: { endpoints }
} = config

export const regulatorService = {
  fetchRegulators: ({ payload }) => async () => {
    try {
      const url = endpoints.fetchRegulators.replace(':locale', payload.locale)

      const response = await cFetch(url)

      const data = await response.json()

      return regulatorTransformer.fetchRegulators(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
