import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { roadSignsFetchRequested } from 'modules/rsList/actions'

export const useRoadSignService = ({ fetch } = {}) => {
  const {
    i18n: { language: locale }
  } = useTranslation()

  const dispatch = useDispatch()

  const { roadSignCatId = 1 } = useParams()

  const roadSigns = useSelector(({ roadSigns }) => roadSigns, shallowEqual)

  useEffect(() => {
    if (!fetch) return

    dispatch(roadSignsFetchRequested({ locale, roadSignCatId }))
  }, [dispatch, locale, fetch, roadSignCatId])

  return { roadSigns }
}
