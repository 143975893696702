import React from 'react'
import { animateScroll as scroll } from 'react-scroll'
import PropTypes from 'prop-types'

import { Link } from 'components'

// import { PROFILE_EDIT_MODAL, PROFILE_PASSWORD_MODAL } from 'consts'
import { routes } from 'modules'

Footer.propTypes = {
  t: PropTypes.func.isRequired,
  cats: PropTypes.object.isRequired,
  listenNavBtn: PropTypes.func.isRequired
}

export default function Footer({ t, cats, listenNavBtn }) {
  return (
    <footer className="driving-footer">
      <div className="container">
        <div className="footer-top">
          <span className="footer-border-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="6"
              viewBox="0 0 12 6"
            >
              <path
                fill="#181A26"
                fillRule="nonzero"
                d="M1 0h10a1 1 0 1 1 0 2H1a1 1 0 1 1 0-2zm0 4h5a1 1 0 1 1 0 2H1a1 1 0 1 1 0-2z"
              />
            </svg>
          </span>
          <div className="footer-col">
            <h4 className="footer-heading">{t('menu')}</h4>
            <ul className="footer-list">
              {routes
                .filter(route => route.isNav)
                .map(route => (
                  <li key={route.purePath}>
                    <Link to={route.purePath}>{t(route.name)}</Link>
                  </li>
                ))}
            </ul>
          </div>
          <div className="footer-col">
            <h4 className="footer-heading">{t('profile')}</h4>
            <ul className="footer-list">
              <li>
                <button type="button" onClick={listenNavBtn()}>
                  {t('profile')}
                </button>
              </li>
              {/* <li>
                <button type="button" onClick={listenNavBtn(PROFILE_EDIT_MODAL)}>
                  {t('edit_profile')}
                </button>
              </li>
              <li>
                <button type="button" onClick={listenNavBtn(PROFILE_PASSWORD_MODAL)}>
                  {t('change_password')}
                </button>
              </li> */}
            </ul>
          </div>
          <div className="footer-col">
            <h4 className="footer-heading">{t('categories')}</h4>
            <ul className="footer-list">
              {cats.data
                .filter(({ isParent }) => isParent)
                .map(({ id, title, type }) => (
                  <li key={id}>
                    <Link to={`/categories#category-${type}`}>{title}</Link>
                  </li>
                ))}
            </ul>
          </div>
          <div className="footer-col">
            <h4 className="footer-heading">{t('soc_network')}</h4>
            <ul className="footer-social-list">
              <li>
                <a
                  href="https://www.facebook.com/MyAuto.Ge/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fb"
                >
                  <span className="social-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="17"
                      viewBox="0 0 8 17"
                    >
                      <path
                        fill="#26262a"
                        d="M5.32 17V8.5h2.366L8 5.57H5.32V4.104c0-.764.073-1.173 1.18-1.173h1.48V0H5.616C2.773 0 1.772 1.421 1.772 3.811V5.57H0V8.5h1.772V17z"
                      />
                    </svg>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/myauto.ge/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inst"
                >
                  <span className="social-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                    >
                      <g transform="translate(-1398 -2855)">
                        <path
                          fill="#26262a"
                          d="M9 18c-2.442 0-2.76-.01-3.711-.054a6.67 6.67 0 0 1-2.189-.418 4.4 4.4 0 0 1-1.594-1.038 4.408 4.408 0 0 1-1.033-1.596 6.67 6.67 0 0 1-.419-2.184C.01 11.759 0 11.442 0 9c0-2.426.01-2.746.054-3.711A6.626 6.626 0 0 1 .472 3.1a4.422 4.422 0 0 1 1.039-1.589A4.4 4.4 0 0 1 3.105.473 6.637 6.637 0 0 1 5.29.054C6.241.01 6.558 0 9 0s2.759.01 3.71.054a6.669 6.669 0 0 1 2.186.418 4.4 4.4 0 0 1 1.593 1.038 4.422 4.422 0 0 1 1.038 1.59 6.731 6.731 0 0 1 .418 2.184C17.989 6.25 18 6.57 18 9s-.01 2.749-.054 3.711a6.729 6.729 0 0 1-.418 2.184 4.611 4.611 0 0 1-2.628 2.633 6.681 6.681 0 0 1-2.186.418C11.759 17.99 11.441 18 9 18zM8.194 1.619c-1.691 0-2 .013-2.829.051a4.948 4.948 0 0 0-1.671.311 2.789 2.789 0 0 0-1.035.673 2.789 2.789 0 0 0-.673 1.035 4.968 4.968 0 0 0-.31 1.671c-.044.931-.053 1.213-.053 3.64s.009 2.706.052 3.637a4.968 4.968 0 0 0 .31 1.671 2.791 2.791 0 0 0 .673 1.035 2.789 2.789 0 0 0 1.035.673 5 5 0 0 0 1.671.31c.977.043 1.266.053 3.637.053s2.659-.009 3.637-.053a4.968 4.968 0 0 0 1.671-.31 2.983 2.983 0 0 0 1.707-1.716 4.994 4.994 0 0 0 .31-1.671c.043-.977.053-1.266.053-3.635s-.009-2.659-.053-3.635a4.968 4.968 0 0 0-.31-1.671 2.8 2.8 0 0 0-.673-1.035 2.781 2.781 0 0 0-1.035-.673 4.968 4.968 0 0 0-1.671-.31c-.931-.043-1.213-.052-3.637-.052h-.806zm.807 12A4.621 4.621 0 1 1 13.622 9 4.627 4.627 0 0 1 9 13.621zM9 6a3 3 0 1 0 3 3 3 3 0 0 0-3-3zm4.8-.725a1.08 1.08 0 1 1 1.08-1.08 1.081 1.081 0 0 1-1.08 1.081z"
                          transform="translate(1398 2855)"
                        />
                      </g>
                    </svg>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/my.ge/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="in"
                >
                  <span className="social-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                    >
                      <path
                        fill="#26262a"
                        d="M8.96 15H5.531c0-.082.045-9.176 0-10.12H8.96v1.432a3.813 3.813 0 0 1 1.034-1.094 3.591 3.591 0 0 1 2.058-.577 3.749 3.749 0 0 1 2.821 1.112A4.863 4.863 0 0 1 16 9.2V15h-3.429V9.585c0-1.044-.3-2.288-1.738-2.288a1.783 1.783 0 0 0-1.142.388 2.1 2.1 0 0 0-.617.842 2.189 2.189 0 0 0-.113.82V15zm-5.328 0H.2V4.879h3.43V15zM1.918 3.5H1.9A1.767 1.767 0 0 1 0 1.748 1.771 1.771 0 0 1 1.94 0a1.766 1.766 0 0 1 1.918 1.748 1.681 1.681 0 0 1-.522 1.24 2.023 2.023 0 0 1-1.418.512z"
                      />
                    </svg>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <button type="button" className="scroll-up" onClick={scroll.scrollToTop}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="34"
              viewBox="0 0 16 34"
            >
              <path
                fill="#FD4100"
                fillRule="nonzero"
                d="M.282 22.496a.2.2 0 0 1-.196-.014.191.191 0 0 1-.085-.174v-2.64a3.054 3.054 0 0 1 1.045-2.278l.908-.802C1.31 12.346 2.016 6.704 6.96.503A1.32 1.32 0 0 1 8.004 0c.409.001.794.19 1.04.509 4.952 6.197 5.658 11.835 5.006 16.084l.914.801A3.054 3.054 0 0 1 16 19.68v2.64a.192.192 0 0 1-.087.162.2.2 0 0 1-.186.018l-3.041-1.233c-.37.837-.807 1.644-1.307 2.413l-.291.442-6.168-.002-.289-.443c-.5-.77-.937-1.576-1.307-2.413L.282 22.496zM5.643 10.81a2.287 2.287 0 0 0 .691 1.638 2.385 2.385 0 0 0 1.673.677c1.302-.002 2.356-1.037 2.355-2.312 0-1.276-1.056-2.31-2.358-2.31-1.302-.001-2.358 1.031-2.36 2.307zm.716 18.427a3.08 3.08 0 0 0-1.201 1.002.285.285 0 0 1-.241.127.286.286 0 0 1-.27-.183c-.453-1.219-1.113-3.917 2.162-4.423a.135.135 0 0 1 .102.038.13.13 0 0 1 .04.1c-.11.901.225 1.8.902 2.42a.216.216 0 0 0 .29 0 2.819 2.819 0 0 0 .919-2.404.13.13 0 0 1 .038-.106.135.135 0 0 1 .108-.038c.577.05 2.325.342 2.51 2.096a2.849 2.849 0 0 1-.656 2.095c-.695.845-1.88 2.503-1.797 3.735a.278.278 0 0 1-.129.259.29.29 0 0 1-.293.01c-.916-.49-2.822-1.725-2.484-4.728z"
              />
            </svg>
          </button>
        </div>
        <div className="footer-bottom">
          <p className="footer-copyright">
            {new Date().getFullYear()} &copy; {t('all_rights_reserved')}
          </p>
        </div>
      </div>
    </footer>
  )
}
