import React from 'react'
import PropTypes from 'prop-types'

ExamStartAtCheckbox.propTypes = {
  t: PropTypes.func.isRequired,
  listenAtCheckbox: PropTypes.func.isRequired,
  defaultChecked: PropTypes.bool.isRequired
}

export default function ExamStartAtCheckbox({
  t,
  listenAtCheckbox,
  defaultChecked
}) {
  return (
    <div className="exam-at-checkbox-block">
      <input
        type="checkbox"
        id="auto-tickets"
        checked={defaultChecked}
        onChange={listenAtCheckbox}
      />
      <label htmlFor="auto-tickets" className="checkbox">
        <i></i>
      </label>
      <label htmlFor="auto-tickets" className="text">
        {t('auto_ticket')}
      </label>
    </div>
  )
}
