import { call, put, take, fork } from 'redux-saga/effects'

import {
  roadSignsFetchSucceeded,
  roadSignsFetchFailed,
  roadSignsFetchRequested
} from '../actions'

import { roadSignService } from 'services'

function* fetchRoadSigns({ payload }) {
  try {
    const data = yield call(roadSignService.fetchRoadSigns({ payload }))

    yield put(roadSignsFetchSucceeded({ data }))
  } catch (errors) {
    yield put(roadSignsFetchFailed({ errors }))
  }
}

export default function* watcher() {
  while (true) {
    const { payload } = yield take(roadSignsFetchRequested().type)

    yield fork(fetchRoadSigns, { payload })
  }
}
