import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import DsStarRatingView from '../views/DsStarRating'

import config from 'config'
import { DS_REVIEW_MODAL } from 'consts'
import { toggleModal } from 'modules/modalManager/actions'

DsStarRating.propTypes = {
  drivingSchoolId: PropTypes.number.isRequired
}

const {
  drivingApi: { endpoints }
} = config

export default function DsStarRating({ drivingSchoolId }) {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()

  const auth = useSelector(({ auth }) => auth)

  useEffect(() => {
    const star = auth.user?.drivingSchoolCommentIds?.[drivingSchoolId]

    setStarState({
      disabled: star && true,
      count: star || 0
    })
  }, [auth, drivingSchoolId])

  const starsRef = useRef([])

  const [starState, setStarState] = useState({
    disabled: false,
    count: 0
  })

  const dispatch = useDispatch()

  const onMouseEnter = index => () => {
    const { current: stars } = starsRef

    for (let star of stars.values()) {
      if (star.dataset.index <= index.toString()) {
        star.classList.add('starred')
      }
    }

    setStarState(prevState => ({
      ...prevState,
      count: index
    }))
  }

  const onMouseLeave = () => {
    const { current: stars } = starsRef

    let index = 0

    for (let star of stars.values()) {
      if (star.dataset.starred !== 'yes') {
        star.classList.remove('starred')
      } else {
        index = parseInt(star.dataset.index)
      }
    }

    setStarState(prevState => ({
      ...prevState,
      count: index
    }))
  }

  const onClick = index => () => {
    if (!auth.user) return redirectToAuth()

    const { current: stars } = starsRef

    for (let star of stars.values()) {
      if (star.dataset.starred === 'yes') {
        star.dataset.starred = 'no'
      }
      if (star.dataset.index <= index.toString()) {
        star.dataset.starred = 'yes'
      } else {
        star.classList.remove('starred')
      }
    }

    setStarState(prevState => ({
      ...prevState,
      count: index
    }))

    dispatch(
      toggleModal({
        name: DS_REVIEW_MODAL,
        data: { drivingSchoolId, starCount: starState.count, removeStars, lockStars }
      })
    )
  }

  const removeStars = () => {
    const { current: stars } = starsRef

    for (let star of stars.values()) {
      star.dataset.starred = 'no'
      star.classList.remove('starred')
    }

    setStarState(prevState => ({
      ...prevState,
      count: 0
    }))
  }

  const lockStars = () =>
    setStarState(prevState => ({
      ...prevState,
      disabled: true
    }))

  const events = index => ({
    onMouseEnter: onMouseEnter(index),
    onMouseLeave,
    onClick: onClick(index)
  })

  const redirectToAuth = () => {
    window.location.href = endpoints.authSignInUri.replace(/:locale/g, locale)
  }

  return (
    <DsStarRatingView
      t={t}
      starsRef={starsRef}
      events={events}
      starState={starState}
    />
  )
}
