/* eslint-disable react/display-name */
import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { TicketNav, RsNav, CategoryNav, DsNav, TipNav, RegulatorNav } from 'modules'

import {
  X1380_SIZE,
  EXAM_ROUTE,
  EXAM_STARTED_ROUTE,
  TICKETS_ROUTE,
  ROAD_SIGNS_ROUTE,
  CATEGORIES_ROUTE,
  DRIVING_SCHOOL_ROUTE,
  PROFILE_ROUTE,
  TIPS_ROUTE,
  REGULATORS_ROUTE
} from 'consts'

import { useViewport } from 'hooks'

export default function useMatchConfig({ headerClass = '' } = '') {
  const { vpLessThan } = useViewport()

  const routeMatch =
    useRouteMatch({
      path: [
        EXAM_STARTED_ROUTE,
        EXAM_ROUTE,
        TICKETS_ROUTE,
        ROAD_SIGNS_ROUTE,
        CATEGORIES_ROUTE,
        DRIVING_SCHOOL_ROUTE,
        PROFILE_ROUTE,
        TIPS_ROUTE,
        REGULATORS_ROUTE
      ]
    }) || {}

  const headerClassListConf = {
    [EXAM_STARTED_ROUTE]: 's-navigable no-shameful',
    [EXAM_ROUTE]: 'navigable no-shameful',
    [TICKETS_ROUTE]: 'navigable',
    [ROAD_SIGNS_ROUTE]: `navigable fixed-header ${headerClass}`,
    [CATEGORIES_ROUTE]: `navigable fixed-header ${headerClass}`,
    [DRIVING_SCHOOL_ROUTE]: 'navigable fixed-header',
    [PROFILE_ROUTE]: 'navigable no-shameful',
    [TIPS_ROUTE]: 'navigable fixed-header',
    [REGULATORS_ROUTE]: 'navigable fixed-header',
    default: vpLessThan(X1380_SIZE) ? 'navigable n-transparent no-shameful' : ''
  }

  const dropdownNavConf = {
    [EXAM_STARTED_ROUTE]: {
      y: 0,
      delay: 0
    },
    default: {
      y: -10,
      delay: 0
    }
  }

  const headerBuildConf = {
    [EXAM_STARTED_ROUTE]: () => null,
    [EXAM_ROUTE]: () => null,
    [TICKETS_ROUTE]: () => <TicketNav />,
    [CATEGORIES_ROUTE]: () => <CategoryNav />,
    [ROAD_SIGNS_ROUTE]: () => <RsNav />,
    [DRIVING_SCHOOL_ROUTE]: () => <DsNav />,
    [PROFILE_ROUTE]: () => null,
    [TIPS_ROUTE]: () => <TipNav />,
    [REGULATORS_ROUTE]: () => <RegulatorNav />,
    default: () => null
  }

  const isFooterConf = {
    [EXAM_STARTED_ROUTE]: false,
    [EXAM_ROUTE]: false,
    [TICKETS_ROUTE]: false,
    [PROFILE_ROUTE]: false,
    default: true
  }

  const isHome = vpLessThan(X1380_SIZE) ? false : !routeMatch.path

  const headerClassList = Reflect.has(headerClassListConf, routeMatch.path)
    ? headerClassListConf[routeMatch.path]
    : headerClassListConf.default

  const dropdownNav = Reflect.has(dropdownNavConf, routeMatch.path)
    ? dropdownNavConf[routeMatch.path]
    : dropdownNavConf.default

  const headerBuild = Reflect.has(headerBuildConf, routeMatch.path)
    ? headerBuildConf[routeMatch.path]
    : headerBuildConf.default

  const isFooter = Reflect.has(isFooterConf, routeMatch.path)
    ? isFooterConf[routeMatch.path]
    : isFooterConf.default

  return {
    isHome,
    headerClassList,
    dropdownNav,
    headerBuild,
    isFooter
  }
}
