import React from 'react'

import HomeView from '../views/Home'
import { ScrollTo } from 'components'

import { useCategoryService } from 'hooks/services'

export default function Home() {
  useCategoryService({ fetch: true })

  return (
    <>
      <HomeView />
      <ScrollTo />
    </>
  )
}
