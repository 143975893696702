import React from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

import { Loader } from 'components'
import ExamStartedView from '../views/ExamStarted'

import {
  TYPE_EXAM,
  TICKET_FINISH_MODAL,
  VICTORY_STATUS,
  LOSING_STATUS,
  EXAM_TIME,
  EXAM_TRY_WRONG,
  EXAM_TRY_VICTORY
} from 'consts'
import { toggleModal } from 'modules/modalManager/actions'
import { examTimerUpdate } from 'modules/examTimer/actions'
import { useTicketWizard, useKeyToBtn } from 'hooks'
import { useTicketService } from 'hooks/services'

export default function ExamStarted() {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const examTimer = useSelector(({ examTimer }) => examTimer)

  const { search } = useLocation()

  const { tickets, options } = useTicketService({ fetch: true, isExam: true })

  const autoTicket = qs.parse(search, { ignoreQueryPrefix: true }).autoTicket

  const closeModalCallback = () => dispatch(examTimerUpdate({ time: EXAM_TIME }))

  const finishCallback = state =>
    dispatch(
      toggleModal({
        name: TICKET_FINISH_MODAL,
        data: {
          ...state,
          closeModalCallback,
          status: state.correct >= EXAM_TRY_VICTORY ? VICTORY_STATUS : LOSING_STATUS
        }
      })
    )

  const { ticketWizard, listenAnswer, listenSkipBtn } = useTicketWizard({
    ticketList: tickets,
    finishCallback,
    examTimer,
    time: EXAM_TIME,
    examTryWrong: EXAM_TRY_WRONG,
    autoTicket: autoTicket === 'on',
    options: {
      ...options,
      type: TYPE_EXAM
    }
  })

  const { buttonsRef } = useKeyToBtn({
    keys: [32],
    lockupCallback: () => ticketWizard.isLocked
  })

  if (ticketWizard.loading || !ticketWizard.ticket) return <Loader />

  return (
    <ExamStartedView
      t={t}
      buttonsRef={buttonsRef}
      ticketWizard={ticketWizard}
      listenAnswer={listenAnswer}
      listenSkipBtn={listenSkipBtn}
      examTimer={examTimer}
    />
  )
}
