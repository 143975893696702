import React from 'react'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

import CategoryMultiSelectView from '../views/CategoryMultiSelect'

import { CATEGORY_SELECTS } from 'consts'
import { useWatchQuery, useDropdown } from 'hooks'
import { useCategoryService } from 'hooks/services'

export default function CategoryMultiSelect() {
  const { t } = useTranslation()

  const { cats } = useCategoryService({ fetch: true })

  const dropdown = useDropdown({ name: 'categoryMultiSelect', animable: true })

  const { watchQ, q, push } = useWatchQuery()

  const selectedIds = watchQ(CATEGORY_SELECTS) || []

  const listenCategoryBtn = value => event => {
    event.stopPropagation()

    let categoryIds = q[CATEGORY_SELECTS] || []

    if (!Array.isArray(categoryIds)) return

    const val = value.toString()

    const foundIndex = categoryIds.findIndex(id => id === val)

    foundIndex === -1
      ? (categoryIds = [...categoryIds, val])
      : categoryIds.splice(foundIndex, 1)

    push({ search: qs.stringify({ ...q, [CATEGORY_SELECTS]: categoryIds }) })
  }

  return (
    <CategoryMultiSelectView
      t={t}
      cats={cats}
      selectedIds={selectedIds}
      listenCategoryBtn={listenCategoryBtn}
      dropdown={dropdown}
    />
  )
}
