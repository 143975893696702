import React from 'react'

import { CitySelect, CategoryMultiSelect } from 'modules'

export default function DsNav() {
  return (
    <nav className="ds-nav">
      <CitySelect />
      <CategoryMultiSelect />
    </nav>
  )
}
