import React from 'react'
import { components } from 'react-select'

import selectArrowIcon from 'resources/svgs/select-arrow.svg'

export default function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <img src={selectArrowIcon} alt="" />
    </components.DropdownIndicator>
  )
}
