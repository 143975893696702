import { call, put, takeLatest } from 'redux-saga/effects'

import {
  ticketsFetchSucceeded,
  ticketsFetchFailed,
  ticketsFetchRequested
} from '../actions'

import { ticketService } from 'services'

function* fetchTickets({ payload: { locale, categoryId, subjectId, isExam } }) {
  try {
    const data = yield call(
      ticketService.fetchTickets({ locale, categoryId, subjectId, isExam })
    )

    yield put(ticketsFetchSucceeded({ data }))
  } catch (errors) {
    yield put(ticketsFetchFailed({ errors }))
  }
}

export default function* watcher() {
  yield takeLatest(ticketsFetchRequested().type, fetchTickets)
}
