import React from 'react'
import { useTranslation } from 'react-i18next'

import Block4View from '../views/Block4'

import { useTextPartService } from 'hooks/services'

export default function Block4() {
  const { t } = useTranslation()

  const { textPart } = useTextPartService({ fetch: true, part: 'bigRace' })

  return <Block4View t={t} textPart={textPart} />
}
