import React from 'react'
import PropTypes from 'prop-types'

ExamTicketTooltip.propTypes = {
  ticketTooltipRef: PropTypes.object.isRequired,
  ticket: PropTypes.object.isRequired
}

export default function ExamTicketTooltip({ ticketTooltipRef, ticket }) {
  return (
    <div className="placeholder-view" ref={ticketTooltipRef} style={{ opacity: 0 }}>
      <img src={ticket.image} alt="" />
      <span className="question">{ticket.question}</span>
      <div className={`answers answered`}>
        {ticket.answers.map((answer, index) => (
          <button
            type="button"
            key={answer.id}
            disabled={true}
            className={
              answer.correct
                ? 'true'
                : answer.id === ticket.result.answerId
                ? 'false'
                : ''
            }
          >
            <span>{++index}</span>
            <em>{answer.answer}</em>
          </button>
        ))}
      </div>
    </div>
  )
}
