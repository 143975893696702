import React from 'react'
import PropTypes from 'prop-types'

export const TramCatIcon = ({ className = '' } = '') => (
  <svg
    className={`license-i tram-cat-i ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 25 100 50"
    enableBackground="new 0 25 100 50"
    space="preserve"
  >
    <path
      d="M97.1,43.6c-0.3-1.1-1.3-1.9-2.4-1.9H70h-8.3l-1.2-1.2c-0.3-0.3-0.8-0.5-1.2-0.5h-1h-1.6h-5l8.3-5.8l-7.9-5.8v-2.5
c0-0.5-0.4-0.8-0.8-0.8h-2.5c-0.5,0-0.8,0.4-0.8,0.8v2.5L40,34.2l8.3,5.8h-5h-1.6h-1c-0.4,0-0.9,0.2-1.2,0.5l-1.2,1.2H30H5.3
c-1.1,0-2.1,0.8-2.4,1.9L0,55.1v15h8.3v1.7h5l0,0c0,1.8,1.5,3.2,3.3,3.2c1.8,0,3.3-1.4,3.3-3.2l0.1,0h5l0,0c0,1.8,1.5,3.2,3.3,3.2
c1.8,0,3.3-1.4,3.3-3.2h36.7c0,1.8,1.5,3.2,3.3,3.2c0,0,0.1,0,0.1,0c1.8,0,3.2-1.5,3.2-3.2l0.1,0h5l0,0c0,1.8,1.5,3.2,3.3,3.2
c0,0,0.1,0,0.1,0c1.8,0,3.2-1.5,3.2-3.2l0.1,0h5v-1.7h8.3v-15L97.1,43.6z M10.4,53.8L10.4,53.8c0,0.7-0.6,1.3-1.3,1.3H5.1H5
c-0.9,0-1.6-0.8-1.6-1.7c0-0.1,0-0.3,0.1-0.4L5,46.8c0.2-0.5,0.7-0.9,1.2-0.9h3c0.7,0,1.3,0.6,1.3,1.3V53.8z M20,67.1
c0,0.7-0.6,1.2-1.3,1.2h-4.2c-0.7,0-1.3-0.5-1.3-1.2v-20c0-0.7,0.6-1.3,1.3-1.3l4.2,0.1c0.7,0,1.3,0.5,1.3,1.2V67.1z M28.2,67.1
c0,0.7-0.5,1.2-1.2,1.2h-4.2c-0.7,0-1.2-0.5-1.2-1.2v-20c0-0.7,0.5-1.3,1.2-1.3l4.2,0.1c0.7,0,1.2,0.5,1.2,1.2V67.1z M41.9,53.8
c0,0.7-0.6,1.3-1.3,1.3l-8.3,0c0,0-0.1,0-0.1,0c-0.7,0-1.2-0.6-1.2-1.3v-6.7c0-0.7,0.5-1.3,1.2-1.3c0,0,0.1,0,0.1,0l8.3,0.1
c0.7,0,1.3,0.5,1.3,1.2V53.8z M42.1,34.2l7.9-5.8l7.9,5.8L50,39.7L42.1,34.2z M55.4,53.8c0,0.7-0.6,1.3-1.3,1.3h-8.3
c-0.7,0-1.3-0.6-1.3-1.3v-6.7c0-0.7,0.6-1.3,1.3-1.3l8.3,0.1c0.7,0,1.3,0.5,1.3,1.2V53.8z M69,53.8c0,0.7-0.6,1.3-1.3,1.3l-8.3,0
c0,0-0.1,0-0.1,0c-0.7,0-1.2-0.6-1.2-1.3v-6.7c0-0.7,0.5-1.3,1.2-1.3c0,0,0.1,0,0.1,0l8.3,0.1c0.7,0,1.3,0.5,1.3,1.2V53.8z
 M78.2,67.1c0,0.6-0.5,1.1-1.1,1.2H73c0,0-0.1,0-0.1,0c-0.7,0-1.2-0.5-1.2-1.2v-20c0-0.7,0.5-1.3,1.2-1.3c0,0,0.1,0,0.1,0l4.2,0.1
c0.6,0,1.1,0.6,1.1,1.2V67.1z M86.7,67.1c0,0.7-0.6,1.2-1.3,1.2h-4.2c-0.7,0-1.3-0.5-1.3-1.2v-20c0-0.7,0.6-1.3,1.3-1.3l4.2,0.1
c0.7,0,1.3,0.5,1.3,1.2V67.1z M94.9,55.1h-4.1c-0.7,0-1.3-0.6-1.3-1.3h0v-6.7c0-0.7,0.6-1.3,1.3-1.3h3c0.6,0,1.1,0.4,1.2,0.9
l1.4,6.2c0,0.1,0.1,0.3,0.1,0.4C96.5,54.3,95.8,55.1,94.9,55.1z"
    />
  </svg>
)

TramCatIcon.propTypes = {
  className: PropTypes.string
}
