import React from 'react'
import pinIcon from 'resources/svgs/pin.svg'

export const cityOptions = cities =>
  cities.map(city => ({
    value: city.id,
    label: city.title,
    // eslint-disable-next-line react/display-name
    icon: () => <img src={pinIcon} alt="" />
  }))
