import {
  ROAD_SIGNS_CATS_FETCH_REQUESTED,
  ROAD_SIGNS_CATS_FETCH_SUCCEEDED,
  ROAD_SIGNS_CATS_FETCH_FAILED
} from './types'

export const roadSignCatsFetchRequested = content => ({
  type: ROAD_SIGNS_CATS_FETCH_REQUESTED,
  payload: content
})
export const roadSignCatsFetchSucceeded = content => ({
  type: ROAD_SIGNS_CATS_FETCH_SUCCEEDED,
  payload: content
})
export const roadSignCatsFetchFailed = content => ({
  type: ROAD_SIGNS_CATS_FETCH_FAILED,
  payload: content
})
