import config from 'config'

const {
  drivingApi: {
    endpoints: { getRegulatorPhoto }
  }
} = config

const generatePhotos = ({ id, photo_count }) => {
  let photos = []

  for (let i = 1; i <= photo_count; i++) {
    photos.push(getRegulatorPhoto.replace(':id', id).replace(':index', i))
  }
  return photos
}

export const regulatorTransformer = {
  fetchRegulators: data => {
    try {
      return data.data.map(
        ({
          id,
          photo_count,
          order,
          regulator_options,
          short_title,
          sub_title,
          title
        }) => ({
          id,
          photos: generatePhotos({ id, photo_count }),
          title,
          shortTitle: short_title,
          subTitle: sub_title,
          options: regulator_options.map(({ id, title, desc, pivot }) => ({
            id,
            title,
            desc,
            isCorrect: pivot.is_correct
          })),
          order
        })
      )
    } catch (error) {
      throw new Error(error)
    }
  }
}
