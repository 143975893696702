/* eslint-disable react/display-name */
import React from 'react'

import {
  RS_QUEST_MODAL,
  RS_QUEST_BTN_MODAL,
  RS_FULLSCREEN_MODAL,
  TICKET_FINISH_MODAL,
  TICKET_QUEST_MODAL,
  PROFILE_EDIT_MODAL,
  DS_REVIEW_MODAL,
  PROFILE_PROGRESS_MODAL,
  PROFILE_PASSWORD_MODAL
} from 'consts'

import {
  RsQuestModal,
  RsQuestBtnModal,
  RsFullscreenModal,
  TicketFinishModal,
  TicketQuestModal,
  ProfileEditModal,
  DsReviewModal,
  ProfileProgressModal,
  ProfilePasswordModal
} from 'modules'

export default function ModalManager({ name, data }) {
  const modals = {
    [RS_QUEST_MODAL]: data => <RsQuestModal data={data} />,
    [RS_QUEST_BTN_MODAL]: data => <RsQuestBtnModal data={data} />,
    [RS_FULLSCREEN_MODAL]: data => <RsFullscreenModal data={data} />,
    [TICKET_FINISH_MODAL]: data => <TicketFinishModal data={data} />,
    [TICKET_QUEST_MODAL]: data => <TicketQuestModal data={data} />,
    [DS_REVIEW_MODAL]: data => <DsReviewModal data={data} />,
    [PROFILE_EDIT_MODAL]: data => <ProfileEditModal data={data} />,
    [PROFILE_PROGRESS_MODAL]: data => <ProfileProgressModal data={data} />,
    [PROFILE_PASSWORD_MODAL]: data => <ProfilePasswordModal data={data} />
  }

  return Reflect.has(modals, name) && modals[name](data)
}
