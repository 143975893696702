import React from 'react'

import TipsView from '../views/Tips'
import { ScrollTo } from 'components'

import { useTipService } from 'hooks/services'

export default function Tips() {
  const { tips } = useTipService({ fetch: true })

  return (
    <>
      <TipsView tips={tips} />
      <ScrollTo />
    </>
  )
}
