import config from 'config'
import { cFetch } from 'tools'
import { categoryTransformer } from 'transformers'

const {
  drivingApi: { endpoints }
} = config

export const categoryService = {
  fetchLessCats: ({ payload }) => async () => {
    const data = [
      {
        id: 1,
        icon: 'a-cat-icon',
        title: 'A, A1: მოტოციკლი, მსუბუქი მოტოციკლი',
        type: 'a'
      },
      {
        id: 2,
        icon: 'b-cat-icon',
        title: 'B, B1: მსუბუქი ავტომობილი, კვადროციკლი',
        type: 'b'
      },
      {
        id: 3,
        icon: 'c-cat-icon',
        title: 'C: სატვირთო ავტომობილი',
        type: 'c'
      },
      {
        id: 4,
        icon: 'c1-cat-icon',
        title: 'C1: მცირე სატვირთო ავტომობილი',
        type: 'c1'
      },
      {
        id: 5,
        icon: 'd-cat-icon',
        title: 'D: ავტობუსი',
        type: 'd'
      },
      {
        id: 6,
        icon: 'd1-cat-icon',
        title: 'D1: მიკროავტობუსი',
        type: 'd1'
      },
      {
        id: 7,
        icon: 'ts-cat-icon',
        title: 'T, S: სასოფლო-სამეურნეო და საგზაო-სამშენებლო მანქანები',
        type: 'ts'
      },
      {
        id: 8,
        icon: 'tram-cat-icon',
        title: 'Tram: ტრამვაი',
        type: 'tram'
      },
      {
        id: 9,
        icon: 'mil-cat-icon',
        title: 'B+C1 Mil: B და C1 კატეგორია სამხედრო მოსამსახურეთათვის',
        type: 'mil'
      }
    ]

    return categoryTransformer.fetchLessCats(data)
  },

  fetchCats: ({ payload }) => async () => {
    try {
      const url = endpoints.fetchCats.replace(':locale', payload.locale)

      const response = await cFetch(url)

      const data = await response.json()

      return categoryTransformer.fetchCats(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
