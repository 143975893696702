import React from 'react'
import { useTranslation } from 'react-i18next'

import Block5View from '../views/Block5'

import { useTextPartService, useAuthService } from 'hooks/services'

export default function Block5() {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()

  const { auth } = useAuthService()

  const { textPart } = useTextPartService({ fetch: true, part: 'joinUs' })

  return <Block5View t={t} locale={locale} auth={auth} textPart={textPart} />
}
