import React from 'react'

import TipNavView from '../views/TipNav'

import { useTipService } from 'hooks/services'

export default function TipNav() {
  const { tips } = useTipService()

  return <TipNavView tips={tips} />
}
