import { AUTH_FETCH_SUCCEEDED, AUTH_FETCH_FAILED } from '../actions/types'
import {
  ticketLogCreateSucceeded,
  saveLastTicketsSucceeded
} from 'modules/application/actions'

const initialState = {
  user: undefined,
  error: undefined,
  loading: true
}

export default function auth(state = initialState, action) {
  const actionTypes = {
    [AUTH_FETCH_SUCCEEDED]: {
      ...state,
      ...action.payload,
      loading: false
    },
    [AUTH_FETCH_FAILED]: {
      ...state,
      ...action.payload,
      loading: false
    },
    [ticketLogCreateSucceeded().type]: {
      ...state,
      user: { ...state.user, ...action.payload?.data }
    },
    [saveLastTicketsSucceeded().type]: {
      ...state,
      user: {
        ...state.user,
        userExp: {
          ...state.user?.userExp,
          lastTickets: action.payload?.data
        }
      }
    },
    default: state
  }

  return Reflect.has(actionTypes, action.type)
    ? actionTypes[action.type]
    : actionTypes.default
}
