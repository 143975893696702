import {
  CITIES_FETCH_REQUESTED,
  CITIES_FETCH_SUCCEEDED,
  CITIES_FETCH_FAILED
} from './types'

export const citiesFetchRequested = content => ({
  type: CITIES_FETCH_REQUESTED,
  payload: content
})
export const citiesFetchSucceeded = content => ({
  type: CITIES_FETCH_SUCCEEDED,
  payload: content
})
export const citiesFetchFailed = content => ({
  type: CITIES_FETCH_FAILED,
  payload: content
})
