import React from 'react'
import { useTranslation } from 'react-i18next'

import { routes } from 'modules'
import { NavLink } from 'components'

import { useDropdown } from 'hooks'

export const BurgerNav = () => {
  const { t } = useTranslation()

  const dropdown = useDropdown({ name: 'burgerNav', animable: true })

  const listenToggleDropdownBtn = () => dropdown.toggle()

  const listenLinkBtn = () => dropdown.toggle(false)

  return (
    <div className="burger-nav" ref={dropdown.containerRef}>
      <button
        type="button"
        className={`nav-btn ${dropdown.isOpen ? 'is-open' : ''}`}
        onClick={listenToggleDropdownBtn}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="6"
          viewBox="0 0 12 6"
          fill="#181A26"
        >
          <path
            fill="inherit"
            d="M280 36c.552 0 1 .448 1 1s-.448 1-1 1h-5c-.552 0-1-.448-1-1s.448-1 1-1h5zm5-4c.552 0 1 .448 1 1s-.448 1-1 1h-10c-.552 0-1-.448-1-1s.448-1 1-1h10z"
            transform="translate(-350 -199) translate(76 167)"
          />
        </svg>
      </button>
      {dropdown.isOpen && (
        <div className="display" ref={dropdown.displayRef}>
          <ul>
            {routes
              .filter(route => route.isNav)
              .map(route => (
                <li key={route.purePath}>
                  <NavLink to={route.purePath} onClick={listenLinkBtn}>
                    {t(route.name)}
                  </NavLink>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  )
}
