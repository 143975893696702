import {
  ACatIcon,
  BCatIcon,
  CCatIcon,
  C1CatIcon,
  DCatIcon,
  D1CatIcon,
  TsCatIcon,
  SCatIcon,
  TramCatIcon,
  MilCatIcon
} from 'components/icons'

import config from 'config'

import starIcon from 'resources/svgs/category-options/star-icon.svg'
import giftIcon from 'resources/svgs/category-options/gift-icon.svg'
import settingsIcon from 'resources/svgs/category-options/settings-icon.svg'
import speedometerIcon from 'resources/svgs/category-options/speedometer-icon.svg'
import xIcon from 'resources/svgs/category-options/x-icon.svg'
import massIcon from 'resources/svgs/category-options/mass-icon.svg'
import linkIcon from 'resources/svgs/category-options/link-icon.svg'
import humansIcon from 'resources/svgs/category-options/humans-icon.svg'
import humansRightIcon from 'resources/svgs/category-options/humans-right-icon.svg'
import linkMassIcon from 'resources/svgs/category-options/link-mass-icon.svg'
import checkIcon from 'resources/svgs/category-options/check-icon.svg'
import listIcon from 'resources/svgs/category-options/list-icon.svg'
import arrowBothIcon from 'resources/svgs/category-options/arrow-both-icon.svg'

const icons = {
  'a-cat-icon': ACatIcon,
  'b-cat-icon': BCatIcon,
  'c-cat-icon': CCatIcon,
  'c1-cat-icon': C1CatIcon,
  'd-cat-icon': DCatIcon,
  'd1-cat-icon': D1CatIcon,
  't-cat-icon': TsCatIcon,
  's-cat-icon': SCatIcon,
  'ts-cat-icon': TsCatIcon,
  'tram-cat-icon': TramCatIcon,
  'mil-cat-icon': MilCatIcon
}

const optionIcons = {
  'star-icon': starIcon,
  'gift-icon': giftIcon,
  'settings-icon': settingsIcon,
  'speedometer-icon': speedometerIcon,
  'x-icon': xIcon,
  'mass-icon': massIcon,
  'link-icon': linkIcon,
  'humans-icon': humansIcon,
  'humans-right-icon': humansRightIcon,
  'link-mass-icon': linkMassIcon,
  'check-icon': checkIcon,
  'list-icon': listIcon,
  'arrow-both-icon': arrowBothIcon
}

const {
  drivingApi: { endpoints }
} = config

const df = () => null

const getCatImages = ({ id, photo_count }) => {
  const data = []

  for (let i = 0; i < photo_count; i++) {
    data.push(
      endpoints.getCatImage.replace(':catId', id).replace(':photoCount', i + 1)
    )
  }

  return data
}

export const categoryTransformer = {
  fetchLessCats: data => {
    try {
      return data.map(category => ({
        value: category.id,
        icon: icons[category.icon],
        label: category.title
      }))
    } catch (error) {
      throw new Error(error)
    }
  },

  fetchCats: data => {
    try {
      return data.data.map(category => ({
        id: category.id,
        icon: icons[category.icon] || df,
        type: category.type,
        title: category.title,
        desc: category.desc,
        isParent: category.is_parent,
        subTitile: category.sub_titile,
        ticketCount: category.ticket_count,
        order: category.order,
        options: category.category_options.map(option => ({
          ...option,
          icon: optionIcons[option.icon]
        })),
        tempDesc: category.temp_desc,
        photos: getCatImages(category),
        createdAt: category.created_at,
        updatedAt: category.updated_at
      }))
    } catch (error) {
      throw new Error(error)
    }
  }
}
