export const swoopTransformer = {
  fetchSwoop: data =>
    data.map(
      ({
        ID,
        ImageUrl,
        MerchantName,
        ProductTitle,
        ProductLink,
        InitialPrice,
        DiscountStart,
        DiscountEnds,
        DiscountPrice,
        DiscountPercent
      }) => ({
        swoop: true,
        id: ID,
        hasPhoto: true,
        hasCover: false,
        cover: null,
        rating: '5.0',
        photo: ImageUrl,
        merchant: MerchantName,
        title: ProductTitle,
        url: ProductLink,
        price: InitialPrice,
        discountStart: DiscountStart,
        discountEnds: DiscountEnds,
        discountPrice: DiscountPrice,
        discountPercent: DiscountPercent,
        categories: [],
        timetables: [],
        comments: []
      })
    )
}
