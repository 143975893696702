import React, { useEffect, useRef } from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

CInputV1.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  value: PropTypes.string,
  textile: PropTypes.string,
  error: PropTypes.object
}

export default function CInputV1({ name, control, value, textile, error, ...rest }) {
  const inputRef = useRef()

  useEffect(() => {
    const { current: input } = inputRef
    if (!input.value) return

    input.dataset.focused = true

    return () => {
      input.dataset.focused = false
    }
  }, [value])

  const inputOptions = {
    onFocus: ({ target: { dataset } }) => {
      dataset.focused = true
    },
    onBlur: ({ target: { value, dataset } }) => {
      if (value) return
      dataset.focused = false
    }
  }

  return (
    <div className="c-input-v1">
      <div className="overflow" data-error={!!error}>
        <Controller
          name={name}
          control={control}
          defaultValue={value}
          as={<input ref={inputRef} {...inputOptions} />}
          {...rest}
        />
        {textile && <i>{textile}</i>}
      </div>
      {error && <span className="report-error">{error.message}</span>}
    </div>
  )
}
