import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Loader } from 'components'

export default function AuthListener() {
  const history = useHistory()

  useEffect(() => {
    window.parent.postMessage({
      func: 'authentic'
    })

    history.push('/')
  }, [history])

  return <Loader />
}
