import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import PropTypes from 'prop-types'

import racingImg from 'resources/imgs/racing-img.png'

Block4.propTypes = {
  t: PropTypes.func.isRequired,
  textPart: PropTypes.object.isRequired
}

export default function Block4({ t, textPart }) {
  return (
    <section className="home-section driving-racing-section right-decoration">
      <div className="home-section-left">
        <div className="racing-info">
          <div className="racing-section-text">
            <h2 className="home-section-title">{textPart.data.bigRace.title}</h2>
            {ReactHtmlParser(textPart.data.bigRace.desc)}
          </div>
        </div>

        <a className="btn-with-icon" href="/" style={{ display: 'none' }}>
          <span className="ticket-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="24"
              viewBox="0 0 34 24"
            >
              <path
                fill="#FD4100"
                fillRule="nonzero"
                d="M25.395 3.562C30.253 4.277 34 8.564 34 13.73 34 19.393 29.497 24 23.962 24c-.792 0-1.434-.657-1.434-1.467s.642-1.467 1.434-1.467c3.954 0 7.17-3.292 7.17-7.337 0-4.046-3.216-7.337-7.17-7.337-.792 0-1.434-.657-1.434-1.467v-1.99h-.588c-.792 0-1.434-.657-1.434-1.468 0-.81.642-1.467 1.434-1.467h4.057c.792 0 1.434.657 1.434 1.467s-.642 1.467-1.434 1.467h-.602v.628zm-1.433 4.05c.792 0 1.433.658 1.433 1.468v2.955l1.937-.572c.76-.22 1.556.224 1.775 1.003.22.778-.22 1.591-.98 1.816l-3.767 1.113a1.413 1.413 0 0 1-1.26-.237 1.477 1.477 0 0 1-.572-1.173V9.08c0-.81.642-1.467 1.434-1.467zm-5.667-4.21c.792 0 1.434.656 1.434 1.467 0 .81-.642 1.467-1.434 1.467H1.434C.642 6.336 0 5.679 0 4.869S.642 3.4 1.434 3.4h16.861zm0 5.887c.792 0 1.434.656 1.434 1.467 0 .81-.642 1.467-1.434 1.467H5.04c-.792 0-1.433-.657-1.433-1.467s.641-1.467 1.433-1.467h13.256zm0 5.889c.792 0 1.434.656 1.434 1.467 0 .81-.642 1.467-1.434 1.467h-7.847c-.792 0-1.434-.657-1.434-1.467s.642-1.467 1.434-1.467h7.847zm0 5.888c.792 0 1.434.656 1.434 1.467 0 .81-.642 1.467-1.434 1.467h-3.34c-.793 0-1.434-.657-1.434-1.467s.641-1.467 1.433-1.467h3.341z"
              />
            </svg>
          </span>
          {t('start_now')}
        </a>
      </div>
      <div className="home-section-right">
        <div className="racing-img">
          <img src={racingImg} alt="" />
        </div>
      </div>
    </section>
  )
}
