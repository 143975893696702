import React from 'react'
import PropTypes from 'prop-types'

import { CSelect, DropdownIndicator } from 'components'

SubjectSelect.propTypes = {
  t: PropTypes.func.isRequired,
  subjectSelectRef: PropTypes.object.isRequired,
  subjectOptions: PropTypes.array.isRequired,
  listenSelectChange: PropTypes.func.isRequired,
  defaultSubjectId: PropTypes.string
}

export default function SubjectSelect({
  t,
  subjectSelectRef,
  subjectOptions,
  listenSelectChange,
  defaultSubjectId
}) {
  return (
    <CSelect
      className="select-type-1"
      forwardRef={subjectSelectRef}
      options={subjectOptions}
      placeholder={t('select_subject')}
      onChange={listenSelectChange}
      isSearchable={false}
      value={subjectOptions.find(
        ({ value }) => value.toString() === defaultSubjectId
      )}
      components={{ DropdownIndicator }}
    />
  )
}
