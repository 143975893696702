import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

import TerminatedTicketView from '../views/TerminatedTicket'

import { setTickets } from 'utils/ticket'

export default function TerminatedTicket() {
  const { t } = useTranslation()

  const auth = useSelector(({ auth }) => auth)

  const history = useHistory()

  const listenContTicketsBtn = () => {
    const lastTickets = auth.user.userExp.lastTickets

    setTickets(lastTickets)

    history.push({
      pathname: 'tickets',
      search: qs.stringify({
        categoryId: lastTickets.categoryId,
        subjectId: lastTickets.subjectId
      })
    })
  }

  return (
    <TerminatedTicketView
      t={t}
      lastTickets={auth.user.userExp.lastTickets}
      listenContTicketsBtn={listenContTicketsBtn}
    />
  )
}
