import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { getCurrentRoute } from 'utils/app'

export default function BreadCrumb() {
  const { t } = useTranslation()

  const location = useLocation()

  const currentRoute = getCurrentRoute(location)

  return (
    <div className="bread-crumbs">
      <div className="container">
        <nav>
          <NavLink to="/">{t('home')}</NavLink>
          <span>{t(currentRoute?.name)}</span>
        </nav>
      </div>
    </div>
  )
}
