import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import CategoryView from '../views/Category'
import { ScrollTo } from 'components'

import { useCategoryService } from 'hooks/services'
import { groupByKey } from 'tools'
import { scrollNavSettings } from 'utils/app'

export default function Category() {
  const { t } = useTranslation()

  const { cats } = useCategoryService({ fetch: true })

  const { hash } = useLocation()

  const catsByType = groupByKey(cats.data, 'type')

  return (
    <>
      <CategoryView t={t} catsByType={catsByType} />
      <ScrollTo anchor={hash.replace('#', '')} settings={scrollNavSettings} />
    </>
  )
}
