import React from 'react'
import { components } from 'react-select'
import PropTypes from 'prop-types'

SingleValue.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
  })
}

export default function SingleValue(props) {
  return (
    <components.SingleValue {...props}>
      <span className="my__single-value-icon">
        {props.data.icon({ className: 'active' })}
      </span>
      <span className="my__single-value-text">{props.data.label}</span>
    </components.SingleValue>
  )
}
