import { padToTwo } from 'tools'

export default function convertTime(timestamp) {
  const days = Math.floor(timestamp / 3600 / 24)
  const hours = Math.floor(timestamp / 3600) % 24
  const minutes = Math.floor(timestamp / 60) % 60
  const seconds = timestamp % 60

  let d = `${padToTwo(days)}:d`,
    h = `${padToTwo(hours)}:h`,
    m = `${padToTwo(minutes)}:m`,
    s = `${padToTwo(seconds)}:s`

  if (!days && !hours && !minutes && !seconds) {
    d = h = m = s = ''
  }

  if (!days && !hours && !minutes) {
    d = h = m = ''
  }

  if (!days && !hours) {
    d = h = ''
  }

  if (!days) {
    d = ''
  }

  return {
    d,
    h,
    m,
    s,
    type1: `${padToTwo(hours)}:${padToTwo(minutes)}:${padToTwo(seconds)}`
  }
}
