import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ExamProgressBarTicketView from '../views/ExamProgressBarTicket'

ExamProgressBarTicket.propTypes = {
  ticketWizard: PropTypes.object.isRequired
}

export default function ExamProgressBarTicket({ ticketWizard }) {
  const [isTicketTooltip, setIsTicketTooltip] = useState({})

  const listenPlaceholder = n => () =>
    setIsTicketTooltip(prevState => ({
      ...prevState,
      [n]: !prevState[n]
    }))

  return (
    <ExamProgressBarTicketView
      listenPlaceholder={listenPlaceholder}
      isTicketTooltip={isTicketTooltip}
      totalCount={ticketWizard.totalCount}
      counter={ticketWizard.counter}
      tickets={ticketWizard.prevTickets}
    />
  )
}
