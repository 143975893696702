import React from 'react'
import { useSelector } from 'react-redux'

import ModalManagerView from '../views/ModalManager'

export default function ModalManager() {
  const modalManager = useSelector(state => state.modalManager)

  return (
    <>
      {modalManager.show && (
        <ModalManagerView name={modalManager.name} data={modalManager.data} />
      )}
    </>
  )
}
