import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import PropTypes from 'prop-types'

// import reviewAvatar from 'resources/imgs/tmp/review.jpg'

DsReviewModal.propTypes = {
  t: PropTypes.func.isRequired,
  modalRef: PropTypes.object.isRequired,
  listenCloseModal: PropTypes.func.isRequired,
  listenReviewTextChange: PropTypes.func.isRequired,
  disabledSubmitBtn: PropTypes.bool.isRequired,
  auth: PropTypes.object.isRequired,
  starCount: PropTypes.number.isRequired,
  listenSubmit: PropTypes.func.isRequired
}

export default function DsReviewModal({
  t,
  modalRef,
  listenCloseModal,
  listenReviewTextChange,
  disabledSubmitBtn,
  auth,
  starCount,
  listenSubmit
}) {
  let stars = []

  for (let i = 0; i < 5; i++) {
    stars.push(
      <li className={i < starCount ? 'starred' : ''} key={i}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
          <path d="M13.903 17.064l-.937-5.745 3.997-4.096-5.521-.844L9 1.177 6.558 6.379l-5.52.844 3.996 4.096-.937 5.745L9 14.354l4.903 2.71z" />
        </svg>
      </li>
    )
  }

  return (
    <div className="modal-manager" ref={modalRef} id="modal-manager">
      <div className="modal-manager-container">
        <button
          type="button"
          className="modal-manager-close-btn"
          onClick={listenCloseModal}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.01 7.01">
            <path d="M4.84,3.5,6.73,5.39a1,1,0,0,1,0,1.34.94.94,0,0,1-1.33,0h0L3.5,4.84,1.61,6.73A.94.94,0,0,1,.27,5.4h0L2.17,3.5.28,1.61A.94.94,0,0,1,.27.28a1,1,0,0,1,1.34,0h0L3.5,2.17,5.39.28A.94.94,0,0,1,6.73,1.61Z" />
          </svg>
        </button>
        <div className="modal-manager-view review-modal-view">
          <p className="caption">{t('rate')}</p>
          <div className="review">
            <div className="left">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 311.54 258.88">
                <path
                  fill="#FD4100"
                  d="M155.77,0A155.77,155.77,0,0,0,35.31,254.53c3.41-27.25,30.63-50.31,68.8-61.22,14,13,32,20.88,51.66,20.88,19.23,0,36.89-7.49,50.7-19.94,38.5,11.87,65.14,36.27,66,64.63A155.73,155.73,0,0,0,155.77,0Zm0,195.74a55.56,55.56,0,0,1-27.63-7.47c-20-11.41-33.86-34.75-33.86-61.71,0-38.14,27.58-69.17,61.49-69.17s61.49,31,61.49,69.17c0,27.37-14.24,51-34.79,62.22A55.52,55.52,0,0,1,155.77,195.74Z"
                />
              </svg>
              <p>{auth.user.fullName}</p>
            </div>
            <div className="right">
              <ul>{stars}</ul>
            </div>
          </div>
          <div className="form-container">
            <form onSubmit={listenSubmit} autoComplete="off">
              <div className="field">
                <TextareaAutosize
                  maxRows="3"
                  placeholder={t('comment')}
                  onChange={listenReviewTextChange}
                />
              </div>
              <div className="field">
                <button type="submit" disabled={disabledSubmitBtn}>
                  {t('add_review')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
