import React from 'react'
import { useTranslation } from 'react-i18next'

import { CSelect, Option, SingleValue, DropdownIndicator } from 'components'

import { CATEGORY_SELECT } from 'consts'
import { useSelect } from 'hooks'
import { useLessCatService } from 'hooks/services'

export default function CategorySelect({ ...rest }) {
  const { t } = useTranslation()

  const { selectRef, defaultSelectId, listenSelectChange } = useSelect({
    fieldName: CATEGORY_SELECT
  })

  const { lessCats } = useLessCatService({ fetch: true })

  return (
    <CSelect
      className="select-type-1"
      forwardRef={selectRef}
      options={lessCats.data}
      placeholder={t('select_category')}
      onChange={listenSelectChange}
      value={lessCats.data.find(({ value }) => value.toString() === defaultSelectId)}
      isSearchable={false}
      {...rest}
      components={{
        Option,
        SingleValue,
        DropdownIndicator
      }}
    />
  )
}
