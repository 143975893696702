import { call, put, take, fork } from 'redux-saga/effects'

import {
  drivingSchoolsFetchSucceeded,
  drivingSchoolsFetchFailed,
  drivingSchoolsFetchRequested
} from '../actions'

import { drivingSchoolService } from 'services'

function* fetchDrivingSchools({ payload }) {
  try {
    const data = yield call(drivingSchoolService.fetchDrivingSchools({ payload }))

    yield put(drivingSchoolsFetchSucceeded({ data }))
  } catch (errors) {
    yield put(drivingSchoolsFetchFailed({ errors }))
  }
}

export default function* watcher() {
  while (true) {
    const { payload } = yield take(drivingSchoolsFetchRequested().type)

    yield fork(fetchDrivingSchools, { payload })
  }
}
