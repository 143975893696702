import { call, put, takeLatest } from 'redux-saga/effects'

import {
  lessCatsFetchSucceeded,
  lessCatsFetchFailed,
  lessCatsFetchRequested
} from '../actions'

import { categoryService } from 'services'

function* fetchLessCats({ payload }) {
  try {
    const data = yield call(categoryService.fetchLessCats({ payload }))

    yield put(lessCatsFetchSucceeded({ data }))
  } catch (errors) {
    yield put(lessCatsFetchFailed({ errors }))
  }
}

export default function* watcher() {
  yield takeLatest(lessCatsFetchRequested().type, fetchLessCats)
}
