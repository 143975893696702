import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { animateScroll as scroll, scroller } from 'react-scroll'
import PropTypes from 'prop-types'

ScrollTo.propTypes = {
  anchor: PropTypes.string
}

export default function ScrollTo({ anchor, settings = { duration: 0 } }) {
  const { pathname } = useLocation()

  useEffect(() => {
    anchor ? scroller.scrollTo(anchor, settings) : scroll.scrollTo(1, settings)
    // eslint-disable-next-line
  }, [pathname, anchor])

  return null
}
