import { useEffect, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { TYPE_TICKETS } from 'consts'
import {
  ticketLogCreateRequested,
  saveLastTicketsRequested
} from 'modules/application/actions'
import { getTickets, removeSyncedTickets } from 'utils/ticket'

export default function useTicketLogCreate({
  interval = false,
  clearTickets = true
} = {}) {
  const {
    i18n: { language: locale }
  } = useTranslation()

  const dispatch = useDispatch()

  const ticketLogCreate = useSelector(
    ({ ticketLogCreate }) => ticketLogCreate,
    shallowEqual
  )

  const doSync = useCallback(() => {
    const tickets = getTickets()

    if (!tickets?.sync) return

    dispatch(
      ticketLogCreateRequested({
        locale,
        data: { tickets: tickets.syncTickets },
        type: tickets.type
      })
    )

    if (tickets.type !== TYPE_TICKETS) return

    dispatch(
      saveLastTicketsRequested({
        locale,
        data: { tickets },
        type: tickets.type
      })
    )
  }, [dispatch, locale])

  useEffect(() => {
    if (!interval) return

    const intervalId = setInterval(() => doSync(), 60 * 2000)
    return () => {
      clearInterval(intervalId)
    }
  }, [doSync, interval])

  useEffect(() => {
    if (!clearTickets || ticketLogCreate.loading || ticketLogCreate.errors) return
    removeSyncedTickets()
  }, [clearTickets, ticketLogCreate.loading, ticketLogCreate.errors])

  return { doSync }
}
