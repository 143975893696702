import {
  AUTH_FETCH_REQUESTED,
  AUTH_FETCH_SUCCEEDED,
  AUTH_FETCH_FAILED
} from './types'

export const authFetchRequested = content => ({
  type: AUTH_FETCH_REQUESTED,
  payload: content
})
export const authFetchSucceeded = content => ({
  type: AUTH_FETCH_SUCCEEDED,
  payload: content
})
export const authFetchFailed = content => ({
  type: AUTH_FETCH_FAILED,
  payload: content
})
