import React from 'react'
import PropTypes from 'prop-types'

import swoopLogo from 'resources/imgs/swopp-logo-2x.webp'

export const SwoopDiscount = ({ price, discountPrice, discountPercent }) => (
  <div className="swoop">
    <div className="swoop-up">
      <img src={swoopLogo} alt="" />
      <p>-{discountPercent}%</p>
    </div>
    <div className="swoop-down">
      <p>{price}₾</p>
      <span>{discountPrice}₾</span>
    </div>
  </div>
)

SwoopDiscount.propTypes = {
  price: PropTypes.string.isRequired,
  discountPrice: PropTypes.string.isRequired,
  discountPercent: PropTypes.string.isRequired
}
