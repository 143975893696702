import { SET_DROPDOWN } from '../actions/types'

const initialState = {}

export default function dropdown(state = initialState, action) {
  const actionTypes = {
    [SET_DROPDOWN]: {
      ...state,
      [action.payload?.name]: { isOpen: action.payload?.isOpen }
    },
    default: state
  }

  return Reflect.has(actionTypes, action.type)
    ? actionTypes[action.type]
    : actionTypes.default
}
