import { routes as authRoutes } from './auth'
import { routes as homeRoutes } from './home'
import { routes as examRoutes } from './exam'
import { routes as examStartedRoutes } from './examStarted'
import { routes as ticketRoutes } from './ticket'
import { routes as roadSignRoutes } from './roadSign'
import { routes as categoryRoutes } from './category'
import { routes as drivingSchoolRoutes } from './drivingSchool'
import { routes as profileRoutes } from './profile'
import { routes as tipRoutes } from './tips'
import { routes as regulatorRoutes } from './regulator'

import { catsReducers, catsSagas } from './category'
import { roadSignsReducers, roadSignsSagas } from './rsList'
import { roadSignCatsReducers, roadSignCatsSagas } from './rsNav'
import { rsModalReducers } from './rsModal'
import { rsQuestBtnReducers } from './rsQuestBtn'
import { rsQuestModalReducers, rsQuestModalSagas } from './rsQuestModal'
import { ticketsReducers, ticketsSagas } from './ticketList'
import { modalManagerReducers } from './modalManager'
import { subjectsReducers, subjectsSagas } from './subjectSelect'
import { examTimerReducers } from './examTimer'
import { authReducers, authSagas } from './auth'
import { profileEditReducers, profileEditSagas } from './profileEditModal'
import { tipsReducers, tipsSagas } from './tips'
import { regulatorReducers, regulatorSagas } from './regulator'
import { citiesReducers, citiesSagas } from './citySelect'
import { drivingSchoolsReducers, drivingSchoolsSagas } from './drivingSchool'
import { dsReviewReducers, dsReviewSagas } from './dsReviewModal'
import { changePasswordReducers, changePasswordSagas } from './profilePasswordModal'
import { lessCatsReducers, lessCatsSagas } from './categorySelect'
import { lvlNotifySagas } from './profileProgressModal'
import { textPartReducers, textPartSagas } from './home'
import { swoopSagas } from './swoop'

import {
  dropdownReducers,
  ticketLogCreateReducers,
  ticketLogCreateSagas,
  saveLastTicketsReducers,
  saveLastTicketsSagas
} from './application'

export { Header } from './header'
export { AuthBlock } from './authBlock'
export { RsNav } from './rsNav'
export { CategoryNav } from './categoryNav'
export { Footer } from './footer'
export { BreadCrumb } from './breadCrumb'
export { RsList } from './rsList'
export { RsModal } from './rsModal'
export { RsFullscreenModal } from './rsFullscreenModal'
export { RsQuestBtn } from './rsQuestBtn'
export { RsQuestBtnModal } from './rsQuestBtnModal'
export { RsQuestModal } from './rsQuestModal'
export { TicketNav } from './ticketNav'
export { TicketList } from './ticketList'
export { TicketListDescBlock } from './ticketListDescBlock'
export { TicketListAnswersBlock } from './ticketListAnswersBlock'
export { TicketFinishModal } from './ticketFinishModal'
export { ModalManager } from './modalManager'
export { TicketQuestModal } from './ticketQuestModal'
export { ExamPreparation } from './examPreparation'
export { SubjectSelect } from './subjectSelect'
export { CategorySelect } from './categorySelect'
export { ExamStartBlock } from './examStartBlock'
export { ExamStartAtCheckbox } from './examStartAtCheckbox'
export { ExamProgressBarTicket } from './examProgressBarTicket'
export { ExamTicketTooltip } from './examTicketTooltip'
export { ExamTimer } from './examTimer'
export { TicketBlock } from './ticketBlock'
export { TicketWizardLvlBlock } from './ticketWizardLvlBlock'
export { ProfileEditModal } from './profileEditModal'
export { DsNav } from './dsNav'
export { DsRatingSelect } from './dsRatingSelect'
export { DsTimeSchedule } from './dsTimeSchedule'
export { DsStarRating } from './dsStarRating'
export { DsReviewModal } from './dsReviewModal'
export { CitySelect } from './citySelect'
export { CategoryMultiSelect } from './categoryMultiSelect'
export { ProfileBox } from './profileBox'
export { ProfilePeriodSelect } from './profilePeriodSelect'
export { ProfileProgressModal } from './profileProgressModal'
export { ProfilePasswordModal } from './profilePasswordModal'
export { TipNav } from './tipNav'
export { RegulatorNav } from './regulatorNav'
export { Activity } from './activity'
export { LastMistake } from './lastMistake'
export { TerminatedTicket } from './terminatedTicket'

export const routes = [
  ...authRoutes,
  homeRoutes,
  examRoutes,
  examStartedRoutes,
  ticketRoutes,
  roadSignRoutes,
  categoryRoutes,
  drivingSchoolRoutes,
  profileRoutes,
  tipRoutes,
  regulatorRoutes
]

export const reducers = {
  cats: catsReducers,
  roadSigns: roadSignsReducers,
  roadSignCats: roadSignCatsReducers,
  rsModal: rsModalReducers,
  rsQuestBtn: rsQuestBtnReducers,
  rsQuest: rsQuestModalReducers,
  tickets: ticketsReducers,
  modalManager: modalManagerReducers,
  subjects: subjectsReducers,
  examTimer: examTimerReducers,
  auth: authReducers,
  profileEdit: profileEditReducers,
  tips: tipsReducers,
  regulators: regulatorReducers,
  cities: citiesReducers,
  drivingSchools: drivingSchoolsReducers,
  dsReview: dsReviewReducers,
  changePassword: changePasswordReducers,
  dropdown: dropdownReducers,
  ticketLogCreate: ticketLogCreateReducers,
  saveLastTickets: saveLastTicketsReducers,
  lessCats: lessCatsReducers,
  textPart: textPartReducers
}

export const sagas = [
  catsSagas,
  roadSignsSagas,
  roadSignCatsSagas,
  rsQuestModalSagas,
  ticketsSagas,
  subjectsSagas,
  authSagas,
  profileEditSagas,
  tipsSagas,
  regulatorSagas,
  citiesSagas,
  drivingSchoolsSagas,
  dsReviewSagas,
  changePasswordSagas,
  ticketLogCreateSagas,
  saveLastTicketsSagas,
  lessCatsSagas,
  lvlNotifySagas,
  textPartSagas,
  swoopSagas
]
