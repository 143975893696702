import React from 'react'
import PropTypes from 'prop-types'

import { CInputV1 } from 'components'

import backIcon from 'resources/svgs/back.svg'

ProfilePasswordModal.propTypes = {
  t: PropTypes.func.isRequired,
  modalRef: PropTypes.object.isRequired,
  listenCloseModal: PropTypes.func.isRequired,
  listenPasswordChangeSubmit: PropTypes.func.isRequired,
  listenProfileEditModal: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  newPasswordRef: PropTypes.object.isRequired,
  changePassword: PropTypes.object.isRequired
}

export default function ProfilePasswordModal({
  t,
  modalRef,
  listenCloseModal,
  listenPasswordChangeSubmit,
  listenProfileEditModal,
  control,
  handleSubmit,
  errors,
  data,
  newPasswordRef,
  changePassword
}) {
  return (
    <div
      className="modal-manager"
      ref={modalRef}
      id="modal-manager"
      style={{ opacity: data.anchor ? 1 : 0 }}
    >
      <div className="modal-manager-container">
        <div className="modal-manager-view profile-modal-view">
          <button
            type="button"
            className="modal-manager-close-btn"
            onClick={listenCloseModal}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.01 7.01">
              <path d="M4.84,3.5,6.73,5.39a1,1,0,0,1,0,1.34.94.94,0,0,1-1.33,0h0L3.5,4.84,1.61,6.73A.94.94,0,0,1,.27,5.4h0L2.17,3.5.28,1.61A.94.94,0,0,1,.27.28a1,1,0,0,1,1.34,0h0L3.5,2.17,5.39.28A.94.94,0,0,1,6.73,1.61Z" />
            </svg>
          </button>
          <div className="header-flow">
            {data.anchor && (
              <button type="button" onClick={listenProfileEditModal}>
                <img src={backIcon} alt="" />
              </button>
            )}
            <p className="caption">{t('change_password')}</p>
          </div>
          <div className="form-container">
            <form
              onSubmit={handleSubmit(listenPasswordChangeSubmit)}
              autoComplete="off"
            >
              <div className="field">
                <CInputV1
                  control={control}
                  name="currentPassword"
                  type="password"
                  value=""
                  textile={t('password')}
                  error={errors.currentPassword}
                  rules={{
                    required: { value: true, message: t('required_field') },
                    minLength: {
                      value: 2,
                      message: t('min_symbol').replace('%s', 2)
                    },
                    maxLength: {
                      value: 48,
                      message: t('max_symbol').replace('%s', 48)
                    }
                  }}
                />
              </div>
              <div className="field">
                <CInputV1
                  control={control}
                  name="newPassword"
                  type="password"
                  value=""
                  textile={t('new_password')}
                  error={errors.newPassword}
                  rules={{
                    required: { value: true, message: t('required_field') },
                    minLength: {
                      value: 2,
                      message: t('min_symbol').replace('%s', 2)
                    },
                    maxLength: {
                      value: 48,
                      message: t('max_symbol').replace('%s', 48)
                    }
                  }}
                />
              </div>
              <div className="field">
                <CInputV1
                  control={control}
                  name="reNewPassword"
                  type="password"
                  value=""
                  textile={t('re_new_password')}
                  error={errors.reNewPassword}
                  rules={{
                    required: { value: true, message: t('required_field') },
                    minLength: {
                      value: 2,
                      message: t('min_symbol').replace('%s', 2)
                    },
                    maxLength: {
                      value: 48,
                      message: t('max_symbol').replace('%s', 48)
                    },
                    validate: value =>
                      value === newPasswordRef.current || t('incorrect_password')
                  }}
                />
              </div>
              <div className="field">
                <div className="submit-btn-block">
                  <button type="submit" disabled={changePassword.loading}>
                    {t('save')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
