import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { SwoopDiscount } from 'components'
import licenseIcon from 'resources/svgs/s-license.svg'
import phoneIcon from 'resources/svgs/s-phone.svg'
import timeIcon from 'resources/svgs/s-time.svg'
import wwwIcon from 'resources/svgs/s-www.svg'

SchoolList.propTypes = {
  moEvents: PropTypes.func.isRequired,
  listenSchoolDetailBtn: PropTypes.func.isRequired,
  drivingSchools: PropTypes.object.isRequired
}

export default function SchoolList({
  moEvents,
  listenSchoolDetailBtn,
  drivingSchools
}) {
  
  const { t } = useTranslation()

  const layout = ({ drivingSchool }, children) => {
    if(drivingSchool.swoop) {
      return (
        <a href={drivingSchool.url} target="_blank" rel="noopener noreferrer" key={drivingSchool.id} className="item">
          {children}
        </a>
      )
    }
    
    return (
      <div key={drivingSchool.id}
        className="item"
        {...moEvents({
          id: drivingSchool.id,
          latitude: parseFloat(drivingSchool.mapLat),
          longitude: parseFloat(drivingSchool.mapLng)
        })}
        onClick={listenSchoolDetailBtn(drivingSchool.id)}>
          {children}
      </div>
    )
  }

  return (
    <div className="school-list">
      {drivingSchools.data.map(drivingSchool => (
        layout({drivingSchool},
          <div className="wrap">
            <div className="image">
              <div className="rating-stars">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9">
                  <path d="M4.5 7.463L1.719 9l.531-3.256L0 3.438l3.11-.475L4.5 0l1.39 2.963L9 3.438 6.75 5.744 7.281 9z" />
                </svg>
                <p>{drivingSchool.rating}</p>
              </div>
              {drivingSchool.hasPhoto && <img src={drivingSchool.photo} alt="" />}
            </div>
            <div className="content">
              <div className="top">
                  <div>
                    <p className="address">{drivingSchool.address}</p>
                    <p className="title">{drivingSchool.title}</p>
                  </div>
                 {drivingSchool.swoop && <SwoopDiscount {...drivingSchool} />}
              </div>
              {!drivingSchool.swoop && (
                <div className="bottom">
                  <div className="l">
                    <div className="opt license">
                      <img src={licenseIcon} alt="" />
                      <p>{drivingSchool.categories}</p>
                    </div>
                    <div className="opt time">
                      <img src={timeIcon} alt="" />
                      <p>
                        {t('monday_friday')} <b>{drivingSchool.timetables[0]?.open}</b>{' '}
                        - <b>{drivingSchool.timetables[0]?.close}</b>
                      </p>
                    </div>
                  </div>
                  <div className="r">
                    <div>
                      <div className="opt phone">
                        <img src={phoneIcon} alt="" />
                        <p>{drivingSchool.phone}</p>
                      </div>
                      <div className="opt www">
                        <img src={wwwIcon} alt="" />
                        <a
                          href={drivingSchool.siteUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('website')}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      ))}
    </div>
  )
}
