import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PropTypes from 'prop-types'

import TicketListDescBlockView from '../views/TicketListDescBlock'

TicketListDescBlock.propTypes = {
  description: PropTypes.string.isRequired
}

export default function TicketListDescBlock({ description }) {
  const { t } = useTranslation()

  const [descExpand, setDescExpand] = useState(false)

  useEffect(() => {
    return () => {
      setDescExpand(false)
    }
  }, [description])

  const listenDescBtn = () => setDescExpand(prevState => !prevState)

  return (
    <TicketListDescBlockView
      t={t}
      description={description}
      listenDescBtn={listenDescBtn}
      descExpand={descExpand}
    />
  )
}
