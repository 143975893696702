import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

import { ticketsFetchRequested } from 'modules/ticketList/actions'

export const useTicketService = ({ isExam, fetch } = {}) => {
  const {
    i18n: { language: locale }
  } = useTranslation()

  const [options, setOptions] = useState({
    categoryId: undefined,
    subjectId: undefined
  })

  const { search } = useLocation()

  const dispatch = useDispatch()

  const tickets = useSelector(({ tickets }) => tickets)

  useEffect(() => {
    if (!fetch) return

    const q = qs.parse(search, { ignoreQueryPrefix: true })

    setOptions({
      categoryId: q.categoryId,
      subjectId: q.subjectId
    })

    dispatch(ticketsFetchRequested({ ...q, locale, isExam }))
  }, [dispatch, locale, fetch, search, isExam])

  return { tickets, options }
}
