export const options = {
  center: {
    lng: 44.8046555495273,
    lat: 41.690784961913124
  },
  zoom: [13],
  maxBounds: [
    [38.54381, 39.48108],
    [48.4315, 44.70999]
  ],
  // style: 'mapbox://styles/mapbox/streets-v9',
  style: 'https://map.my.ge/style/my/map/style.json',
  interactive: true,
  dragRotate: false,
  maxZoom: 19,
  minZoom: 2
}
