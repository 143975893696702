import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import TicketQuestModalView from '../views/TicketQuestModal'

import { randomSplice } from 'tools'
import { useModalManager, useTicketWizard, useKeyToBtn } from 'hooks'

import { toggleModal } from 'modules/modalManager/actions'

TicketQuestModal.propTypes = {
  data: PropTypes.object.isRequired
}

export default function TicketQuestModal({ data }) {
  const { modalRef, listenCloseModal } = useModalManager()

  const dispatch = useDispatch()

  const finishCallback = () => dispatch(toggleModal())

  const { ticketWizard, listenAnswer } = useTicketWizard({
    ticketList: data,
    finishCallback
  })

  const lockupCallback = () => ticketWizard.isLocked

  const { buttonsRef } = useKeyToBtn({
    keys: [
      [49, 50],
      [97, 98]
    ],
    lockupCallback
  })

  const [answers, setAnswers] = useState([])

  useEffect(() => {
    const randomA = randomSplice([...ticketWizard.ticket.answers], 2, {
      correct: true
    })

    if (randomA && randomA.length) {
      setAnswers(randomA)
    }

    // eslint-disable-next-line
  }, [ticketWizard.tickets])

  return (
    <TicketQuestModalView
      modalRef={modalRef}
      buttonsRef={buttonsRef}
      listenCloseModal={listenCloseModal}
      ticketWizard={ticketWizard}
      listenAnswer={listenAnswer}
      answers={answers}
    />
  )
}
