import {
  RS_QUEST_FETCH_REQUESTED,
  RS_QUEST_FETCH_SUCCEEDED,
  RS_QUEST_FETCH_FAILED
} from './types'

export const rsQuestFetchRequested = content => ({
  type: RS_QUEST_FETCH_REQUESTED,
  payload: content
})
export const rsQuestFetchSucceeded = content => ({
  type: RS_QUEST_FETCH_SUCCEEDED,
  payload: content
})
export const rsQuestFetchFailed = content => ({
  type: RS_QUEST_FETCH_FAILED,
  payload: content
})
