import config from 'config'
import { cFetch } from 'tools'
import { randomSplice } from 'tools'
import { ticketTransformer } from 'transformers'
import { EXAM_TICKET_LIMIT } from 'consts'
import tickets from 'data/tickets.json'

const {
  drivingApi: { endpoints }
} = config

export const ticketService = {
  fetchTickets: ({
    // locale,
    categoryId = '',
    subjectId = '',
    isExam = false
  }) => async () => {
    let data = ticketTransformer.fetchTickets(tickets)

    if (categoryId) {
      data = data.filter(ticket => ticket.categoryIds.includes(parseInt(categoryId)))
    }

    if (subjectId) {
      data = data.filter(ticket => ticket.subjectId.toString() === subjectId.trim())
    }

    if (isExam) {
      data = randomSplice(data, EXAM_TICKET_LIMIT)
    }

    return data
  },

  createTicketLog: ({ payload }) => async () => {
    try {
      const url = endpoints.createTicketLog.replace(':locale', payload.locale)

      const response = await cFetch(url, {
        method: 'POST',
        body: JSON.stringify(
          ticketTransformer.createTicketLogRequest(payload.data, payload.type)
        )
      })

      const data = await response.json()

      return ticketTransformer.createTicketLog(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  saveLastTickets: ({ payload }) => async () => {
    try {
      const url = endpoints.saveLastTickets.replace(':locale', payload.locale)

      const response = await cFetch(url, {
        method: 'POST',
        body: JSON.stringify(
          ticketTransformer.saveLastTicketsRequest(payload.data, payload.type)
        )
      })

      const data = await response.json()

      return ticketTransformer.saveLastTickets(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
