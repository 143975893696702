import React, { useEffect } from 'react'
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'
// import ReactPixel from 'react-facebook-pixel'
// import { hotjar } from 'react-hotjar'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'resources/styles/index.scss'

import { Loader } from 'components'
import { Header, Footer, ModalManager, routes } from 'modules'

import { useAuthService } from 'hooks/services'
import { useTicketLogCreate } from 'hooks'
import { hasAuthRoutes } from 'utils/app'

export default function App() {
  const {
    i18n: { pattern }
  } = useTranslation()

  const { auth } = useAuthService({ fetch: true })

  const { doSync } = useTicketLogCreate()

  useEffect(() => {
    doSync()
  }, [doSync])

  useEffect(() => {
    ReactGA.initialize('GTM-KH56JND')
    ReactGA.pageview(window.location.pathname + window.location.search)

    // ReactPixel.init(process.env.FB_PIXEL)
    // ReactPixel.pageView()

    // hotjar.initialize(process.env.HOTJAR_HJID, process.env.HOTJAR_HJSV)
  }, [])

  if (auth.loading || auth.errors) return <Loader />

  return (
    <Router>
      <Switch>
        <>
          <Route path={pattern}>
            <Header />
            {routes
              .filter(hasAuthRoutes(auth.user))
              .map(({ path, ...rest }, key) => (
                <Route {...rest} path={`${pattern}${path}`} key={key} />
              ))}
            <Footer />

            <ModalManager />
          </Route>
        </>
      </Switch>
    </Router>
  )
}
