import Category from './containers/Category'

export default {
  exact: true,
  isNav: true,
  name: 'categories',
  purePath: '/categories',
  path: '/categories',
  component: Category
}
