import i18n from 'i18next'
import { routes } from 'modules'

export const setToken = accessToken =>
  localStorage.setItem('accessToken', accessToken)
export const getToken = () => localStorage.getItem('accessToken')
export const removeToken = () => localStorage.removeItem('accessToken')

export const months = [
  'months[1]',
  'months[2]',
  'months[3]',
  'months[4]',
  'months[5]',
  'months[6]',
  'months[7]',
  'months[8]',
  'months[9]',
  'months[10]',
  'months[11]',
  'months[12]'
]

export const weeks = {
  1: 'week_days[1]',
  2: 'week_days[2]',
  3: 'week_days[3]',
  4: 'week_days[4]',
  5: 'week_days[5]',
  6: 'week_days[6]',
  7: 'week_days[7]'
}

// TEMP
export const getCurrentRoute = ({ pathname }) => {
  let purePath = pathname
  const locales = Object.keys(i18n.locales)

  for (let i = 0; i < locales.length; i++) {
    purePath = purePath.replace(`/${locales[i]}/`, '')
  }

  purePath = purePath.split('/').filter(path => path)[0] || ''

  const currentRoute = routes.filter(route => route.purePath === `/${purePath}`)[0]

  return currentRoute
}

export const scrollNavSettings = {
  activeClass: 'active',
  spy: true,
  smooth: true,
  offset: -300,
  duration: 500,
  hashSpy: true
}

export const carouselSettings = {
  dots: false,
  infinite: false,
  arrow: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
}

export const hasAuthRoutes = auth => ({ hasAuth }) =>
  auth ? hasAuth || hasAuth === undefined : !hasAuth || hasAuth === undefined
