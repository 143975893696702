import {
  TICKET_LOG_CREATE_REQUESTED,
  TICKET_LOG_CREATE_SUCCEEDED,
  TICKET_LOG_CREATE_FAILED
} from '../actions/types'

const initialState = {
  data: [],
  errors: undefined,
  loading: true
}

export default function ticketLogCreate(state = initialState, action) {
  const actionTypes = {
    [TICKET_LOG_CREATE_REQUESTED]: {
      ...state,
      ...action.payload,
      loading: true
    },
    [TICKET_LOG_CREATE_SUCCEEDED]: {
      ...state,
      ...action.payload,
      loading: false
    },
    [TICKET_LOG_CREATE_FAILED]: {
      ...state,
      ...action.payload,
      loading: false
    },
    default: state
  }

  return Reflect.has(actionTypes, action.type)
    ? actionTypes[action.type]
    : actionTypes.default
}
