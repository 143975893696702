import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import TicketListAnswersBlockView from '../views/TicketListAnswersBlock'

TicketListAnswersBlock.propTypes = {
  buttonsRef: PropTypes.object.isRequired,
  listenAnswer: PropTypes.func.isRequired,
  answers: PropTypes.array.isRequired
}

export default function TicketListAnswersBlock({
  buttonsRef,
  listenAnswer,
  answers
}) {
  const examTimer = useSelector(({ examTimer }) => examTimer)

  const [answered, setAnswered] = useState({
    isAnswered: false,
    className: undefined,
    classList: undefined
  })

  useEffect(() => {
    const { classList } = answered

    if (classList) {
      classList.remove('false')
    }

    return () => {
      setAnswered(prevState => ({
        ...prevState,
        isAnswered: false
      }))
    }
    // eslint-disable-next-line
  }, [examTimer.down || answers])

  const listenAnswerBtn = (isCorrect, answerId) => ({
    currentTarget: { classList }
  }) => {
    if (!isCorrect) {
      classList.add(isCorrect)
    }

    setAnswered(prevState => ({
      ...prevState,
      isAnswered: true,
      className: 'true',
      classList
    }))

    listenAnswer(isCorrect, answerId)
  }

  return (
    <TicketListAnswersBlockView
      buttonsRef={buttonsRef}
      answers={answers}
      answered={answered}
      listenAnswerBtn={listenAnswerBtn}
    />
  )
}
