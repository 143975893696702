import {
  SUBJECTS_FETCH_REQUESTED,
  SUBJECTS_FETCH_SUCCEEDED,
  SUBJECTS_FETCH_FAILED
} from './types'

export const subjectsFetchRequested = content => ({
  type: SUBJECTS_FETCH_REQUESTED,
  payload: content
})
export const subjectsFetchSucceeded = content => ({
  type: SUBJECTS_FETCH_SUCCEEDED,
  payload: content
})
export const subjectsFetchFailed = content => ({
  type: SUBJECTS_FETCH_FAILED,
  payload: content
})
