import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { swoopFetchRequested } from 'modules/swoop/actions'

export const useSwoopService = () => {
  const dispatch = useDispatch()

  const swoop = useSelector(({ swoop }) => swoop, shallowEqual)

  useEffect(() => {
    dispatch(swoopFetchRequested())
  }, [dispatch])

  return { swoop }
}
