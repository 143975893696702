import React from 'react'
import { useTranslation } from 'react-i18next'

import { CSelect, DropdownIndicatorV2 } from 'components'

import { PERIOD_SELECT, CHART_TYPES } from 'consts'
import { useSelect } from 'hooks'

const periodList = {
  data: [
    {
      id: CHART_TYPES.week,
      title: 'week'
    },
    {
      id: CHART_TYPES.month,
      title: 'month'
    }
  ]
}

export default function ProfilePeriodSelect() {
  const { t } = useTranslation()

  const {
    selectRef,
    defaultSelectId = CHART_TYPES.week,
    listenSelectChange
  } = useSelect({
    fieldName: PERIOD_SELECT
  })

  const periodOptions = periodList.data.map(rating => ({
    value: rating.id,
    label: t(rating.title)
  }))

  return (
    <CSelect
      className="select-type-1 select-type-1-mod"
      forwardRef={selectRef}
      options={periodOptions}
      placeholder={t('period')}
      onChange={listenSelectChange}
      value={periodOptions.find(({ value }) => value.toString() === defaultSelectId)}
      components={{ DropdownIndicator: DropdownIndicatorV2 }}
    />
  )
}
