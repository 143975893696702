import React from 'react'
import PropTypes from 'prop-types'

import { PROFILE_EDIT_MODAL, PROFILE_PASSWORD_MODAL } from 'consts'

ProfileBox.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  dropdown: PropTypes.object.isRequired,
  listenNavBtn: PropTypes.func.isRequired,
  listenSubNavBtn: PropTypes.func.isRequired,
  listenLogOutBtn: PropTypes.func.isRequired
}

export default function ProfileBox({
  t,
  auth,
  dropdown,
  listenNavBtn,
  listenSubNavBtn,
  listenLogOutBtn
}) {
  return (
    <div className="profile-box" ref={dropdown.containerRef}>
      <button type="button" className="profile-btn" onClick={listenNavBtn}>
        {auth.user.avatar ? (
          <img src={auth.user.avatar} alt="" />
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 311.54 258.88">
            <path
              fill="#FD4100"
              d="M155.77,0A155.77,155.77,0,0,0,35.31,254.53c3.41-27.25,30.63-50.31,68.8-61.22,14,13,32,20.88,51.66,20.88,19.23,0,36.89-7.49,50.7-19.94,38.5,11.87,65.14,36.27,66,64.63A155.73,155.73,0,0,0,155.77,0Zm0,195.74a55.56,55.56,0,0,1-27.63-7.47c-20-11.41-33.86-34.75-33.86-61.71,0-38.14,27.58-69.17,61.49-69.17s61.49,31,61.49,69.17c0,27.37-14.24,51-34.79,62.22A55.52,55.52,0,0,1,155.77,195.74Z"
            />
          </svg>
        )}
      </button>
      {dropdown.isOpen && (
        <div className="profile-nav">
          <div className="haed">
            <p>
              <span>{auth.user.fullName}</span>
              <em>id: {auth.user.id}</em>
            </p>
          </div>
          <ul className="nav-list">
            <li>
              <button type="button" onClick={listenSubNavBtn()}>
                {t('profile')}
              </button>
            </li>
            <li>
              <button type="button" onClick={listenSubNavBtn(PROFILE_EDIT_MODAL)}>
                {t('edit_profile')}
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={listenSubNavBtn(PROFILE_PASSWORD_MODAL)}
              >
                {t('change_password')}
              </button>
            </li>
            <li>
              <button type="button" onClick={listenLogOutBtn}>
                {t('logout')}
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}
