import { TEXT_PART_FETCH_SUCCEEDED, TEXT_PART_FETCH_FAILED } from '../actions/types'
import { TEXT_PART_IDS } from 'consts'
import { objectFlip } from 'tools'

const initialState = {
  data: {
    about: {},
    categories: {},
    bigRace: {},
    joinUs: {}
  },
  error: undefined,
  loading: true
}

export default function textPart(state = initialState, action) {
  const actionTypes = {
    [TEXT_PART_FETCH_SUCCEEDED]: {
      ...state,
      ...action.payload,
      data: {
        ...state.data,
        [objectFlip(TEXT_PART_IDS)[action.payload?.data?.id]]: action.payload?.data
      },
      loading: false
    },
    [TEXT_PART_FETCH_FAILED]: {
      ...state,
      ...action.payload,
      loading: false
    },
    default: state
  }

  return Reflect.has(actionTypes, action.type)
    ? actionTypes[action.type]
    : actionTypes.default
}
