import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import { ProfilePeriodSelect } from 'modules'

import { CHART_TYPES } from 'consts'
import { convertTime } from 'tools'

Activity.propTypes = {
  t: PropTypes.func.isRequired,
  charts: PropTypes.object.isRequired,
  calculatePillar: PropTypes.func.isRequired
}

export default function Activity({ t, charts, calculatePillar }) {
  const { d, h, m, s } = convertTime(Math.round(charts.data.time))

  return (
    <div className="activities">
      <div className="head">
        <p className="title">{t('daily_activity')}</p>
        <div className="period">
          <p>{t('period')}</p>
          <ProfilePeriodSelect />
        </div>
      </div>
      <div className="statistics">
        <div className="wrap">
          <div className="charts">
            {charts.data.loop.map(chart => (
              <div className="pillar" key={chart.index}>
                <div className="placeholder">
                  <p>
                    <span>{t('correct')}:</span>
                    <i>{chart.correct}</i>
                  </p>
                  <p>
                    <span>{t('mistake')}:</span>
                    <i>{chart.wrong}</i>
                  </p>
                </div>
                <div className="indicator">
                  <div
                    className={`low ${!chart.total ? 'no' : ''}`}
                    style={{ ...calculatePillar(chart.total, chart.wrong) }}
                  >
                    <i></i>
                  </div>
                  <div
                    className={`high ${!chart.total ? 'no' : ''}`}
                    style={{ ...calculatePillar(chart.total, chart.correct) }}
                  >
                    <i></i>
                  </div>
                </div>
                <div className="text">
                  {charts.type === CHART_TYPES.week ? (
                    <>
                      <p>{moment(chart.date).format('dd')}</p>
                      <p>{moment(chart.date).format('DD MMM')}</p>
                    </>
                  ) : (
                    <>
                      <p>{moment(chart.date).format('MMM')}</p>
                      <p>&nbsp;</p>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="result">
            <div className="block block-all">
              <p>
                <span>{charts.data.total}</span>
                <span>{t('total')}</span>
              </p>
            </div>
            <div className="block block-true">
              <p>
                <span>{charts.data.correct}</span>
                <span>{t('correct')}</span>
              </p>
            </div>
            <div className="block block-false">
              <p>
                <span>{charts.data.wrong}</span>
                <span>{t('mistake')}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="time">
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23 28"
              className={charts.data.total ? 'filled' : ''}
            >
              <path d="M11.14 5.364c1.231 0 2.462.202 3.693.607 3.26 1.08 5.822 3.677 6.886 6.983 1.165 3.61.599 7.422-1.53 10.39A11.08 11.08 0 0 1 11.173 28a11.76 11.76 0 0 1-3.692-.607C4.254 26.347 1.659 23.716.594 20.41c-1.164-3.61-.599-7.422 1.53-10.39a11.08 11.08 0 0 1 9.016-4.656zm.033 2.834a8.394 8.394 0 0 0-6.786 3.508c-1.597 2.227-2.03 5.094-1.131 7.86a8.08 8.08 0 0 0 5.123 5.195 9.203 9.203 0 0 0 2.828.473 8.394 8.394 0 0 0 6.786-3.509c1.597-2.226 2.03-5.094 1.131-7.86a8.08 8.08 0 0 0-5.123-5.195 8.295 8.295 0 0 0-2.828-.472zm0 1.956c3.56 0 6.454 2.935 6.454 6.545h-4.79c0 .944-.732 1.687-1.664 1.687-.931 0-1.663-.743-1.663-1.687 0-.945.732-1.687 1.663-1.687zm9.715-5.195l1.763 1.788c.434.44.463 1.117.087 1.561l-.087.092c-.466.472-1.198.472-1.63 0l-1.764-1.788c-.434-.44-.463-1.117-.087-1.561l.087-.092c.466-.472 1.198-.472 1.63 0zM13.468 0c.932 0 1.664.742 1.664 1.687v.506c0 .91-.732 1.687-1.663 1.687H8.878c-.932 0-1.663-.743-1.663-1.687v-.506C7.215.742 7.946 0 8.878 0h4.59z" />
            </svg>
          </div>
          <p>
            {`
              ${d.replace(':d', t('d'))} 
              ${h.replace(':h', t('h'))} 
              ${m.replace(':m', t('m'))} 
              ${s.replace(':s', t('s'))}
            `}
          </p>
        </div>
      </div>
      {!charts.data.total && (
        <div className="no-result">
          <p>{t('no_activity')}</p>
        </div>
      )}
    </div>
  )
}
