import { call, put, take, fork } from 'redux-saga/effects'

import { tipsFetchSucceeded, tipsFetchFailed, tipsFetchRequested } from '../actions'

import { tipService } from 'services'

function* fetchTips({ payload }) {
  try {
    const data = yield call(tipService.fetchTips({ payload }))

    yield put(tipsFetchSucceeded({ data }))
  } catch (errors) {
    yield put(tipsFetchFailed({ errors }))
  }
}

export default function* watcher() {
  while (true) {
    const { payload } = yield take(tipsFetchRequested().type)

    yield fork(fetchTips, { payload })
  }
}
