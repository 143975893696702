import Profile from './containers/Profile'

export default {
  hasAuth: true,
  exact: true,
  name: 'profile',
  purePath: '/profile',
  path: '/profile',
  component: Profile
}
