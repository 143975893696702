import React from 'react'

import RegulatorView from '../views/Regulator'
import { ScrollTo } from 'components'

import { useRegulatorService } from 'hooks/services'

export default function Regulator() {
  const { regulators } = useRegulatorService({ fetch: true })

  return (
    <>
      <RegulatorView regulators={regulators} />
      <ScrollTo />
    </>
  )
}
