import { call, put, take, fork } from 'redux-saga/effects'

import { catsFetchSucceeded, catsFetchFailed, catsFetchRequested } from '../actions'

import { categoryService } from 'services'

function* fetchCats({ payload }) {
  try {
    const data = yield call(categoryService.fetchCats({ payload }))

    yield put(catsFetchSucceeded({ data }))
  } catch (errors) {
    yield put(catsFetchFailed({ errors }))
  }
}

export default function* watcher() {
  while (true) {
    const { payload } = yield take(catsFetchRequested().type)

    yield fork(fetchCats, { payload })
  }
}
