import React from 'react'
import { useTranslation } from 'react-i18next'

import CitySelectView from '../views/CitySelect'

import { CITY_SELECT } from 'consts'
import { useSelect } from 'hooks'
import { useCityService } from 'hooks/services'
import { cityOptions } from 'utils/select'

export default function CitySelect() {
  const { t } = useTranslation()

  const { selectRef, defaultSelectId, listenSelectChange } = useSelect({
    fieldName: CITY_SELECT
  })

  const { cities } = useCityService({ fetch: true })

  return (
    <CitySelectView
      t={t}
      citySelectRef={selectRef}
      cityOptions={cityOptions(cities.data)}
      listenSelectChange={listenSelectChange}
      defaultCityId={defaultSelectId}
    />
  )
}
