import React from 'react'
import { NavLink as Nl, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

export const NavLink = ({ children, to, ...rest }) => {
  const { locale = null } = useParams()

  to =
    typeof to === 'object'
      ? (to.pathname = `${locale ? `/${locale}` : ''}${to.pathname}`)
      : `${locale ? `/${locale}` : ''}${to}`

  return (
    <Nl {...rest} to={to}>
      {children}
    </Nl>
  )
}

NavLink.propTypes = {
  children: PropTypes.any,
  to: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired])
}
