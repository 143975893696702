import subjects from 'data/subjects.json'
import { ticketTransformer } from 'transformers'
import config from 'config'

const {
  drivingApi: { endpoints }
} = config

const attachSubjectTitleById = mistakes => {
  const entries = Object.entries(mistakes)

  return entries.map(([id, value]) => ({
    title: subjects[id]?.title,
    value: value
  }))
}

export const userTransformer = {
  fetchUser: data => {
    try {
      const {
        user_id,
        username,
        user_name,
        user_surname,
        driving_school_comment_ids,
        latest_ticket_mistakes,
        ticket_log_by_week,
        ticket_log_by_month,
        user_exp,
        has_photo,
        photo_ver
      } = data.data

      const avatar = endpoints.getAvatar
        .replace(':id', user_id)
        .replace(':version', photo_ver)

      return {
        id: user_id,
        avatar: parseInt(has_photo) ? avatar : null,
        email: username,
        firstName: user_name,
        lastName: user_surname,
        fullName: `${user_name} ${user_surname}`,
        drivingSchoolCommentIds: driving_school_comment_ids,
        latestTicketMistakes: attachSubjectTitleById(latest_ticket_mistakes),
        ticketLogByWeek: {
          ...ticket_log_by_week,
          loop: Object.values(ticket_log_by_week.loop)
        },
        ticketLogByMonth: {
          ...ticket_log_by_month,
          loop: Object.values(ticket_log_by_month.loop)
        },
        userExp: {
          lvl: user_exp.lvl || '1',
          lastTickets: ticketTransformer.saveLastTickets({
            data: user_exp
          }),
          nextLvlNotify: user_exp.next_lvl_notify || false
        }
      }
    } catch (error) {
      throw new Error(error)
    }
  },

  latestTicketMistakes: latestTicketMistakes =>
    attachSubjectTitleById(latestTicketMistakes),

  updateUserRequest: ({ firstName, lastName, currentPassword }) => ({
    first_name: firstName,
    last_name: lastName,
    current_password: currentPassword
  }),

  updatePasswordRequest: ({ currentPassword, newPassword }) => ({
    current_password: currentPassword,
    new_password: newPassword
  })
}
