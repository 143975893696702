import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import ProfileBoxView from '../views/ProfileBox'

import { PROFILE_EDIT_MODAL, PROFILE_PASSWORD_MODAL } from 'consts'
import config from 'config'
import { removeToken } from 'utils/app'
import { useDropdown } from 'hooks'
import { toggleModal } from 'modules/modalManager/actions'

ProfileBox.propTypes = {
  auth: PropTypes.object.isRequired
}

const {
  drivingApi: { endpoints }
} = config

export default function ProfileBox({ auth }) {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()

  const dropdown = useDropdown({ name: 'profileBox', animable: true })

  const dispatch = useDispatch()

  const hisotry = useHistory()

  const listenNavBtn = () => dropdown.toggle()

  const listenSubNavBtn = action => () => {
    switch (action) {
      case PROFILE_EDIT_MODAL:
        dispatch(toggleModal({ name: action }))
        break
      case PROFILE_PASSWORD_MODAL:
        dispatch(toggleModal({ name: action }))
        break
      default:
        hisotry.push('/profile')
        break
    }

    dropdown.toggle(false)
  }

  const listenLogOutBtn = () => {
    removeToken()

    window.location.href = endpoints.authSignOutUri.replace(/:locale/g, locale)
  }

  return (
    <ProfileBoxView
      t={t}
      auth={auth}
      dropdown={dropdown}
      listenNavBtn={listenNavBtn}
      listenSubNavBtn={listenSubNavBtn}
      listenLogOutBtn={listenLogOutBtn}
    />
  )
}
