import React from 'react'
import PropTypes from 'prop-types'

import TicketWizardLvlBlockView from '../views/TicketWizardLvlBlock'

TicketWizardLvlBlock.propTypes = {
  wizardLvl: PropTypes.number.isRequired
}

export default function TicketWizardLvlBlock({ wizardLvl }) {
  return <TicketWizardLvlBlockView wizardLvl={wizardLvl} />
}
