import React from 'react'
import PropTypes from 'prop-types'

export const BCatIcon = ({ className = '' } = '') => (
  <svg
    className={`license-i b-cat-i ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34 14"
  >
    <path d="M26.71 5.955c-2.082 0-3.77 1.801-3.77 4.023 0 2.221 1.688 4.022 3.77 4.022 2.081 0 3.769-1.8 3.769-4.022 0-2.222-1.688-4.023-3.77-4.023zm-18.184 0c-2.08 0-3.768 1.801-3.768 4.023C4.758 12.199 6.445 14 8.526 14c2.083 0 3.77-1.8 3.77-4.022 0-2.222-1.687-4.023-3.77-4.023zM26.71 7.848c1.1 0 1.996.955 1.996 2.13 0 1.174-.895 2.13-1.996 2.13-1.1 0-1.995-.956-1.995-2.13 0-1.175.895-2.13 1.995-2.13zm-18.183 0c1.102 0 1.997.955 1.997 2.13 0 1.174-.895 2.13-1.997 2.13-1.1 0-1.995-.956-1.995-2.13 0-1.175.896-2.13 1.995-2.13zM0 3.666s1.7 6.193 2.734 7.102c.478.418 1.025.65 1.616.783a4.988 4.988 0 0 1-.258-1.573c0-2.61 1.99-4.733 4.434-4.733 2.446 0 4.436 2.123 4.436 4.733 0 .417-.056.817-.151 1.202a40.26 40.26 0 0 1 3.311-.153c1.912 0 4.266.372 6.472.703a4.97 4.97 0 0 1-.32-1.752c0-2.61 1.99-4.733 4.435-4.733 2.445 0 4.435 2.123 4.435 4.733 0 .671-.134 1.31-.37 1.888 1.155-.349 1.882-1.002 2.375-2.204 1.097-2.668 1.004-2.948.447-3.494-2.073-2.027-4.836-2.609-7.652-2.78-1.601-.098-2.335-.422-3.148-.79l-.579-.322A108.331 108.331 0 0 0 19.473.799C15.05-1.471 5.905 1.612 0 3.666zM15.414.956c1.527 0 2.764.228 3.674.695.827.425 2.121 1.166 3.296 1.825 0 0-.25 1.05-1.169 1.167l-.169.01h-7.299c-2.117 0-2.915-1.444-2.971-1.55l-.004-.007.855-1.757c1.327-.231 2.613-.382 3.787-.382z" />
  </svg>
)

BCatIcon.propTypes = {
  className: PropTypes.string
}
