import Regulator from './containers/Regulator'

export default {
  exact: true,
  isNav: true,
  name: 'regulators',
  purePath: '/regulators',
  path: '/regulators',
  component: Regulator
}
