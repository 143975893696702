import { useState } from 'react'

export default function useRsQuestModal({
  wizardNext,
  wizardCorrectnessNow,
  listenCloseModal
}) {
  const [isCorrect, setIsCorrect] = useState(false)
  const [isWrong, setIsWrong] = useState(false)

  const listenAnswerBtn = answer => event => {
    const {
      currentTarget: { classList }
    } = event

    if (isCorrect || isWrong) return

    if (answer.correct) {
      classList.add('correct')
      setIsCorrect(true)
    } else {
      classList.add('false')
      setIsWrong(true)
    }

    const next = wizardNext()

    setTimeout(() => {
      wizardCorrectnessNow(answer.correct)
      if (!next) return
      clearAnswerState(classList)
      next(answer.correct)
    }, 1000)

    setTimeout(() => {
      if (!next) return listenCloseModal()
    }, 2500)
  }

  const clearAnswerState = classList => {
    classList.remove('false', 'correct')
    setIsWrong(false)
    setIsCorrect(false)
  }

  return {
    listenAnswerBtn,
    isCorrect,
    isWrong
  }
}
